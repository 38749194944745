import { z } from 'zod'

import { API } from 'app/api/API'
import { Requests } from 'app/api/Requests'
import {
  availableExternalIntegrationsList,
  customerAvailableIntegrationResponse,
  customerAvailableIntegrationsList,
  externalIntegrationList,
} from 'app/types/zod/common/external-integrations'
import {
  zapierIntegration,
  zapierIntegrationApi,
  ZapierIntegrationDetails,
  zapierIntegrationDetails,
  zapierIntegrationList,
  zapierIntegrationSubscription,
} from 'app/types/zod/common/external-integrations/zapier'
import { parsedRequest } from 'app/types/zod/utils'

export class ExternalIntegrations {
  service: Requests
  system: Requests

  constructor(api: API) {
    this.service = new Requests(api, 'v2/service/')
    this.system = new Requests(api, 'v2/mgmt/')
  }

  getAllExternalIntegrations = parsedRequest(
    externalIntegrationList,
    async () => this.service.get('externalintegrations')
  )

  getAvailableExternalIntegrations = parsedRequest(
    availableExternalIntegrationsList,
    async () => this.service.get('customeravailableintegrations')
  )

  getZapierIntegrations = parsedRequest(zapierIntegrationList, async () =>
    this.service.get('zapierintegrations')
  )

  getZapierIntegrationDetails = parsedRequest(
    zapierIntegrationDetails,
    async (id: string) => this.service.get(`zapierintegration/${id}`)
  )

  getZapierIntegrationConnectedStatus = parsedRequest(
    z.boolean(),
    async (id) => {
      const { connected } = await this.getZapierIntegrationDetails(id)

      return !!connected
    }
  )

  private postZapierIntegration = parsedRequest(
    zapierIntegrationApi,
    async () => this.service.post('zapierintegration')
  )

  createZapierIntegration = parsedRequest(zapierIntegration, async () => {
    const result = await this.postZapierIntegration()

    return {
      ...result,
      expiry_time: result.expiry_time.milliseconds,
    }
  })

  updateZapierIntegration = parsedRequest(
    zapierIntegrationDetails,
    async (id: string, data: Partial<ZapierIntegrationDetails>) =>
      this.service.put(`zapierintegration/${id}`, data)
  )

  deleteZapierIntegration = async (id: string) =>
    this.service.delete(`zapierintegration/${id}`)

  getZapierConnectionSubscriptions = parsedRequest(
    z.array(zapierIntegrationSubscription),
    async (id: string) => this.service.get(`zapiersubscriptions/${id}`)
  )

  getSystemExternalIntegrations = parsedRequest(
    externalIntegrationList,
    async () => this.system.get('externalintegrations')
  )

  getCustomerAvailableIntegrations = parsedRequest(
    customerAvailableIntegrationsList,
    async (customerId: number) =>
      this.system.get(`companies/${customerId}/externalintegrations`)
  )

  enableCustomerIntegration = parsedRequest(
    customerAvailableIntegrationResponse,
    async ({
      customerId,
      externalIntegrationId,
    }: {
      customerId: number
      externalIntegrationId: number
    }) =>
      this.system.post(`companies/${customerId}/externalintegrations`, {
        external_integration_id: externalIntegrationId,
      })
  )

  disableCustomerIntegration = async (id: number, customerId: number) =>
    this.system.delete(`companies/${customerId}/externalintegrations`, {
      customer_available_integration_id: id,
    })
}
