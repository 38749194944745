import { filter } from 'lodash'
import moment from 'moment'

import { bind } from '@common/utils'
import { getCountryCodes } from 'app/countryCodes/helpers'
import { t } from 'app/i18n'
import { integrationStatuses } from 'app/integrations/helpers'

export class Integrations {
  constructor(api) {
    this.api = api
    bind(this, ['importFromHLR', 'getStatus'])
  }

  /**
   * Get status of all, or specified, integrations
   *
   * @param {Object} options Options object
   * @param {Object} options.extra Extra object
   * @param {string} options.extra.integrations Comma separated listing of integrations, or 'all'
   * @param {string} options.extra.customerId Customer id
   * @returns {Object} { count, results }
   */
  getStatus(options) {
    return this.api
      .get(
        `service/integrations/status`,
        {
          integrations: options.extra.integrations || 'all',
        },
        true,
        {
          'X-Customer': options.extra.customerId,
          // TODO: add filtering to back end and enable this
          // ...this.api.withFilters(options, {
          //   created: 'created',
          //   status: 'status',
          //   country_code: 'country_code',
          //   countries: 'countries',
          // }),
        }
      )
      .then((res) => {
        const countries = res.map((data) => ({
          countries: findCountriesByCountryCode(data.parameters.country_code),
          country_code: data.parameters.country_code,
          status: t(integrationStatuses[data.status]),
          created: moment(data.created).format('YYYY-MM-DD HH:mm:ss'),
          id: data.id,
        }))
        return {
          count: countries.length,
          results: countries,
        }
      })
      .catch((err) => {
        console.error(err)
        return {
          count: 0,
          results: [],
        }
      })
  }

  /**
   * Import contacts to group from Elisa HLR
   *
   * @param {Object} options Options object
   * @param {integer} options.country_code Country code (e.g. 358)
   * @param {Object} options.group Group object
   * @param {integer} options.customer_id Customer id
   * @returns {Object} Result status object
   */
  importFromHLR({ country_code, group, customer_id }) {
    return this.api
      .post(
        `service/integration/hlr`,
        {
          command: 'DOWNLOAD',
          action: 'UPDATE_OR_CREATE',
          parameters: {
            country_code,
          },
          internal_parameters: {
            groups: [group],
          },
        },
        true,
        {
          'X-Customer': customer_id,
        }
      )
      .then(() => {
        return {
          success: true,
        }
      })
      .catch((err) => {
        console.error(err)
        return {
          success: false,
        }
      })
  }
}

/**
 * Get all countries that use specified country code
 *
 * @param {integer} countryCode E.g. 358
 * @returns {string} A comma separated listing of country names
 */
const findCountriesByCountryCode = (countryCode) =>
  filter(getCountryCodes(t), (country) => {
    return country.countryCode === countryCode
  })
    .map((c) => c.nameWithoutCode)
    .join(', ')
