import { z } from 'zod'

import { FlowStatus } from 'app/types/flow'
import { groupApi } from '../../common/group'
import {
  apiEntityFieldsWithStatus,
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
  phoneNumber,
} from '../../utils'

export const applicationInfoRecipients = z.object({
  msisdns: z.array(phoneNumber),
  groups: z.array(groupApi),
})

export const flowApplicationData = z
  .object({
    systemapplication_id: z.number(),
    name: nonEmptyString,
    apikey: z.optional(z.nullable(nonEmptyString)),
    applicationevent_key: nonEmptyString,
    end: z.nullable(datetimeStringWithOffset),
    description: z.optional(z.nullable(nonEmptyString)),
  })
  .passthrough()
  .describe('flowApplicationData')

export const flowApplicationDataApi = flowApplicationData
  .extend({
    begin: nonEmptyString,
    allow_api_usage: z.boolean(),
  })
  .merge(apiEntityFieldsWithStatus)
  .strict()

// v2

// These are updated versions of flow data schemas. We'll leave
// the previous schemas untouched so that there's no need to worry about
// Group Subscription, Verify and RCS
export const sendinfo = z.object({
  message: nonEmptyString,
  sender: nonEmptyString,
  recipients: applicationInfoRecipients,
})

export const applicationDataResponse = z.object({
  id: integer,
  allow_api_usage: z.boolean(),
  apikey: z.nullable(z.array(nonEmptyString)),
  applicationevent_key: nonEmptyString,
  begin: datetimeStringWithOffset,
  created: datetimeStringWithOffset,
  description: nullableString,
  end: z.nullable(datetimeStringWithOffset),
  name: nonEmptyString,
  status: z.nativeEnum(FlowStatus),
  systemapplication_id: integer,
})

export const applicationData = applicationDataResponse
  .partial({
    id: true,
    created: true,
    status: true,
    allow_api_usage: true,
    apikey: true,
    begin: true,
    description: true,
    end: true,
  })
  .strip()
  .describe('applicationData')

export type ApplicationData = z.infer<typeof applicationData>
