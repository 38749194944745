import accounts from './accounts/sagas'
import auditLog from './audit-log/sagas'
import billing from './billing/sagas'
import customers from './customers/sagas'
import messageLogs from './message-logs/sagas'
import moNumbers from './mo-numbers/sagas'
import newsfeed from './news-feed/sagas'
import policies from './policies/sagas'
import routing from './routing/sagas'
import ssoconnection from './sso/sagas'

const system = [
  ...accounts,
  ...auditLog,
  ...billing,
  ...customers,
  ...moNumbers,
  ...policies,
  ...routing,
  ...ssoconnection,
  ...newsfeed,
  ...messageLogs,
]

export default system
