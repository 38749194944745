import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import isUndefined from 'lodash/isUndefined'

import { hasPolicyPermission } from './index'

class Auth extends Component {
  state = {
    hasAccess: true,
  }

  render() {
    const { auth, children, role, redirect } = this.props,
      { user, loggedIn } = auth

    if (!loggedIn) {
      return this.redirect('/login')
    }

    if (!this.state.hasAccess) {
      return this.redirect(redirect)
    }

    if (!isUndefined(role)) {
      if (Array.isArray(role)) {
        if (!role.includes(user.role)) {
          return this.redirect()
        }
      } else if (role !== user.role) {
        return this.redirect()
      }
    }

    return <>{children}</>
  }

  redirect(url) {
    return <Redirect to={url || '/'} />
  }

  componentDidMount() {
    const { auth, policy, access } = this.props,
      { user } = auth
    if (!isUndefined(policy)) {
      hasPolicyPermission(access || ['view'], user.policies[policy], (res) => {
        this.setState({ hasAccess: res })
      })
    }
  }
}

export default connect(({ auth }) => ({
  auth,
}))(Auth)
