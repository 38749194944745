import { take, takeLatest } from 'redux-saga/effects'

import { userInfo } from './auth/sagas'
import {
  ACTION_COMPLETE,
  actionComplete,
  actionName,
  actionPut,
} from './common/action'
import api from './api'

export const initialize = 'initialize'

/**
 * Handle
 */
export function* handleInitialize() {
  yield api.init()
  if (api.hasToken()) {
    try {
      yield api.user.refreshToken()
      yield actionPut(userInfo)
      yield take(actionName(userInfo, ACTION_COMPLETE))
    } catch {
      yield api.destroyToken()
    }
  }
  yield actionComplete(initialize)
}

/**
 * Watch
 */
export function* watchInitialize() {
  yield takeLatest(actionName(initialize), handleInitialize)
}

const app = [watchInitialize]

export default app
