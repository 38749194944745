import { z } from 'zod'

import {
  RichCardAlignment,
  RichCardOrientation,
} from 'app/types/zod/common/Enums'
import { rcsRichCardMedia } from '../media'
import { suggestions } from '../suggestions'

export const rcsBaseCard = z.object({
  title: z.optional(z.string().max(200)),
  description: z.optional(z.string().max(2000)),
  media: z.optional(rcsRichCardMedia),
  suggestions,
})

export const rcsRichCardContent = rcsBaseCard.extend({
  orientation: RichCardOrientation,
  thumbnail_alignment: RichCardAlignment,
})

export type RcsRichCardContent = z.infer<typeof rcsRichCardContent>
