import {
  billing as billingApi,
  createActionSaga,
  createFormSaga,
} from '../../api'

export const systemBillingProducts = 'system-billing-products'
export const billingProducts = 'billing-products'
export const billingProductDelete = 'billing-product-delete'
export const billingProductReload = 'billing-product-reload'
export const billingProductSave = 'billing-product-save'

export const systemBillingProductGroups = 'system-billing-product-groups'
export const billingProductGroups = 'billing-product-groups'
export const billingProductGroupDelete = 'billing-product-group-delete'
export const billingProductGroupReload = 'billing-product-group-reload'
export const billingProductGroupSave = 'billing-product-group-save'

const {
  deleteGroup,
  deleteProduct,
  getAllGroups,
  getAllProducts,
  getGroup,
  getGroups,
  getProduct,
  getProducts,
  saveGroup,
  saveProduct,
} = billingApi

const billing = [
  // Products
  createActionSaga(systemBillingProducts, getProducts),
  createActionSaga(billingProductDelete, deleteProduct),
  createActionSaga(billingProductReload, getProduct, {
    busy: true,
  }),
  createFormSaga(billingProductSave, saveProduct, {
    message: 'Product saved',
  }),
  createActionSaga(billingProducts, getAllProducts),
  // Groups
  createActionSaga(systemBillingProductGroups, getGroups),
  createActionSaga(billingProductGroupDelete, deleteGroup),
  createActionSaga(billingProductGroupReload, getGroup, {
    busy: true,
  }),
  createFormSaga(billingProductGroupSave, saveGroup, {
    message: (payload, result) => {
      return result.new
        ? 'Product group added successfully'
        : 'Product group was updated successfully'
    },
  }),
  createActionSaga(billingProductGroups, getAllGroups, { busy: true }),
]

export default billing
