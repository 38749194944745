import { z } from 'zod'

import {
  RcsFileContentType,
  RichCardMediaHeight,
} from 'app/types/zod/common/Enums'
import { urlString } from 'app/types/zod/utils'
import { suggestions } from '../suggestions'

export const rcsMediaFile = z.object({
  file_content_type: RcsFileContentType,
  thumbnail: z.optional(urlString),
  url: urlString,
})

export const rcsRichCardMedia = z.object({
  height: RichCardMediaHeight,
  file: rcsMediaFile,
})

export const rcsMediaContent = z.object({
  file: rcsMediaFile,
  suggestions,
})

export type RcsMediaFile = z.infer<typeof rcsMediaFile>

export type RcsRichCardMedia = z.infer<typeof rcsRichCardMedia>

export type RcsMediaContent = z.infer<typeof rcsMediaContent>
