import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'

import integrations from 'app/integrations/ImportContacts/sagas'
import forgotPassword from '../auth/forgot-password/sagas'
import userAccountConfirmation from '../auth/invitation/sagas'
import login from '../auth/login/sagas'
import auth from '../auth/sagas'
import table from '../common/table/sagas'
import tablePopup from '../common/table-popup/sagas'
import configurations from '../configurations/sagas'
import home from '../home/sagas'
import newsfeed from '../newsfeed/sagas'
import profile from '../profile/sagas'
import app from '../sagas'
import messages from '../service/applications/sagas'
import router from '../service/router/sagas'
import service from '../service/sagas'
import system from '../system/sagas'
import contacts from '../tools/contact/sagas'
import tracking from '../tools/tracking/sagas'

/**
 * Middleware
 */
export const sagaMiddleware = createSagaMiddleware()

/**
 * Root redux saga
 */
export default function* rootSaga() {
  yield all(auth.map(fork))
  yield all(app.map(fork))
  yield all(contacts.map(fork))
  yield all(forgotPassword.map(fork))
  yield all(userAccountConfirmation.map(fork))
  yield all(login.map(fork))
  yield all(home.map(fork))
  yield all(messages.map(fork))
  yield all(profile.map(fork))
  yield all(router.map(fork))
  yield all(system.map(fork))
  yield all(table.map(fork))
  yield all(tablePopup.map(fork))
  yield all(tracking.map(fork))
  yield all(configurations.map(fork))
  yield all(newsfeed.map(fork))
  yield all(service.map(fork))
  yield all(integrations.map(fork))
}
