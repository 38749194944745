import { AxiosRequestHeaders } from 'axios'

import { getPageNumber, getTableQueryHeaders } from 'app/api'
import { API } from 'app/api/API'
import { downloadFileWithNameFromHeaders } from 'app/common/utils'
import {
  UnsubscribeEvent,
  unsubscribeEventList,
  unsubscribeEventResponse,
} from 'app/tools/unsubscription/Unsubscription/types'
import { TableRequestOptions } from 'app/types/app'
import {
  ContactResponseItem,
  contactResponseItem,
} from 'app/types/zod/common/contact'
import { parsedRequest, parsedTableRequest } from 'app/types/zod/utils'

export class Unsubscription {
  api: API
  constructor(api: API) {
    this.api = api
  }

  get headers() {
    const requestHeaders: AxiosRequestHeaders = {}
    const currentUser = this.api.user?.currentUser

    if (currentUser?.customer?.active) {
      requestHeaders['X-customer'] = currentUser.customer.active.id
    }

    return requestHeaders
  }

  getContacts = parsedTableRequest(
    contactResponseItem,
    async (options: TableRequestOptions) => {
      const headers = getTableQueryHeaders(options)
      return this.api.get(
        `service/contacts?page=${getPageNumber(options)}`,
        {},
        true,
        headers
      )
    }
  )

  downloadContacts = async (
    download: 'excel' | 'csv',
    options: TableRequestOptions,
    message?: string
  ) => {
    options.download = download

    const headers = getTableQueryHeaders(options)

    const result = await this.api.get(
      'service/downloadcontacts',
      {},
      true,
      headers,
      download === 'excel' ? true : false,
      true
    )

    downloadFileWithNameFromHeaders({ result, message })
  }

  createUnsubscribe = parsedRequest(
    unsubscribeEventResponse,
    async (data: UnsubscribeEvent) =>
      this.api.post('app/unsubscribe', data, true, this.headers)
  )

  updateUnsubscribe = parsedRequest(
    unsubscribeEventResponse,
    async (id: number, data: UnsubscribeEvent) =>
      this.api.put(`app/unsubscribe/${id}`, data, true, this.headers)
  )

  getUnsubscribe = parsedRequest(unsubscribeEventResponse, async (id: number) =>
    this.api.get(`app/unsubscribe/${id}`, {}, true, this.headers)
  )

  abortUnsubscribe = async (id: number) =>
    this.api.get(`app/abort/${id}`, {}, true, this.headers)

  getUnsubscribeEvents = parsedRequest(unsubscribeEventList, () =>
    this.api.get('app/unsubscribe', {}, true, this.headers)
  )

  allowMessages = async (data: ContactResponseItem | ContactResponseItem[]) => {
    const contactids = Array.isArray(data)
      ? data.map((contact) => contact.id)
      : [data.id]

    return this.api.put(
      `service/contacts/subscribe`,
      { contactids },
      true,
      this.headers
    )
  }

  allowMessagesForAllContacts = async () =>
    this.api.put(
      'service/contacts/subscribe',
      { all: true },
      true,
      this.headers
    )
}
