import { Customer, DateAndTime, Id, TimeWithSeconds } from './app'

export enum CleanupAction {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export enum CleanupRoutineStatus {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}

export enum CleanupRoutineRunStatus {
  CREATED = 'Created',
  PENDING = 'Pending',
  RUNNING = 'Running',
  ABORTED = 'Aborted',
  FINISHED = 'Finished',
  INTERRUPTED = 'Interrupted',
  FAILED = 'Failed',
}

export enum CleanupDataObject {
  MESSAGE = 'Message',
  EVENT_RECIPIENTS = 'EventRecipients',
  CDR_DATA = 'CDRData',
}

export enum CleanupFilterType {
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
  INTEGER = 'INTEGER',
  INTEGER_ARRAY = 'INTEGER_ARRAY',
  DATETIME = 'DATETIME',
  TIME_ADD = 'TIME_ADD',
}

export enum CleanupValueType {
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  STRING = 'STRING',
  DATETIME = 'DATETIME',
  TIME_ADD = 'TIME_ADD',
  STRING_ARRAY = 'STRING_ARRAY',
  INTEGER_ARRAY = 'INTEGER_ARRAY',
  FUNCTION = 'FUNCTION',
  NULL = 'NULL',
}

export interface CleanupFilter {
  id: Id
  data_field: CleanupDataObject
  operand: CleanupOperand
  filter_value: string | number
  filter_type: CleanupFilterType
  modified: string
  created: string
  system_data_cleanup_id?: Id
}

export type CleanupFilterPost = Omit<
  CleanupFilter,
  'id' | 'modified' | 'created'
>

export interface CleanupValue {
  id: Id
  data_field: string
  created: string
  modified: string
  system_data_cleanup_id?: Id
  update_args: string | null
  update_type: CleanupValueType
  update_value?: string
}

export type CleanupValuePost = Omit<
  CleanupValue,
  'id' | 'created' | 'modified' | 'system_data_cleanup_id'
>

export enum IntervalUnit {
  DAYS = 'days',
  MINUTES = 'minutes',
  HOURS = 'hours',
}

export interface CleanupRoutine {
  id: Id
  filters: CleanupFilter[]
  set_values: any[]
  data_object: string
  status: CleanupRoutineStatus
  chunk_size: number
  action: CleanupAction
  begin_datetime: DateAndTime
  interval: IntervalUnit
  interval_value: number
  name: string
  next_run: DateAndTime
  last_run: DateAndTime
  begin_earliest_time: TimeWithSeconds
  end_latest_time: TimeWithSeconds
  enabled: boolean
  temporary: boolean
  modified: DateAndTime
  created: DateAndTime
  customer?: Customer
}

export type CleanupRoutinePost = Pick<
  CleanupRoutine,
  | 'name'
  | 'data_object'
  | 'action'
  | 'begin_datetime'
  | 'interval'
  | 'interval_value'
  | 'begin_earliest_time'
  | 'end_latest_time'
  | 'chunk_size'
  | 'enabled'
>

export interface CleanupRoutineStatusData {
  id: Id
  status: CleanupRoutineStatus
}

export enum CleanupOperand {
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  IS_NULL = 'isnull',
  EXACT = 'exact',
  IEXACT = 'iexact',
  CONTAINS = 'contains',
  ICONTAINS = 'icontains',
  IN = 'in',
}

export interface CleanupFieldOptions {
  name: string
  can_update: boolean
  filter_types: {
    [key in CleanupFilterType]: {
      operands: CleanupOperand[]
      values: string[] | null
    }
  }
  update_types?: {
    [key in CleanupValueType]: {
      values: string[] | null
    }
  }
  update_functions?: {
    '@hash'?: {}
    '@mask'?: {
      begin: number
      end: number
    }
  }
}

export interface CleanupObjectConfig {
  actions: CleanupAction[]
  chunk_size: number
  fields: CleanupFieldOptions[]
}

export interface CleanupObject {
  data_object: string
  configuration: CleanupObjectConfig
}

export interface CleanupEvent {
  id: Id
  cleanup_batch_id: string // uuid
  status: CleanupRoutineRunStatus
  total_amount: number
  processed_amount: number
  statustime: DateAndTime
  created: DateAndTime
  data_cleanup: CleanupRoutine
  customer?: Customer
}

export enum CleanupLogLevel {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  EXCEPTION = 'EXCEPTION',
}

export interface CleanupLogItem {
  id: Id
  log_level: CleanupLogLevel
  data_object: string
  status_text: string
  created: DateAndTime
}

export interface SystemCleanupLogItem extends CleanupLogItem {
  system_data_cleanup_status_id: Id
}

export enum CleanupFunction {
  MASK = '@mask',
  HASH = '@hash',
}
