import { createFormSaga, user } from '../../api'

export const userResetPassword = 'user-reset-password'
export const userNewPassword = 'user-new-password'

const { resetPassword, newPassword } = user

const forgotPassword = [
  createFormSaga(userResetPassword, resetPassword, {
    redirect: '/forgot-password/complete',
  }),
  createFormSaga(userNewPassword, newPassword, {
    message: 'Password successfully changed',
    redirect: '/forgot-password/done',
  }),
]

export default forgotPassword
