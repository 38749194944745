import { z } from 'zod'

import { nonEmptyString } from 'app/types/zod/utils'
import { suggestions } from '../suggestions'

export const rcsTextContent = z.object({
  text: nonEmptyString.max(3000),
  suggestions,
})

export type RcsTextContent = z.infer<typeof rcsTextContent>
