import { extend, isString, omit } from 'lodash'
import { handleActions } from 'redux-actions'

export const customer_storage = 'quriiri_customer'

/**
 * Customer state
 */
export const customerState = {
  customers: [],
  active: null,
  default: 0,
}

/**
 * Initial user state
 */
export const userState = {
  email: '',
  firstName: '',
  id: '',
  language: '',
  lastName: '',
  logMask: 0,
  mobilePhone: '',
  mobileNumberToSenderlist: false,
  role: null,
  twoFactorAuthentication: '',
}

/**
 * Auth initial state
 */
export const authState = {
  customer: customerState,
  loggedIn: false,
  senderList: [],
  twoFactor: null,
  user: userState,
}

/**
 * Auth reducer
 */
export default handleActions(
  {
    USER_CUSTOMER: (state, { payload }) => {
      const customers = state.customer.customers || [],
        active = customers.find(({ id }) => id === payload) || null
      return {
        ...state,
        customer: {
          ...state.customer,
          active,
        },
      }
    },
    USER_INFO_SUCCESS: (state, { payload }) => {
      const customerPayload = payload.customer || {},
        customerDefault = customerPayload.default || 0,
        customer_id = customerDefault,
        customers = customerPayload.customers || [],
        active =
          customer_id > 0
            ? customers.find(({ id }) => id === customer_id) || null
            : null
      const customer = {
        customers,
        active,
        default: customerDefault,
      }
      if (!customer.active && customerDefault > 0) {
        customer.active = customers.find(({ id }) => id === customerDefault)
      }
      return {
        ...state,
        customer: {
          ...state.customer,
          ...customer,
        },
        loggedIn: !!payload.id,
        user: omit(payload, ['customer']),
      }
    },
    USER_REFRESH_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          policies: payload.policies,
        },
        customer: {
          ...state.customer,
          customers: payload.customers,
        },
      }
    },
    USER_REFRESH_FAIL: (state) => {
      return extend({}, state, {
        loggedIn: false,
        user: userState,
      })
    },
    FORM_USER_LOGIN_SUCCESS: (state, { payload }) => {
      if (isString(payload)) {
        return state
      } else {
        return extend({}, state, {
          twoFactor: payload,
        })
      }
    },
    FORM_USER_LOGIN_TWO_FACTOR_SUCCESS: (state) => {
      return extend({}, state, {
        twoFactor: null,
      })
    },
    SENDER_LIST_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        senderList: payload,
      })
    },
    USER_LOGOUT_COMPLETE: (state) => {
      return extend({}, state, {
        loggedIn: false,
        user: userState,
      })
    },
  },
  authState
)
