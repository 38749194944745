import React from 'react'
import { isUndefined } from 'lodash'
import moment from 'moment'

import packageJson from '../../package.json'

global.appVersion = packageJson.version + ' b. ' + packageJson.buildDate

export function buildDateGreaterThan(latestDate, currentDate) {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true
  } else {
    return false
  }
}

export function deleteCache() {
  console.log('Clearing cache and hard reloading...')
  if (!isUndefined(caches)) {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name)
      })
    }
  }
  // delete browser cache and hard reload
  window.location.reload()
}

class CacheBuster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isLatestBuildDate: false,
      refreshCacheAndReload: deleteCache,
    }
  }

  componentDidMount() {
    this.setIntervalImmediately(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate
          const currentVersionDate = packageJson.buildDate

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          )
          if (shouldForceRefresh) {
            console.log(
              `We have a new version - ${latestVersionDate}. Should force refresh`
            )
            this.setState({ loading: false, isLatestBuildDate: false })
          } else {
            // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
            this.setState({ loading: false, isLatestBuildDate: true })
          }
        })
    }, 1000 * 60)
  }

  render() {
    const { loading, isLatestBuildDate, refreshCacheAndReload } = this.state
    return this.props.children({
      loading,
      isLatestBuildDate,
      refreshCacheAndReload,
    })
  }

  setIntervalImmediately(fn, delay) {
    fn()
    setInterval(fn, delay)
  }
}

export default CacheBuster
