import { rawCountryCodes } from './rawCountryCodes'

// Create unique country id for all countries since some dial codes are mapped to multiple countries
export const getCountryCodes = (t) => {
  return rawCountryCodes.map((country) => {
    const countryNameWithCode = `${t(country.name)} (+${country.countryCode})`
    const id = `${country.name}_${country.countryCode}`
    return {
      ...country,
      name: countryNameWithCode,
      nameWithoutCode: t(country.name),
      id,
    }
  })
}
