import { extend, pick } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Table initial state
 */
export const tablePopupState = {
  busy: false,
  count: 0,
  limit: 25,
  page: 1,
  results: [],
  additional: {},
  search: {},
}

/**
 * Table reducer
 */
export default handleActions(
  {
    TABLE_POPUP_BUSY: (state, { payload }) => {
      return extend({}, state, {
        busy: !!payload,
      })
    },
    TABLE_POPUP_RESET: () => {
      return extend({}, tablePopupState)
    },
    TABLE_POPUP_QUERY: (state, { payload }) => {
      return extend(
        {},
        state,
        pick(payload, ['limit', 'order', 'page', 'search', 'sort']),
        {
          busy: true,
        }
      )
    },
    TABLE_POPUP_QUERY_COMPLETE: (state) => {
      return extend({}, state, {
        busy: false,
      })
    },
    TABLE_POPUP_QUERY_SUCCESS: (state, { payload: { count, results } }) => {
      return extend({}, state, {
        count,
        results,
      })
    },
  },
  tablePopupState
)
