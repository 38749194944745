import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'

import config from '../config'
import reducers from './reducers'
import rootSaga, { sagaMiddleware } from './sagas'

/**
 * Use logger
 */
export const USE_LOGGER = config.log.redux

/**
 * Wrap in Provider
 */
export function wrap(children, state) {
  return <Provider store={configure(state)}>{children}</Provider>
}

/**
 * Configure store
 */
export function configure(initialState) {
  const useMiddleware = [
    ...(USE_LOGGER ? [createLogger({})] : []),
    sagaMiddleware,
  ]
  const middleware = applyMiddleware(...useMiddleware),
    store = createStore(
      reducers,
      initialState,
      compose(
        middleware
        // Uncomment these to enable devtools. UI starts to lag if enabled so don't leave them
        // window.__REDUX_DEVTOOLS_EXTENSION__ &&
        //   window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    )
  sagaMiddleware.run(rootSaga)
  return store
}
