// import { keys, range, reverse, sample, sortBy } from 'lodash';
import moment from 'moment'

// import * as faker from 'faker';
import { bind /*, fakeAsync*/ } from '../common/utils'
import { getPageNumber } from './'

/**
 * Events
 */
export const events = {
  login: 'Login',
  logout: 'Logout',
  modify: 'Modify',
  delete: 'Delete',
  create: 'Create',
  view: 'View',
  select: 'Select',
}

/**
 * Statuses
 */
export const statuses = {
  success: 'Success',
  failed: 'Failed',
}

/**
 * Log API
 */
export class Log {
  constructor(api) {
    this.api = api
    bind(this, ['getLogs'])
  }

  decodeDetail(value) {
    if (value === null) {
      return value
    }

    try {
      return JSON.parse(atob(value))
    } catch (error) {
      console.error(error)
      return null
    }
  }

  decodeAuditLogDetails(details) {
    if (details == null) {
      return details
    }

    const request = this.decodeDetail(details.request)
    const response = this.decodeDetail(details.response)

    return {
      ...(request ? { request } : {}),
      ...(response ? { response } : {}),
    }
  }

  /**
   * Get logs
   */
  getLogs(options = {}) {
    return this.api
      .get(
        'mgmt/auditlog?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          customer: 'customername',
          user: 'username',
          event: 'event',
          status: 'status',
          info: 'info',
          created: 'created',
          reason: 'reason',
          target: 'target',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              audit_log_details: this.decodeAuditLogDetails(
                result.audit_log_detail
              ),
              customer: result.customername || 'N/A',
              user: {
                id: null,
                email: result.username,
              },
              event: result.event,
              status: result.status,
              info: result.info,
              created: moment(result.created),
              reason: result.reason,
              target: result.target,
            }
          }),
        }
      })
  }
}
