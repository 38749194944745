import { z } from 'zod'

import { suggestionFields } from './fields'
import { RcsSuggestionContent } from '.'

export const suggestedReplyContent = suggestionFields.extend({})

export type RcsSuggestedReplyContent = z.infer<typeof suggestedReplyContent>

export const isSuggestedReplyContent = (
  suggestion: RcsSuggestionContent
): suggestion is RcsSuggestedReplyContent => !('action' in suggestion)
