import { toast, ToastContainer } from 'react-toastify'
import { isFunction } from 'lodash'

import 'react-toastify/dist/ReactToastify.min.css'

import './Notification.scss'

export const setNotification = async ({ variant, message, cb }) => {
  const notification = variant ? toast[variant] : toast
  if (isFunction(cb)) {
    await cb()
  }
  notification(() => <div className="Notification">{message}</div>)
}

const Notification = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default Notification
