import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useTranslation } from 'react-i18next'
import { partition } from 'lodash'

import { Id } from 'app/types/app'

import './Autocomplete.scss'

export type AutoCompleteItem<T extends Id> = {
  id: T
  name: string
  prependItem?: boolean
}

interface AutoCompleteProps<T extends Id> {
  suggestions: AutoCompleteItem<T>[]
  onSelect: (id?: AutoCompleteItem<T>['id']) => void
  clearOption?: boolean
}

function AutoComplete<T extends Id>({
  suggestions,
  onSelect,
  clearOption = false,
}: AutoCompleteProps<T>) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value)
  }
  const selectOption = (id?: AutoCompleteItem<T>['id']) => {
    onSelect(id)
    setSearch('')
  }

  const [prependItems, items] = partition(
    suggestions,
    (item) => item.prependItem
  )

  return (
    <div className="Autocomplete">
      <div className="search-input">
        <i className="qf qf-search"></i>
        <input
          autoFocus
          ref={(input) => input && input.focus()}
          type="text"
          placeholder={t('Search')}
          value={search}
          onChange={handleSearch}
        />
      </div>
      <div className="suggestions">
        {clearOption && (
          <Dropdown.Item
            className="d-flex justify-content-between"
            as="span"
            onClick={() => selectOption()}
          >
            <span>{t('Clear')}</span>
            <i className="qf qf-cross-ui"></i>
          </Dropdown.Item>
        )}

        {prependItems.map((item, v) => (
          <Dropdown.Item
            key={v}
            as="span"
            onClick={() => selectOption(item.id)}
          >
            <span>{item.name}</span>
          </Dropdown.Item>
        ))}

        {items
          .filter((item) =>
            item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )
          .map((item, v) => (
            <Dropdown.Item
              key={v}
              as="span"
              data-cy={`autocomplete-item-${item.id}`}
              onClick={() => selectOption(item.id)}
            >
              <span>{item.name}</span>
            </Dropdown.Item>
          ))}
      </div>
    </div>
  )
}

export default AutoComplete
