import { z } from 'zod'

import { datetimeStringWithOffset, integer, nonEmptyString } from '../utils'
import { companyApi } from './company'

export const storageFormValues = z.object({
  address: nonEmptyString,
  size: integer,
  customer_managed: z.boolean(),
})

export const storageApi = storageFormValues
  .extend({
    used_space: integer,
    created: datetimeStringWithOffset,
  })
  .describe('storageApi')

export const storageApiListItem = storageApi
  .extend({
    customer: companyApi,
  })
  .describe('storageApiListItem')

export type StorageFormValues = z.infer<typeof storageFormValues>

export type StorageApi = z.infer<typeof storageApi>

export type StorageApiListItem = z.infer<typeof storageApiListItem>
