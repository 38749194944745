import { extend, keys } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Default columns
 */
export const defaultColumns = [
  'mobilePhone',
  'firstName',
  'lastName',
  'email',
  'created',
]

/**
 * Contacts initial state
 */
export const contactsState = {
  contact: {},
  group: {},
  groups: [],
  contactgroups: [],
  columns: [],
}

/**
 * User contacts reducer
 */
export default handleActions(
  {
    CONTACT_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        contact: extend({}, state.contact, payload || {}),
      })
    },
    CONTACT_GROUP_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        group: extend({}, state.group, payload || {}),
      })
    },
    USER_CONTACT_GROUPS: (state, { payload }) => {
      return {
        ...state,
        groups: [],
        contactGroups: [],
      }
    },
    USER_CONTACT_GROUPS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        groups: payload.results,
        contactGroups: payload.contactGroups,
      }
    },
    CONTACT_RESET: (state) => {
      return extend({}, state, {
        contact: {},
      })
    },
    CONTACT_GROUP_RESET: (state) => {
      return extend({}, state, {
        group: {},
      })
    },
    CONTACTS_COLUMNS_TOGGLE: (state, { payload }) => {
      const columns = extend({}, state.columns, {
        [payload]: state.columns[payload] ? 0 : 1,
      })
      const columnKeys = keys(columns)
      const total = columnKeys
        .map((key) => {
          return columns[key]
        })
        .reduce((sum, value) => {
          return sum + value
        }, 0)
      if (total === 0) {
        columns[columnKeys[0]] = 1
      }
      return extend({}, state, {
        columns,
      })
    },
    USER_CONTACTS: (state, { payload }) => {
      return extend({}, state, {})
    },
  },
  contactsState
)
