import { AxiosRequestHeaders } from 'axios'

import { API } from 'app/api/API'

// This is a function for getting the required headers from the
// v1 api object when using API endpoints from the v2.
export const getRequestHeaders = (
  api: API,
  {
    headers = {},
    useAuth = true,
  }: { headers?: AxiosRequestHeaders; useAuth?: boolean } = {}
) => {
  const requestHeaders: AxiosRequestHeaders = {
    'content-type': 'application/json',
    ...headers,
  }

  if (useAuth && api.token) {
    requestHeaders['Authorization'] = 'Bearer ' + api.token
  }

  const currentUser = api.user?.currentUser

  if (currentUser) {
    if (currentUser.language) {
      requestHeaders['Accept-Language'] = currentUser.language
    }
    if (currentUser.customer?.active) {
      requestHeaders['X-customer'] = currentUser.customer.active.id
    }
  }

  return requestHeaders
}
