import { z } from 'zod'

import { FlowStatus } from 'app/types/flow'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  optionalString,
} from '../../utils'
import {
  batchContact,
  batchContactApi,
  batchGroup,
  batchGroupApi,
  batchMsisdn,
  batchMsisdnApi,
} from './fields'

export const mtBatch = z.object({
  groups: z.optional(z.array(batchGroup)),
  contacts: z.optional(z.array(batchContact)),
  msisdns: z.optional(z.array(batchMsisdn)),
  destination: z.nullable(optionalString),
})

export const mtBatchApi = z.object({
  id: integer,
  created: datetimeStringWithOffset,
  groups: z.optional(z.array(batchGroupApi)),
  contacts: z.optional(z.array(batchContactApi)),
  msisdns: z.optional(z.array(batchMsisdnApi)),
  destination: z.nullable(nonEmptyString),
  applicationevent_id: integer,
  applicationevent_key: nonEmptyString,
  status: z.nativeEnum(FlowStatus),
  recipient_count: integer,
})

export type MtBatch = z.infer<typeof mtBatch>
