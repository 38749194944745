import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './datadogRumInit'
import ReactDOM from 'react-dom'

import App from './app/App'
import { wrap } from './app/store'
import * as serviceWorker from './serviceWorker'

import './index.scss'

ReactDOM.render(wrap(<App />), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
