import { z } from 'zod'

import { rcsCarouselCardContent, rcsCarouselContent } from './carousel'
import { rcsMediaContent } from './media'
import { rcsRichCardContent } from './rich-card'
import { rcsTextContent } from './text'

export const rcsMessageContent = z.union([
  rcsTextContent,
  rcsMediaContent,
  rcsRichCardContent,
])

export const rcsContent = z.union([
  rcsMessageContent,
  rcsCarouselContent,
  rcsCarouselCardContent,
])

export type RcsContent = z.infer<typeof rcsContent>
