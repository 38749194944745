import { ObjectSchema } from 'yup'

import { useFlow } from 'app/service/Flow'
import { MtBatch } from './zod/common/batches'
import { RcsContent } from './zod/rcs/content'
import { Group, VerifyMessage } from './app'

// Flow enums
export enum ModuleName {
  VERIFY = 'verify',
  GROUP_SUBSCRIPTION = 'groupsubscription',
  NPS_SURVEY = 'npssurvey',
  UNSUBSCRIBE = 'unsubscribe',
}

export enum FlowType {
  ROOT = 'ROOT',
  INPUT = 'INPUT',
  REPORT = 'REPORT',
  FLOW_ITEM = 'FLOW_ITEM',
  FLOW_FUNCTION = 'FLOW_FUNCTION',
  ACTIVATION = 'ACTIVATION',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export enum FlowStatus {
  INITIALIZING = 'INITIALIZING',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
}

export enum StatType {
  PROMOTERS = 'PROMOTERS',
  PASSIVES = 'PASSIVES',
  DETRACTORS = 'DETRACTORS',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  SUBSCRIBED = 'SUBSCRIBED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  VERIFY = 'VERIFY',
  CHECK_VERIFICATION = 'CHECK_VERIFICATION',
}

export enum ResponseType {
  NPS_RESULTS = 'NPS_RESULTS',
  NPS_FEEDBACK = 'NPS_FEEDBACK',
}

export enum Detail {
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  GROUP_SUBSCRIBE = 'GROUP_SUBSCRIBE',
  GROUP_UNSUBSCRIBE = 'GROUP_UNSUBSCRIBE',
  SMS_NOTIFICATION = 'SMS_NOTIFICATION',
  EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
  SUBSCRIBE_RESPONSE = 'SUBSCRIBE_RESPONSE',
  UNSUBSCRIBE_RESPONSE = 'UNSUBSCRIBE_RESPONSE',
  SEND_VERIFICATION = 'SEND_VERIFICATION',
  CHECK_VERIFICATION = 'CHECK_VERIFICATION',
}

export enum DestinationSenderType {
  NATIONAL = 'NATIONAL',
  ALNUM = 'ALNUM',
  MSISDN = 'MSISDN',
}

export enum FlowAction {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

// Main flow elements
export interface FlowData {
  application: FlowApplicationData
  flow: FlowEvent
}

export interface FlowEvent {
  id?: number
  applicationevent_id?: number
  customerrouterule_id?: number
  parent?: number
  stat_type?: StatType
  status?: FlowStatus
  flow_type: Exclude<FlowType, FlowType.FLOW_FUNCTION>
  level?: number
  order?: number
  created?: string
  children?: FlowEvent[]
  flow_functions?: FlowFunction[]
  flowevent_matchers?: Matcher[]
  reference?: string
  settings_props?: VerifySettingsProps
  flowevent_schedule?: FlowSchedule
}

export interface FlowActivationEvent extends FlowEvent {
  flow_type: FlowType.ACTIVATION
}

export interface FlowFunction {
  id?: number
  reference?: string
  action?: string
  detail?: string | null
  customerrouterule_id?: number
  status?: FlowStatus
  flow_type: FlowType.FLOW_FUNCTION | FlowType.ACTIVATION
  order?: number
  created?: string
  receivesmsfunction?: ReceiveSmsFunction
  sendsmsfunction?: SendSmsFunction
  receivercsfunction?: ReceiveRcsFunction
  sendrcsfunction?: SendRcsFunction
  sendemailfunction?: SendEmailFunction
  subscriptionfunction?: SubscriptionFunction
  verifyfunction?: VerifyFunction
  checkverificationfunction?: CheckVerifyFunction
}

export type FlowSchedule = {
  created: string
  id: number
  schedule_type: string
  scheduled_datetime: string
  status: FlowStatus
}

// Flow function parameters
export interface ReceiveRcsFunction {
  agent_id: number
}

export interface SendRcsFunction {
  id?: number
  agent_id: number
  body: RcsContent[]
  created?: string
  sendrcsbatches?: MtBatch[]
}

export interface ReceiveSmsFunction {
  id?: number
  destination: string
  destinationtype?: DestinationSenderType
  created?: string
}

export interface SendSmsFunction {
  id?: number
  sender: string
  sendertype?: DestinationSenderType
  body: string
  udh?: string
  flash?: boolean
  validity?: number
  enable_duplicates?: boolean
  created?: string
  sendsmsbatches?: MtBatch[]
  sendbatch_option?: string
}

export interface SendEmailFunction {
  id?: number
  sender: string
  subject: string
  body: string
  enable_duplicates?: boolean
  created?: string
  sendemail_batches: EmailBatch[]
}

export interface SubscriptionFunction {
  id?: number
  action: FlowAction
  personalization: string[]
  groups: Group[]
  created?: string
}

export interface VerifyFunction {
  id?: number
  sender: string
  messages: VerifyMessage[]
}

export interface CheckVerifyFunction {
  id?: number
  pin_length: number
  pin_validity: number
}

interface BatchGroup {}

interface BatchContact {}

interface EmailBatchFieldBase {
  id?: number
  email: string
  domain: string
}

interface Batch {
  id?: number
  applicationevent_id?: number
  applicationevent_key?: string
  status?: FlowStatus
  recipient_count?: number
  created: string
}

interface EmailBatch extends Batch {
  to_groups?: BatchGroup[]
  cc_groups?: BatchGroup[]
  bcc_groups?: BatchGroup[]
  to_contacts?: BatchContact[]
  cc_contacts?: BatchContact[]
  bcc_contacts?: BatchContact[]
  to: EmailBatchFieldBase[]
  cc: EmailBatchFieldBase[]
  bcc: EmailBatchFieldBase[]
}

// Flow event parameters
export interface Matcher {
  id?: number
  reference?: string
  priority: number
  matcher: string
  created?: string
}

export interface Recipients {
  msisdns?: string[]
  groups?: Group[]
}

// Flow application data
export interface FlowApplicationData {
  id?: number
  systemapplication_id: number
  name: string
  apikey?: string | null
  applicationevent_key: string
  status?: FlowStatus
  end: string | null
  description?: string
  created?: string
  application_info?: {
    sendinfo?: SendInfo
    receiveinfo?: ReceiveInfo
  }
}

export interface SendInfo {
  message?: string
  sender?: string
  recipients?: Recipients
}

export interface ReceiveInfo {
  number?: string
  ends?: string
  starts?: string
}

export interface AppSecret {
  name: string
  content: string
  status: 'active' | 'inactive'
}

export interface VerifySettingsProps {
  pin_length: SettingsProp
  pin_validity: SettingsProp
}

interface SettingsProp {
  value: any
  prop_type: 'number'
}

// Flow UI data
export interface ReferenceMapNode {
  reference: string
  children?: ReferenceMapNode[]
  flow_functions?: ReferenceMapNode[]
  flowevent_matchers?: ReferenceMapNode[]
  match: {
    order?: number
    flow_type?: FlowType
    stat_type?: StatType
    detail?: string | null
  }
}

export interface FlowErrors {
  application: any
  flow: {
    [key in string]: any
  }
}

export type FlowContent =
  | FlowEvent
  | FlowEvent[]
  | FlowFunction
  | FlowFunction[]
  | Matcher
  | Matcher[]

export interface FlowApplicationValidationSchema {
  application: ObjectSchema<any>
  flow: {
    [key in string]: ObjectSchema<any>
  }
}

export type Flow = ReturnType<typeof useFlow>
