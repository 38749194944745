import { z } from 'zod'

import { integer, nonEmptyString } from '../../utils'

export const batchGroup = z.object({
  group: z.object({
    id: integer,
    name: nonEmptyString,
    member_count: integer,
  }),
})

export const batchGroupApi = batchGroup.extend({
  id: integer,
})

export const batchContact = z.object({
  contact: z.any(),
})

export const batchContactApi = batchContact.extend({
  id: integer,
})

export const batchMsisdn = z.object({
  msisdn: nonEmptyString,
})

export const batchMsisdnApi = batchMsisdn.extend({
  id: integer,
  countrycode: z.nullable(nonEmptyString),
})
