import { createActionSaga, createFormSaga, message, services } from '../../api'
import { actionSuccess } from '../../common/action'

export const userApplications = 'user-applications'
export const applicationToggle = 'application-toggle'
export const applicationCallToAction = 'application-call-to-action'
export const applicationDialog = 'application-dialog'
export const applicationFeedbackAndInquiry = 'application-feedback-and-inquiry'
export const applicationGroupMessage = 'application-group-message'
export const applicationInbox = 'application-inbox'
export const applicationNumbers = 'application-numbers'
export const applicationQuickMessage = 'application-quick-message'
export const applicationKey = 'application-key'
export const applicationMessages = 'application-messages'
export const applicationMessagesReload = 'application-messages-reload'
export const applicationTasks = 'application-tasks'
export const eventDelete = 'event-delete'
export const userMessageEvents = 'user-message-events'
export const userMessageEventsReload = 'user-message-events-reload'
export const userMessageTemplates = 'user-message-templates'
export const messageTemplateDelete = 'message-template-delete'
export const messageTemplateReload = 'message-template-reload'
export const messageTemplateSave = 'message-template-save'
export const smsInboxFolder = 'sms-inbox-folder'
export const applicationDialogReports = 'application-dialog-reports'
export const applicationMoDialogReports = 'application-modialog-reports'
export const applicationCallToActionReports =
  'application-call-to-action-reports'
export const applicationDraftSave = 'application-draft-save'
export const applicationDrafts = 'application-drafts'
export const applicationDraftsReload = 'application-drafts-reload'
export const applicationDraftsDelete = 'application-drafts-delete'
export const applicationDraftsEdit = 'application-drafts-edit'
export const applicationDraftSingle = 'application-drafts-single'
export const applicationDraftsAll = 'application-drafts-all'

const {
  deleteEvent,
  deleteTemplate,
  generateKey,
  getApplications,
  getEvents,
  getNumbers,
  getTemplates,
  getTemplate,
  getMessages,
  getTasks,
  saveTemplate,
  toggleApplication,
} = message

const sendQuickMessage = services.quickMessage.post
const sendGroupMessage = services.groupMessage.post
const startFeedbackAndInquiry = services.feedbackAndInquiry.post
const startCallToAction = services.callToAction.post
const startDialog = services.dialog.post
const startInbox = services.inbox.post
const getInboxFolders = services.inbox.getFolders

const getDialogReports = services.dialog.getReports
const getMoDialogReports = services.moDialog.getReports
const getCallToActionReports = services.callToAction.getReports

const {
  getDrafts,
  getAllDrafts,
  saveApplicationDraft,
  getApplicationDraft,
  editApplicationDraft,
  deleteApplicationDraft,
} = services.applicationDraft

const messages = [
  createActionSaga(userMessageEvents, getEvents),
  createActionSaga(userMessageEventsReload, getEvents, { busy: true }),
  createActionSaga(userMessageTemplates, getTemplates),
  createActionSaga(messageTemplateDelete, deleteTemplate),
  createActionSaga(messageTemplateReload, getTemplate, { busy: true }),
  createFormSaga(messageTemplateSave, saveTemplate, {
    message: 'Template saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/templates/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(messageTemplateReload, result)
    },
  }),
  createActionSaga(applicationNumbers, getNumbers),
  createActionSaga(applicationKey, generateKey),
  createFormSaga(applicationFeedbackAndInquiry, startFeedbackAndInquiry, {
    message: 'Feedback and inquiry started',
  }),
  createFormSaga(applicationQuickMessage, sendQuickMessage, {
    message: 'Message sent',
  }),
  createFormSaga(applicationGroupMessage, sendGroupMessage, {
    message: 'Message sent',
  }),
  createFormSaga(applicationCallToAction, startCallToAction, {
    message: 'Call to action started',
  }),
  createFormSaga(applicationDialog, startDialog, {
    message: 'Dialog started',
  }),
  createFormSaga(applicationInbox, startInbox, {
    message: 'Inbox started',
  }),
  createActionSaga(eventDelete, deleteEvent),
  createActionSaga(userApplications, getApplications),
  createActionSaga(applicationToggle, toggleApplication),
  createActionSaga(applicationMessages, getMessages),
  createActionSaga(applicationMessagesReload, getMessages, {
    busy: true,
  }),
  createActionSaga(applicationTasks, getTasks),
  createActionSaga(smsInboxFolder, getInboxFolders),
  createActionSaga(applicationDialogReports, getDialogReports),
  createActionSaga(applicationMoDialogReports, getMoDialogReports),
  createActionSaga(applicationCallToActionReports, getCallToActionReports),
  createActionSaga(applicationDrafts, getDrafts),
  createActionSaga(applicationDraftsReload, getAllDrafts, { busy: true }),
  createActionSaga(applicationDraftsAll, getAllDrafts),
  createActionSaga(applicationDraftsDelete, deleteApplicationDraft),
  createActionSaga(applicationDraftsEdit, editApplicationDraft),
  createActionSaga(applicationDraftSingle, getApplicationDraft),
  createActionSaga(applicationDraftSave, saveApplicationDraft),
]

export default messages
