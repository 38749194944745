import { z } from 'zod'

import { flowFunctionResponse } from 'app/types/zod/flow/flow-function'
import { FlowStatus, FlowType, StatType } from '../../../flow'
import {
  apiEntityFieldsWithStatus,
  datetimeStringWithOffset,
  integer,
  optionalInteger,
} from '../../utils'
import { matcher, matcherApi, matcherResponse } from '../matcher'

const flowEventSchedule = z.optional(
  z
    .object({
      scheduled_datetime: datetimeStringWithOffset,
      schedule_type: z.literal('SCHEDULED'),
    })
    .or(z.object({}).strict())
)

export const flowEventBase = z
  .object({
    id: z.optional(z.number()),
    flow_type: z.nativeEnum(FlowType),
    stat_type: z.nativeEnum(StatType).or(z.literal('')),
    flowevent_matchers: z.array(matcher),
    flowevent_schedule: z.optional(flowEventSchedule),
  })
  .passthrough()

const flowEventApiFields = z.object({
  parent: z.nullable(z.number()),
  applicationevent_id: z.number(),
  customerrouterule_id: z.optional(z.number()),
  level: z.number(),
  order: z.number(),
  flowevent_matchers: z.array(matcherApi),
  flowevent_schedule: flowEventSchedule,
})

export const flowEventApiBase = flowEventBase
  .merge(flowEventApiFields)
  .merge(apiEntityFieldsWithStatus)

export type FlowEventBase = z.infer<typeof flowEventBase>

export type FlowEventBaseApi = z.infer<typeof flowEventApiBase>

// v2

// These are updated versions of flow data schemas. We'll leave
// the previous schemas untouched so that there's no need to worry about
// Group Subscription, Verify and RCS
export const flowEventPartialFields: Record<string, true> = {
  id: true,
  applicationevent_id: true,
  created: true,
  customerrouterule_id: true,
  level: true,
  order: true,
  parent: true,
  status: true,
  settings_props: true,
  flowevent_schedule: true,
}

export const flowEventResponse = z
  .object({
    id: integer,
    applicationevent_id: integer,
    created: datetimeStringWithOffset,
    customerrouterule_id: optionalInteger,
    flow_functions: z.array(flowFunctionResponse),
    flowevent_matchers: z.array(matcherResponse),
    flow_type: z.nativeEnum(FlowType),
    level: integer,
    order: integer,
    parent: integer,
    stat_type: z.nativeEnum(StatType).or(z.literal('')),
    status: z.nativeEnum(FlowStatus),
    settings_props: z.any(), // TODO: settings_props type
    flowevent_schedule: z
      .object({
        scheduled_datetime: datetimeStringWithOffset,
        scheduled_type: z.literal('SCHEDULED'),
      })
      .or(z.object({})),
  })
  .strict()
  .describe('flowEventResponse')
