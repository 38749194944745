import { z } from 'zod'

export const Direction = z.enum(['MO', 'MT'])

export const Reason = z.enum([
  'BUFFERED',
  'EXPIRED',
  'CANCELED',
  'FILTERED',
  'INVALID',
  'TERMINAL_ERROR',
  'CARRIER_ERROR',
  'BILLING_ERROR',
  'SEND_ERROR',
  'LIMIT_EXCEEDED',
  'APP_ERROR',
  'RESTRICTED',
  'SCHEDULED',
  'UNKNOWN_NUMBER',
  'ABSENT_SUBSCRIBER',
  'TEMPLATE_ERROR',
  'UNKNOWN_ERROR',
  'SUBSCRIBER_ERROR',
  'FALLBACK',
])

export const RichCardOrientation = z.enum([
  'UNSPECIFIED',
  'HORIZONTAL',
  'VERTICAL',
])

export const RichCardAlignment = z.enum(['UNSPECIFIED', 'LEFT', 'RIGHT'])

export const RichCardMediaHeight = z.enum([
  'UNSPECIFIED',
  'SHORT',
  'MEDIUM',
  'TALL',
])

export type RichCardOrientationEnum = z.infer<typeof RichCardOrientation>

export type RichCardMediaHeightEnum = z.infer<typeof RichCardMediaHeight>

export type RichCardAlignmentEnum = z.infer<typeof RichCardAlignment>

export type CarouselCardWidthEnum = z.infer<typeof CarouselCardWidth>

export const CarouselCardWidth = z.enum(['UNSPECIFIED', 'SMALL', 'MEDIUM'])

export const RcsFileContentType = z.enum(['IMAGE', 'VIDEO', 'MEDIA'])

export const SystemApiSource = z.enum([
  'SRC_APPLICATION',
  'SRC_HTTP',
  'SRC_HTTP2RCS',
  'SRC_SMPP',
  'SRC_SMS',
  'SRC_EMAIL',
  'SRC_OPENHTTP',
  'SRC_RCS',
])

export const RouteRuleSource = z.enum([
  'SRC_HTTP2RCS',
  'SRC_HTTP',
  'SRC_SMPP',
  'SRC_SMS',
  'SRC_EMAIL',
  'SRC_OPENHTTP',
  'SRC_APPLICATION',
])

export const RouteRuleDestination = z.enum([
  'DST_SMS',
  'DST_RCS',
  'DST_EMAIL',
  'DST_HTTP',
  'DST_HTTP_INT',
  'DST_SMPP',
  'DST_OPENHTTP',
  'DST_HTTP_PULL',
  'DST_APPLICATION',
])

export const statusEnum = z.enum(['ACTIVE', 'PASSIVE'])

export type StatusEnumType = z.infer<typeof statusEnum>
