import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System accounts initial state
 */
export const accountsState = {
  user: {},
}

/**
 * System accounts reducer
 */
export default handleActions(
  {
    ACCOUNT_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        user: extend({}, state.user, payload || {}),
      })
    },
    ACCOUNT_RESET: (state) => {
      return extend({}, state, {
        user: {},
      })
    },
  },
  accountsState
)
