import { z } from 'zod'

import { companyApi } from '../../common/company'
import {
  datetimeStringWithOffset,
  hexColor,
  nonEmptyString,
  nullableString,
  optionalString,
  optionalUrl,
  requiredEnum,
} from '../../utils'

export enum RcsAgentStatus {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export enum RcsAgentBillingCategory {
  CONVERSATIONAL = 'CONVERSATIONAL',
  SINGLE_MESSAGE = 'SINGLE_MESSAGE',
}

export enum RcsAgentUseCase {
  OTP = 'ONE_TIME_PASSWORD',
  TRANSACTIONAL = 'TRANSACTIONAL',
  PROMOTIONAL = 'PROMOTIONAL',
  MULTI_USE = 'MULTI_USE',
}

export enum RcsAgentType {
  GOOGLE_RBM = 'GOOGLE_RBM',
  QURIIRI_RCS = 'QURIIRI_RCS',
}

const clientToken = z.optional(z.string().max(64))

const agentBaseSchema = z.object({
  agent_id: z.nullable(nonEmptyString),
  display_name: nonEmptyString.max(128),
  status: requiredEnum(RcsAgentStatus),
  billing_category: requiredEnum(RcsAgentBillingCategory),
  use_case: requiredEnum(RcsAgentUseCase),
  agent_type: requiredEnum(RcsAgentType),
  color: z.optional(hexColor).or(z.literal('')),
  banner_image: nullableString,
  logo: nullableString,
  client_token: clientToken,
})

const agentApiFields = z.object({
  id: z.number(),
  modified: datetimeStringWithOffset,
  created: datetimeStringWithOffset,
  email_contacts: z.array(z.any()),
  web_contacts: z.array(z.any()),
  phone_contacts: z.array(z.any()),
  client_token: z.nullable(clientToken),
  customer: companyApi,
  agent_key: z.string(),
  verified_subscription: z.boolean(),
})

const agentConfigurationBaseSchema = z.object({
  jwt: z.preprocess(
    (value) => {
      if (typeof value !== 'string') {
        return value
      }
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    },
    z
      .object(
        // The contents of the JSON are not validated for now.
        // There are more than one valid structures and they are
        // subject to change. We validate that it's an object and
        // let all fields pass through.
        {
          // type: nonEmptyString,
          // project_id: nonEmptyString,
          // private_key_id: nonEmptyString,
          // private_key: nonEmptyString,
          // client_email: nonEmptyString.email(),
          // client_id: nonEmptyString,
          // auth_uri: urlString,
          // token_uri: urlString,
          // auth_provider_x509_cert_url: urlString,
          // client_x509_cert_url: urlString,
        },
        {
          invalid_type_error: 'Please provide a valid JWT payload',
        }
      )
      .passthrough() // Remove if the JSON content is validated
      .transform((value) => JSON.stringify(value))
  ),
  rcs_agent_id: z.number(),
})

const agentConfigurationApiFields = z.object({
  id: z.number(),
  modified: datetimeStringWithOffset,
  created: datetimeStringWithOffset,
})

export const agentConfigurationSchema = agentConfigurationBaseSchema.describe(
  'agentConfigurationSchema'
)

export const agentConfigurationApiSchema = agentConfigurationBaseSchema
  .merge(agentConfigurationApiFields)
  .describe('agentConfigurationApiSchema')

export const agentConfigurationApiSchemaArray = z
  .array(agentConfigurationApiSchema)
  .describe('agentConfigurationApiSchema[]')

export const agent = agentBaseSchema
  .extend({
    privacy_policy: optionalUrl,
    terms_of_service: optionalUrl,
    description: optionalString,
    customer_id: z.number(),
  })
  .passthrough()
  .describe('agent')

export const agentApi = agentBaseSchema
  .merge(agentApiFields)
  .extend({
    privacy_policy: z.nullable(optionalUrl),
    terms_of_service: z.nullable(optionalUrl),
    description: z.nullable(optionalString),
  })
  .strict()
  .describe('agentApi')

export const agentApiArray = z.array(agentApi).describe('agentApiArray')

const customerAgentApi = agentApi
  .omit({
    agent_id: true,
    agent_key: true,
    agent_type: true,
    client_token: true,
    verified_subscription: true,
    customer: true,
  })
  .extend({
    customer_id: z.number(),
  })
  .strict()

export const customerAgentApiArray = z
  .array(customerAgentApi)
  .describe('customerAgentApiArray')

export type CustomerAgentApi = z.infer<typeof customerAgentApi>

export type AgentConfiguration = z.infer<typeof agentConfigurationSchema>

export type Agent = z.infer<typeof agent>

export type AgentApi = z.infer<typeof agentApi>

export type AgentConfigurationApi = z.infer<typeof agentConfigurationApiSchema>
