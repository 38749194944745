import ThemeDefault from './default'

const ThemeViestipalvelu = {
  ...ThemeDefault,
  primary: '#00CE37',
  primary2: '#00A52C',
  primary3: '#007C21',

  secondary: '#FF9800',
  secondary2: '#F57C00',
  secondary3: '#CF4900',

  textPrimary: '#001505',
  textPrimary2: '#3D3E3D',
  textPrimary3: '#666867',
  textPrimary4: '#CCD0CD',
  textPrimary5: '#F2F3F3',
  textPrimary6: '#F2F3F3',

  background: '#FFFFFF',
  backgroundInverted: '#001505',

  rcs: {
    ...ThemeDefault.rcs,
    virtualScreenColor: '#dbebff',
    virtualScreenBorderColor: '#b0b7bf',
    virtualScreenHighlightColor: '#006dfc',
    virtualScreenIconColor: '#737e8c',
  },
}

export default ThemeViestipalvelu
