import { createActionSaga, createFormSaga, settingsUser } from '../../api'
import { actionSuccess } from '../../common/action'

export const settingsAccountDelete = 'settings-account-delete'
export const settingsAccountReload = 'settings-account-reload'
export const settingsAccountSave = 'settings-account-save'
export const settingsAccounts = 'settings-accounts'
export const settingsAccountUserCompanies = 'settings-account-user-companies'
export const settingsAccountUserCompaniesAssigned =
  'settings-account-user-companies-assigned'
export const settingsAccountUserCompaniesUnassigned =
  'settings-account-user-companies-unassigned'
export const settingsAccountUserCompaniesAssignedReload =
  'settings-account-user-companies-assigned-reload'
export const settingsAccountUserCompaniesUnassignedReload =
  'settings-account-user-companies-unassigned-reload'

const {
  deleteAccount,
  getAccount,
  getAccounts,
  saveAccount,
  getAssignedCompanies,
  getUnassignedCompanies,
} = settingsUser

const accounts = [
  createActionSaga(settingsAccountReload, getAccount, {
    busy: true,
  }),
  createActionSaga(settingsAccounts, getAccounts),
  createActionSaga(settingsAccountUserCompaniesAssigned, getAssignedCompanies),
  createActionSaga(
    settingsAccountUserCompaniesAssignedReload,
    getAssignedCompanies,
    {
      busy: true,
    }
  ),
  createActionSaga(
    settingsAccountUserCompaniesUnassigned,
    getUnassignedCompanies
  ),
  createActionSaga(
    settingsAccountUserCompaniesUnassignedReload,
    getUnassignedCompanies,
    {
      busy: true,
    }
  ),
  createActionSaga(settingsAccountDelete, deleteAccount),
  createFormSaga(settingsAccountSave, saveAccount, {
    message: (payload, result) => {
      return result.new
        ? 'Profile added successfully'
        : 'Profile was updated successfully'
    },
    success: function* (result) {
      yield actionSuccess(settingsAccountReload, result)
    },
  }),
]

export default accounts
