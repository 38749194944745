import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Mo numbers initial state
 */
export const moNumbersState = {
  customers: {},
  moNumber: {},
}

/**
 * System mo numbers reducer
 */
export default handleActions(
  {
    MO_NUMBERS_CUSTOMERS_RESET: (state) => {
      return extend({}, state, {
        customers: {},
      })
    },
    MO_NUMBER_CUSTOMERS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        customers: payload || {},
      })
    },
    CUSTOMER_MO_NUMBERS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        customers: payload || {},
      })
    },
    MO_NUMBER_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        moNumber: extend({}, state.moNumber, payload || {}),
      })
    },
    MO_NUMBER_RESET: (state) => {
      return extend({}, state, {
        moNumber: {},
      })
    },
  },
  moNumbersState
)
