import { isArray, pick } from 'lodash'

/**
 * Icon
 */
export default function Icon(props) {
  const { className } = props
  let { value } = props
  const common = pick(props, ['onClick'])
  if (!isArray(value)) {
    value = value ? [value] : []
  }
  return (
    <span className={'Icon' + (className ? ' ' + className : '')} {...common}>
      {value.map((icon, key) => {
        return (
          <i className="material-icons" key={key}>
            {icon}
          </i>
        )
      })}
    </span>
  )
}
