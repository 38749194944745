import {
  createActionSaga,
  createFormSaga,
  router as routerApi,
} from '../../api'
import { actionSuccess } from '../../common/action'

export const userRouterSettings = 'user-router-settings'
export const routerSettingsSave = 'router-settings-save'

export const userRouterResources = 'user-router-resources'

export const userRouterApiKey = 'user-router-api-key'

export const userRoutings = 'user-routings'
export const routingDelete = 'routing-delete'
export const routingReload = 'routing-reload'
export const routingSave = 'routing-save'

export const userOpenHttpInfo = 'user-openhttp-info'
export const userOpenHttpParams = 'user-openhttp-params'

const {
  deleteRouting,
  getResources,
  getApiKey,
  getRouting,
  getRoutings,
  getSettings,
  saveRouting,
  saveSettings,
  getOpenHttpInfo,
  getOpenHttpParams,
} = routerApi

const router = [
  createActionSaga(userRouterSettings, getSettings),
  createActionSaga(userRouterResources, getResources),
  createActionSaga(userOpenHttpInfo, getOpenHttpInfo),
  createActionSaga(userOpenHttpParams, getOpenHttpParams),
  createActionSaga(userRouterApiKey, getApiKey),
  createFormSaga(routerSettingsSave, saveSettings, {
    message: 'Router settings saved',
  }),
  createActionSaga(userRoutings, getRoutings),
  createActionSaga(routingDelete, deleteRouting),
  createActionSaga(routingReload, getRouting, { busy: true }),
  createFormSaga(routingSave, saveRouting, {
    message: 'Routing saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/router/routings/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(routingReload, result)
    },
  }),
]

export default router
