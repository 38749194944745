import { z } from 'zod'

import { withEditorId } from '../../utils'
import {
  callNumberActionContent,
  createCalendarEventActionContent,
  openUrlActionContent,
  viewLocationActionContent,
} from '../content/suggestions/actions'
import { suggestedReplyContent } from '../content/suggestions/replies'

export const suggestedReply = withEditorId.merge(suggestedReplyContent)

export const openUrlAction = withEditorId.merge(openUrlActionContent)

export const callNumberAction = withEditorId.merge(callNumberActionContent)

export const createCalendarEventAction = withEditorId.merge(
  createCalendarEventActionContent
)

export const viewLocationAction = withEditorId.merge(viewLocationActionContent)

const suggestedAction = z.union([
  openUrlAction,
  callNumberAction,
  createCalendarEventAction,
  viewLocationAction,
])

const editorSuggestion = suggestedAction.or(suggestedReply)

export const editorSuggestions = z.array(editorSuggestion)

export const withSuggestions = z.object({
  suggestions: editorSuggestions,
})

export type RcsSuggestedReply = z.infer<typeof suggestedReply>

export type RcsSuggestedAction = z.infer<typeof suggestedAction>

export type CallNumberAction = z.infer<typeof callNumberAction>

export type OpenUrlAction = z.infer<typeof openUrlAction>

export type CreateCalendarEventAction = z.infer<
  typeof createCalendarEventAction
>

export type ViewLocationAction = z.infer<typeof viewLocationAction>

export type WithSuggestions = z.infer<typeof withSuggestions>

export type RcsSuggestion = RcsSuggestedReply | RcsSuggestedAction

export const isSuggestedAction = (
  suggestion: RcsSuggestion
): suggestion is RcsSuggestedAction => 'action' in suggestion

export const isSuggestedReply = (
  suggestion: RcsSuggestion
): suggestion is RcsSuggestedReply => !('action' in suggestion)

export const isOpenUrlAction = (
  action: RcsSuggestedAction
): action is OpenUrlAction => 'url' in action.action

export const isCallNumberAction = (
  action: RcsSuggestedAction
): action is CallNumberAction => 'number' in action.action

export const isCreateCalendarEventAction = (
  action: RcsSuggestedAction
): action is CreateCalendarEventAction => 'start_datetime' in action.action

export const isViewLocationAction = (
  action: RcsSuggestedAction
): action is ViewLocationAction => 'show' in action.action
