import { extend, pick } from 'lodash'
import { handleActions } from 'redux-actions'

const ERROR_MESSAGE = 'There was an error displaying the data'

/**
 * Table initial state
 */
export const tableState = {
  busy: false,
  count: 0,
  limit: 25,
  page: 1,
  results: [],
  showcolumns: [],
  additional: {},
  search: {},
  error: null,
}

/**
 * Table reducer
 */
export default handleActions(
  {
    TABLE_BUSY: (state, { payload }) => {
      return extend({}, state, {
        busy: !!payload,
      })
    },
    TABLE_RESET: () => {
      return extend({}, tableState)
    },
    TABLE_QUERY: (state, { payload }) => {
      return extend(
        {},
        state,
        pick(payload, ['limit', 'order', 'page', 'search', 'sort']),
        {
          busy: true,
          error: null,
        }
      )
    },
    TABLE_QUERY_COMPLETE: (state) => {
      return extend({}, state, {
        busy: false,
      })
    },
    TABLE_QUERY_SUCCESS: (
      state,
      { payload: { count, results, showcolumns, additional } }
    ) => {
      return extend({}, state, {
        count,
        results,
        showcolumns,
        additional,
      })
    },
    TABLE_QUERY_FAIL: (state) => {
      return extend({}, state, {
        error: ERROR_MESSAGE,
        count: 0,
        results: [],
      })
    },
  },
  tableState
)
