import { ReactNode } from 'react'
import { FormikHandlers, FormikHelpers } from 'formik'
import { omit } from 'lodash'

import { routingSourceTypes } from 'app/api/Router'
import { BreadcrumbItem } from 'app/home/Home'

export interface Application {
  id: number
  modulename: string
  name: string
  created: string
  short_description: string
  channels: string
  icon: string
  applicationevent_key: string
}

export interface SystemApplication extends Application {
  apikeys: string[]
  default_template?: {
    id: number
    message: string
  }
}

export interface UiApplication {
  id: number
  active: boolean
  description: string
  name: string
  title: string
  icon: string
  tags: any[]
}

export enum ApplicationAction {
  CREATE = 'create',
  EDIT = 'edit',
  COPY = 'copy',
}

export type WithApiKey<T = {}> = T & {
  apikey?: string
}

interface ApplicationEvent {
  id: number
  status: ApplicationEventStatus
  applicationid: number
  name: string
  created: string
  applicationevent_key: string
  eventid: string
  is_flow_application: boolean
}

interface ApplicationEventDraftBase {
  name: string
  systemapplication: number
  modified: string
  created: string
  eventdata?: any
}

export interface ApplicationEventDraft extends ApplicationEventDraftBase {
  draftid: string
}

export type ApplicationEventDraftId = ApplicationEventDraft['draftid']

export interface ApplicationEventDraftListItem
  extends ApplicationEventDraftBase {
  id: string
}

export enum ApplicationEventStatus {
  INITIALIZING = 'INITIALIZING',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  FINISHED = 'FINISHED',
}

type BooleanAsString = 'true' | 'false'

interface SmsNotification {
  smsfrom: string
  msisdns: string[]
  groups: Group[]
  message: string
  notificationgroup: BooleanAsString
}

interface EmailNotification {
  email_from: string
  email_to: string
  email_cc: string
  email_bcc: string
  email_subject: string
  email_message: string
  email_to_notification: BooleanAsString
  email_cc_notification: BooleanAsString
  email_bcc_notification: BooleanAsString
}

interface HierarchyItem {
  id: number
  applicationevent_id: number
  parent: number
  level: number
  choices: string
  response: string
  errormessage: string
  sms_notification: SmsNotification
  email_notification: EmailNotification
  children: HierarchyItem[]
}

export interface Group {
  id?: number
  name: string
  member_count?: number
  recipient_count?: number
  status?: string
  created?: string
}

export interface Contact {
  address?: any
  birthday?: any
  city?: any
  created?: string
  customerNumber?: string
  email?: string
  firstName?: string
  gender?: any
  id: number
  lastName?: string
  limitedReceivesms?: any
  mobilePhone?: string
  parameter1?: any
  parameter2?: any
  parameter3?: any
  parameter4?: any
  postalCode?: any
  receiveSmsMessages?: any
  smsSendingFailed?: number
}

export enum ComponentStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export enum MessagingChannel {
  SMS = 'SMS',
  RCS = 'RCS',
}

export enum MessageTemplateType {
  GENERAL = 'GENERAL',
  VERIFY = 'VERIFY',
}

export enum ApiKeyStatus {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum BillingDataStatus {
  CREATED = 'CREATED',
  TRANSFERRED = 'TRANSFERRED',
  ARCHIVED = 'ARCHIVED',
  INVALID_DATA = 'INVALID_DATA',
  SEND_ERROR = 'SEND_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum InvoiceStatus {
  CREATED = 'CREATED',
  BILLED = 'BILLED',
  FINISHED = 'FINISHED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  DUPLICATE_DATA = 'DUPLICATE_DATA',
  REQUEST_NOT_UNIQUE = 'REQUEST_NOT_UNIQUE',
  SERVICE_ACCESS_ERROR = 'SERVICE_ACCESS_ERROR',
  SYSTEM_MAINTENANCE = 'SYSTEM_MAINTENANCE',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  PERIOD_LOCK = 'PERIOD_LOCK',
  INVALID_DATA = 'INVALID_DATA',
  UNKNOWN_ERROR = 'GENERAL_ERROR',
}

export type Direction = 'MO' | 'MT'

export interface MessageTemplate {
  id?: number
  channel: MessagingChannel
  apiuse: boolean
  name: string
  message: string
  type: MessageTemplateType
  created?: string
  default: boolean
}

export interface VerifyMessage {
  id?: number
  message: string
}

export enum DateResolution {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export interface FlowStatistics<T> {
  total_messages: number
  chart_data: {
    xkey: string
    data: T[]
  }
}

export interface LineChartLabel<T> {
  label: string
  color: string
  key: keyof T
}

export interface LineChartSeries {
  name: string
  type: 'line'
  data: number[]
  itemStyle: {
    color: string
  }
  lineStyle: {
    color: string
  }
}

export type LineChartData = {
  data: {
    labels: string[]
    series: LineChartSeries[]
  }
}

export type PieChartItem = {
  value: number
  name: string
}

export type PieChartData = {
  data: PieChartItem[]
  colors: string[]
}

export type ValueOf<T> = T[keyof T]
export interface VerifyMessage {
  message: string
}

const srcOptions = omit(routingSourceTypes, 'SRC_APPLICATION')

export type SourceOption = keyof typeof srcOptions

export type AllowedApp = Pick<Application, 'id' | 'modulename'>

export interface ApiKeyPostData {
  name: string
}

export enum SinglePurposeApiKeyType {
  APPLICATION = 'APPLICATION',
  ROUTER = 'ROUTER',
}

export interface SinglePurposeApiKey {
  apikey: string
  name: string
  modulename: string
  type: SinglePurposeApiKeyType
  used_in: number
}

export interface TableResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export interface TableRequestOptions {
  customerId?: number
  download?: string
  limit?: number
  offset?: number
  filters?: TableRequestFilters
}

export interface TableRequestFilters {
  sorted?: `${string}:${SortOrder}`
  page_size: number
  search: SearchParams
}

export type TableRequest<T> = (
  options: TableRequestOptions
) => Promise<TableResponse<T>>

export interface SortParams {
  key: string
  order: SortOrder
}

export type SearchParams = {
  [P in string]?: string | number
}

export type StringKey<T> = keyof T & string

export type FormErrors = {
  [key: string]: string | string[]
}

export type TimeWithoutSeconds = `${number}:${number}`
export type TimeWithSeconds = `${number}:${number}:${number}`
export type Date = `${number}-${number}-${number}`
export type DateAndTime = `${Date}T${TimeWithSeconds}`

export type WithSetBreadcrumb<T = {}> = T & {
  setBreadcrumb?: (items: BreadcrumbItem[]) => ReactNode
}

export type Id = string | number

export interface Price {
  id?: number
  currency?: string
  product?: number
  price: number
  price_vat: number
  pricing_type?: PricingType
  valid_from?: string
  current?: boolean
}

export enum PricingType {
  PRICE = 'PRICE',
  PURCHASE = 'PURCHASE',
  CUSTOMPRICE = 'CUSTOMPRICE',
}

export enum BillingType {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING',
  TRANSACTION = 'TRANSACTION',
}

export enum TransactionType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  RCS = 'RCS',
  SUCCESSFUL_VERIFY = 'SUCCESSFUL_VERIFY',
}

export interface BillingItem {
  id: number
  amount: number
  billing_ref: string
  created: string
  customer_id: number
  customer_name: string
  ext_id: string
  free_text: string
  price: number
  product_billing_type: BillingType
  product_code: string
  product_currency: string
  product_name: string
  product_id: number
  purchase_price: number
  purchase_vat: number
  status: string
  value_datetime: string
  vat: number
  total_sum: number
  total_sum_incl_vat: number
}

export interface InvoiceItem
  extends Omit<
    BillingItem,
    'customer_id' | 'customer_name' | 'ext_id' | 'status'
  > {
  billing_info: string
  customer_billing_ref: string
  customer_invoice_id: number
}

export interface Invoice {
  id: number
  billing_date: string
  billing_interval: number
  created: string
  customer_id: number
  customer_name: string
  ext_id: string
  status: string
  total_sum: number
  total_sum_incl_vat: number
  vat_code: string
  payment_term: number
  our_billing_ref?: string
  your_billing_ref?: string
}

export enum Currency {
  EUR = 'EUR',
}

export interface ProductGroup {
  id: number
  name: string
  description?: string
  created?: string
  modified?: string
}

export interface SMSTransactionBilling {
  id?: number
  direction: Direction
  created?: string
  modified?: string
  operator: string | null
  countrycode: string | null
  product_id?: number
}

export interface Resources {
  httpCommonApi: string
  httpApiDocs?: string
  smsApi: string
  groupsApi: string
  appApi: string
  smsHttpApi: string
  smsEmailApi: string
  smsHttpPullApi: string
  firewallsIps: string
  infoPricingUrl: string
}

export function getTypedKeys<T extends object>(item: T): (keyof T)[] {
  return Object.keys(item) as (keyof T)[]
}

// URL param types
export interface IdParams {
  id: string
  [k: string]: string | undefined
}

export interface Customer {
  id: number
  name: string
  licensecustomer: boolean
  vatcode: string
  extid: string
  status: string
  demo: string
  mtlimit: number
  filtering: boolean
  sender: string
  sendertype: string
  country_code: number
  maskmsisdn: boolean
  maskmessage: boolean
  throttle: number
  validityperiod: number
  info: string
  created: string
  delete_grace_period: string | null
}

interface DialogSmsAction {
  destination: string
  destinationtype: string
  keyword: string
  dialog_enddatetime: string
  status: string
}

interface SmsMessage {
  source: string
  senderlistid: number
  sourcetype: string
  groups: Group[]
  msisdns: string[]
  smsbody: string
}

interface ScheduleNow {
  send: 'SEND_NOW'
}
interface ScheduleOnce {
  send: 'SEND_SCHEDULED'
  schedule_senddatetime: string
}

export enum TimeResolution {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

interface ScheduleRecurring {
  send: 'SEND_RECURRING'
  rs_startdatetime: string
  rs_repeatinterval: string
  rs_repeatresolution:
    | TimeResolution.DAYS
    | TimeResolution.WEEKS
    | TimeResolution.MONTHS
  rs_weekday_mon: string
  rs_weekday_tue: string
  rs_weekday_wed: string
  rs_weekday_thu: string
  rs_weekday_fri: string
  rs_weekday_sat: string
  rs_weekday_sun: string
  rs_ends: string
  rs_ondatevalue: string
  rs_repeatedvalue: string
}

type ScheduleRecurringList = ScheduleRecurring[]

interface SchedulePeriodised {
  send: 'SEND_PERIODISED'
  ps_startdatetime: string
  ps_dayendtime: string
  ps_daystarttime: string
  ps_weekday_mon: string
  ps_weekday_tue: string
  ps_weekday_wed: string
  ps_weekday_thu: string
  ps_weekday_fri: string
  ps_weekday_sat: string
  ps_weekday_sun: string
  ps_enddatetime: string
  ps_remove_publicholidays: string
}

type ScheduleData =
  | ScheduleNow
  | ScheduleOnce
  | ScheduleRecurring
  | ScheduleRecurringList
  | SchedulePeriodised

export interface Remind {
  id: string
  fromApiTimestamp?: string // Used as a React key to trigger input component re-mount
  rm_remind: number
  rm_resolution:
    | TimeResolution.MINUTES
    | TimeResolution.HOURS
    | TimeResolution.DAYS
  remindbody: string
  rm_no_answer: boolean
  receivers?: number
}

interface SendSmsAction {
  id?: number
  enableduplicates?: boolean
  config?: string
  recipients?: number
  schedule?: ScheduleData
  status?: string
  remind?: Remind | Remind[]
  message: SmsMessage
}

interface DialogMessage {
  id: HierarchyItem['id']
  request: HierarchyItem['choices']
  response: HierarchyItem['response']
  dialog: {
    errorMessage: HierarchyItem['errormessage'] | null
    messages: DialogMessage[]
  }
  statistics: {
    answers: number
    percentage: number
  }
  sms: SmsNotification
  email: EmailNotification
}

interface UIDialogData {
  id: HierarchyItem['id']
  parentMessage: HierarchyItem['response']
  errorMessage: HierarchyItem['errormessage']
  dialogsmsaction: DialogSmsAction
  messages: DialogMessage[]
}

export interface DialogEvent extends WithApiKey<ApplicationEvent> {
  description: string | null
  dialog: UIDialogData
  dialog_data: HierarchyItem
  status: ApplicationEventStatus
  dialogsmsaction: DialogSmsAction
  sendsmsaction: SendSmsAction
}

export enum ContentType {
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
}

export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
  DATE_RANGE = 'daterange',
  DATE_RANGE_OBJECT = 'daterangeobject',
  DATE_TIME_RANGE = 'datetimerange',
  RANGE = 'range',
  VAT = 'vat',
  HIDDEN = 'hidden',
  PRICE = 'price',
}

interface OptionalFilterFormItem {
  label?: string
  defaultValue?: any
  key: string
  filter:
    | FilterType
    | {
        options: JSX.Element[]
      }
    | React.FC<{
        key: string
        value: any
        setFieldValue: FormikHelpers<any>['setFieldValue']
        handleChange: FormikHandlers['handleChange']
      }>
  renderTagValue?: (value: SearchParams[string], params: SearchParams) => string
  required?: false
}

interface RequiredFilterFormItem
  extends Omit<OptionalFilterFormItem, 'required'> {
  defaultValue: any
  required: true
}

export type FilterFormItem = OptionalFilterFormItem | RequiredFilterFormItem
