import { z } from 'zod'

import { datetimeStringWithOffset, integer } from '../../utils'
import {
  applicationInfoRecipients,
  flowApplicationData,
  flowApplicationDataApi,
} from '.'

const rcsApplicationInfo = z.object({
  sendinfo: z.object({
    agent_id: integer,
    recipients: applicationInfoRecipients,
    send: z.nullable(datetimeStringWithOffset),
  }),
  receiveinfo: z.object({
    agent_id: integer,
  }),
})

export const rcsApplicationData = flowApplicationData.extend({
  application_info: rcsApplicationInfo,
})

export const rcsApplicationDataApi = flowApplicationDataApi.extend({
  application_info: rcsApplicationInfo,
})
