import moment from 'moment'
import { z } from 'zod'

import {
  datetimeStringWithOffset,
  nonEmptyString,
  phoneNumber,
  requiredNumber,
  urlString,
} from 'app/types/zod/utils'
import { suggestionFields } from './fields'

export const openUrlActionContent = suggestionFields.extend({
  action: z.object({
    url: urlString,
  }),
})

export const callNumberActionContent = suggestionFields.extend({
  action: z.object({
    number: phoneNumber,
  }),
})

export const createCalendarEventActionContent = suggestionFields.extend({
  action: z
    .object({
      start_datetime: datetimeStringWithOffset,
      end_datetime: datetimeStringWithOffset,
      title: nonEmptyString,
      description: nonEmptyString,
    })
    .refine(
      (val) => {
        return moment(val.start_datetime) <= moment(val.end_datetime)
      },
      {
        message: 'End date must be after begin date',
        path: ['end_datetime'],
      }
    ),
})

const longitudeMsg = {
  message: 'Longitude must be a value between -180 and 180 degrees',
}
const latitudeMsg = {
  message: 'Latitude must be a value between -90 and 90 degrees',
}

const rcsLocation = z.object({
  label: nonEmptyString,
  longitude: requiredNumber.min(-180, longitudeMsg).max(180, longitudeMsg),
  latitude: requiredNumber.min(-90, latitudeMsg).max(90, latitudeMsg),
})

export const viewLocationActionContent = suggestionFields.extend({
  action: z.object({
    show: rcsLocation,
  }),
})

export const suggestedActionContent = z.union([
  openUrlActionContent,
  callNumberActionContent,
  createCalendarEventActionContent,
  viewLocationActionContent,
])

export type RcsSuggestedActionContent = z.infer<typeof suggestedActionContent>
