export const fullWidth = 360
export const itemWidth = 300
export const smallWidth = 120
export const mediumWidth = 232

const ThemeDefault = {
  primary: '#00CE37',
  primary2: '#00A52C',
  primary3: '#007C21',
  danger: '#d13729',

  secondary: '#FF9800',
  secondary2: '#F57C00',
  secondary3: '#CF4900',

  textPrimary: '#001505',
  textPrimary2: '#3D3E3D',
  textPrimary3: '#666867',
  textPrimary4: '#CCD0CD',
  textPrimary5: '#F2F3F3',
  textPrimary6: '#F2F3F3',

  rcs: {
    borderRadius: '15px',
    error: '#f6d7d4',
    fullWidthBorderRadius: '15px',
    itemWidth: `${itemWidth}px`,
    fullWidth: `${fullWidth}px`,
    smallWidth: `${smallWidth}px`,
    mediumWidth: `${mediumWidth}px`,
    fullWidthMarginLeft: `${itemWidth - fullWidth}px`,
    itemMarginBottom: '1rem',
    itemPadding: '0.75rem',
    itemMinHeight: '50px',
    virtualScreenWidth: '400px',
    virtualScreenColor: '#e3f2e4',
    virtualScreenBorderColor: '#b1bfb2',
    virtualScreenHighlightColor: '#00a52c',
    virtualScreenIconColor: '#738c74',
  },
}

export default ThemeDefault
