import { throwErrorIfHasPendingJobs } from 'app/globalState'

export class QuickMessage {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/quickmessage'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return response
      })
  }

  post(request) {
    throwErrorIfHasPendingJobs()

    const { customerId } = request

    let data = {
      applicationid: request.applicationId,
      applicationevent_key: request.applicationEventKey,
      name: request.name,
      source: request.source,
      senderlistid: request.senderlistid,
      sourcetype: request.sender_type,
      msisdns: request.msisdns.filter(Boolean),
      smsbody: request.smsbody,
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }
}
