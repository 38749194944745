import { z } from 'zod'

import { editorId } from '../../utils'

const branchBase = z.object({
  flowEventId: z.optional(z.number()),
  flowFunctionId: z.optional(z.number()),
  editorId,
  root: z.boolean(),
  nodes: z.array(editorId),
})

export const sendRcsEventBranch = branchBase.extend({
  sendRcsFunctionId: z.optional(z.number()),
})

export type RcsBranch = z.infer<typeof sendRcsEventBranch>
