import { createActionSaga, user } from '../api'
import { change } from '../i18n'

export const userCustomer = 'user-customer'
export const userRefresh = 'user-refresh'
export const userInfo = 'user-info'
export const userLogout = 'user-logout'
export const senderList = 'sender-list'
export const defaultCustomer = 'default-customer'

const { getInfo, getSenderList, logout, refreshToken, saveDefaultCustomer } =
  user

const auth = [
  createActionSaga(userInfo, getInfo, {
    success: function* (info) {
      yield change(info.language.split('-')[0])
    },
  }),
  createActionSaga(senderList, getSenderList),
  createActionSaga(userRefresh, refreshToken),
  createActionSaga(userLogout, logout, {
    busy: true,
  }),
  createActionSaga(defaultCustomer, saveDefaultCustomer, {
    success: function* (response) {
      if (response.success === true) {
        yield (document.location.href = '/')
      }
    },
  }),
]

export default auth
