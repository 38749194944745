export class GroupSubscription {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/groupsubscription'
    this.abortUrl = 'app/abort'
  }

  get(request) {
    const { id, customerId } = request
    return this.api.get(`${this.endpointUrl}/${id}`, {}, true, {
      'X-Customer': customerId,
    })
  }

  post(request) {
    const { customerId, data } = request

    return this.api.post(`${this.endpointUrl}`, data, true, {
      'X-Customer': customerId,
    })
  }

  update(request) {
    const { id, customerId, action, data } = request
    return this.api.put(
      `${this.endpointUrl}/${id}`,
      {
        ...data,
        action,
      },
      true,
      {
        'X-Customer': customerId,
      }
    )
  }

  abort(request) {
    const { id, customerId } = request
    return this.api.get(`${this.abortUrl}/${id}`, {}, true, {
      'X-Customer': customerId,
    })
  }

  delete(request) {
    const { id, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  getStatistics(request) {
    const { id, customerId, dateRange, dateResolution, comparedRange } = request

    const splitDateRange = dateRange.split('-')
    const statistics_daterange = {
      from: splitDateRange[0].trim(),
      to: splitDateRange[1].trim(),
    }

    return this.api.get(`${this.endpointUrl}statistics/${id}`, {}, true, {
      'X-Customer': customerId,
      'X-filter': JSON.stringify({
        statistics_daterange,
        statistics_dateresolution: dateResolution,
        compared_daterange: comparedRange,
      }),
    })
  }
}
