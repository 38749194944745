import { z } from 'zod'

import { createEditorId } from 'app/service/applications/rcs/utils'
import { Direction, Reason } from '../../common/Enums'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  optionalString,
} from '../../utils'
import { rcsMediaFile } from '../content/media'

const replyFields = {
  content_type: z.literal('UserSuggestionReply'),
  user_suggestion_postback_data: nonEmptyString,
  user_suggestion_text: nonEmptyString,
  user_suggestion_type: z.enum(['ACTION', 'REPLY']),
}

const rcsErrorStatustext = z.object({
  error: z.object({
    code: integer,
    message: nonEmptyString,
    status: nonEmptyString,
  }),
})

export const rcsMessageLogEvent = z
  .object({
    agent_id: integer,
    billingref: z.nullable(optionalString),
    body: nonEmptyString,
    content_type: nonEmptyString,
    created: datetimeStringWithOffset,
    customer: integer,
    customername: nonEmptyString,
    destination: nonEmptyString,
    direction: Direction,
    drid: nonEmptyString,
    drurl: optionalString,
    extid: optionalString,
    reason: z.nullable(Reason.or(z.literal(''))),
    statustext: z.nullable(rcsErrorStatustext.or(z.literal(''))),
    sender: nonEmptyString,
    status: z.string(),
    statustime: datetimeStringWithOffset,
  })
  .describe('rcsMessageLogEvent')

export const rcsReplyLogEvent = rcsMessageLogEvent
  .extend(replyFields)
  .describe('rcsReplyLogEvent')

export const rcsLogEvent = z
  .union([rcsReplyLogEvent, rcsMessageLogEvent])
  .describe('rcsLogEvent')

export const rcsMoTextBody = z
  .object({
    text: z.string(),
  })
  .transform((val) => ({
    ...val,
    editorId: createEditorId(),
    suggestions: [],
  }))

export const rcsMoMediaBody = z.object({
  file: rcsMediaFile.extend({
    file_content_type: z.literal('FILE_CATEGORY_UNSPECIFIED'),
    thumbnail: nonEmptyString,
  }),
})

export type RcsErrorStatustext = z.infer<typeof rcsErrorStatustext>

export type RcsLogEvent = z.infer<typeof rcsLogEvent>
