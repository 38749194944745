export const rawCountryCodes = [
  {
    code: 'AF',
    countryCode: 93,
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    countryCode: 358,
    name: 'Aland Islands',
  },
  {
    code: 'AL',
    countryCode: 355,
    name: 'Albania',
  },
  {
    code: 'DZ',
    countryCode: 213,
    name: 'Algeria',
  },
  {
    code: 'AS',
    countryCode: 1684,
    name: 'AmericanSamoa',
  },
  {
    code: 'AD',
    countryCode: 376,
    name: 'Andorra',
  },
  {
    code: 'AO',
    countryCode: 244,
    name: 'Angola',
  },
  {
    code: 'AI',
    countryCode: 1264,
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    countryCode: 672,
    name: 'Antarctica',
  },
  {
    code: 'AG',
    countryCode: 1268,
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    countryCode: 54,
    name: 'Argentina',
  },
  {
    code: 'AM',
    countryCode: 374,
    name: 'Armenia',
  },
  {
    code: 'AW',
    countryCode: 297,
    name: 'Aruba',
  },
  {
    code: 'AU',
    countryCode: 61,
    name: 'Australia',
  },
  {
    code: 'AT',
    countryCode: 43,
    name: 'Austria',
  },
  {
    code: 'AZ',
    countryCode: 994,
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    countryCode: 1242,
    name: 'Bahamas',
  },
  {
    code: 'BH',
    countryCode: 973,
    name: 'Bahrain',
  },
  {
    code: 'BD',
    countryCode: 880,
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    countryCode: 1246,
    name: 'Barbados',
  },
  {
    code: 'BY',
    countryCode: 375,
    name: 'Belarus',
  },
  {
    code: 'BE',
    countryCode: 32,
    name: 'Belgium',
  },
  {
    code: 'BZ',
    countryCode: 501,
    name: 'Belize',
  },
  {
    code: 'BJ',
    countryCode: 229,
    name: 'Benin',
  },
  {
    code: 'BM',
    countryCode: 1441,
    name: 'Bermuda',
  },
  {
    code: 'BT',
    countryCode: 975,
    name: 'Bhutan',
  },
  {
    code: 'BO',
    countryCode: 591,
    name: 'Bolivia, Plurinational State of',
  },
  {
    code: 'BA',
    countryCode: 387,
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    countryCode: 267,
    name: 'Botswana',
  },
  {
    code: 'BR',
    countryCode: 55,
    name: 'Brazil',
  },
  {
    code: 'IO',
    countryCode: 246,
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'BN',
    countryCode: 673,
    name: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    countryCode: 359,
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    countryCode: 226,
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    countryCode: 257,
    name: 'Burundi',
  },
  {
    code: 'KH',
    countryCode: 855,
    name: 'Cambodia',
  },
  {
    code: 'CM',
    countryCode: 237,
    name: 'Cameroon',
  },
  {
    code: 'CA',
    countryCode: 1,
    name: 'Canada',
  },
  {
    code: 'CV',
    countryCode: 238,
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    countryCode: 345,
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    countryCode: 236,
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    countryCode: 235,
    name: 'Chad',
  },
  {
    code: 'CL',
    countryCode: 56,
    name: 'Chile',
  },
  {
    code: 'CN',
    countryCode: 86,
    name: 'China',
  },
  {
    code: 'CX',
    countryCode: 61,
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    countryCode: 61,
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CO',
    countryCode: 57,
    name: 'Colombia',
  },
  {
    code: 'KM',
    countryCode: 269,
    name: 'Comoros',
  },
  {
    code: 'CG',
    countryCode: 242,
    name: 'Congo',
  },
  {
    code: 'CD',
    countryCode: 243,
    name: 'Congo, The Democratic Republic of the Congo',
  },
  {
    code: 'CK',
    countryCode: 682,
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    countryCode: 506,
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    countryCode: 225,
    name: "Cote d'Ivoire",
  },
  {
    code: 'HR',
    countryCode: 385,
    name: 'Croatia',
  },
  {
    code: 'CU',
    countryCode: 53,
    name: 'Cuba',
  },
  {
    code: 'CY',
    countryCode: 357,
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    countryCode: 420,
    name: 'Czech Republic',
  },
  {
    code: 'DK',
    countryCode: 45,
    name: 'Denmark',
  },
  {
    code: 'DJ',
    countryCode: 253,
    name: 'Djibouti',
  },
  {
    code: 'DM',
    countryCode: 1767,
    name: 'Dominica',
  },
  {
    code: 'DO',
    countryCode: 1849,
    name: 'Dominican Republic',
  },
  {
    code: 'EC',
    countryCode: 593,
    name: 'Ecuador',
  },
  {
    code: 'EG',
    countryCode: 20,
    name: 'Egypt',
  },
  {
    code: 'SV',
    countryCode: 503,
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    countryCode: 240,
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    countryCode: 291,
    name: 'Eritrea',
  },
  {
    code: 'EE',
    countryCode: 372,
    name: 'Estonia',
  },
  {
    code: 'ET',
    countryCode: 251,
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    countryCode: 500,
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    countryCode: 298,
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    countryCode: 679,
    name: 'Fiji',
  },
  {
    code: 'FI',
    countryCode: 358,
    name: 'Finland',
  },
  {
    code: 'FR',
    countryCode: 33,
    name: 'France',
  },
  {
    code: 'GF',
    countryCode: 594,
    name: 'French Guiana',
  },
  {
    code: 'PF',
    countryCode: 689,
    name: 'French Polynesia',
  },
  {
    code: 'GA',
    countryCode: 241,
    name: 'Gabon',
  },
  {
    code: 'GM',
    countryCode: 220,
    name: 'Gambia',
  },
  {
    code: 'GE',
    countryCode: 995,
    name: 'Georgia',
  },
  {
    code: 'DE',
    countryCode: 49,
    name: 'Germany',
  },
  {
    code: 'GH',
    countryCode: 233,
    name: 'Ghana',
  },
  {
    code: 'GI',
    countryCode: 350,
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    countryCode: 30,
    name: 'Greece',
  },
  {
    code: 'GL',
    countryCode: 299,
    name: 'Greenland',
  },
  {
    code: 'GD',
    countryCode: 1473,
    name: 'Grenada',
  },
  {
    code: 'GP',
    countryCode: 590,
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    countryCode: 1671,
    name: 'Guam',
  },
  {
    code: 'GT',
    countryCode: 502,
    name: 'Guatemala',
  },
  {
    code: 'GG',
    countryCode: 44,
    name: 'Guernsey',
  },
  {
    code: 'GN',
    countryCode: 224,
    name: 'Guinea',
  },
  {
    code: 'GW',
    countryCode: 245,
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    countryCode: 595,
    name: 'Guyana',
  },
  {
    code: 'HT',
    countryCode: 509,
    name: 'Haiti',
  },
  {
    code: 'VA',
    countryCode: 379,
    name: 'Holy See (Vatican City State)',
  },
  {
    code: 'HN',
    countryCode: 504,
    name: 'Honduras',
  },
  {
    code: 'HK',
    countryCode: 852,
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    countryCode: 36,
    name: 'Hungary',
  },
  {
    code: 'IS',
    countryCode: 354,
    name: 'Iceland',
  },
  {
    code: 'IN',
    countryCode: 91,
    name: 'India',
  },
  {
    code: 'countryCode',
    countryCode: 62,
    name: 'Indonesia',
  },
  {
    code: 'IR',
    countryCode: 98,
    name: 'Iran, Islamic Republic of Persian Gulf',
  },
  {
    code: 'IQ',
    countryCode: 964,
    name: 'Iraq',
  },
  {
    code: 'IE',
    countryCode: 353,
    name: 'Ireland',
  },
  {
    code: 'IM',
    countryCode: 44,
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    countryCode: 972,
    name: 'Israel',
  },
  {
    code: 'IT',
    countryCode: 39,
    name: 'Italy',
  },
  {
    code: 'JM',
    countryCode: 1876,
    name: 'Jamaica',
  },
  {
    code: 'JP',
    countryCode: 81,
    name: 'Japan',
  },
  {
    code: 'JE',
    countryCode: 44,
    name: 'Jersey',
  },
  {
    code: 'JO',
    countryCode: 962,
    name: 'Jordan',
  },
  {
    code: 'KZ',
    countryCode: 77,
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    countryCode: 254,
    name: 'Kenya',
  },
  {
    code: 'KI',
    countryCode: 686,
    name: 'Kiribati',
  },
  {
    code: 'KP',
    countryCode: 850,
    name: "Korea, Democratic People's Republic of Korea",
  },
  {
    code: 'KR',
    countryCode: 82,
    name: 'Korea, Republic of South Korea',
  },
  {
    code: 'KW',
    countryCode: 965,
    name: 'Kuwait',
  },
  {
    code: 'KG',
    countryCode: 996,
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    countryCode: 856,
    name: 'Laos',
  },
  {
    code: 'LV',
    countryCode: 371,
    name: 'Latvia',
  },
  {
    code: 'LB',
    countryCode: 961,
    name: 'Lebanon',
  },
  {
    code: 'LS',
    countryCode: 266,
    name: 'Lesotho',
  },
  {
    code: 'LR',
    countryCode: 231,
    name: 'Liberia',
  },
  {
    code: 'LY',
    countryCode: 218,
    name: 'Libya',
  },
  {
    code: 'LI',
    countryCode: 423,
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    countryCode: 370,
    name: 'Lithuania',
  },
  {
    code: 'LU',
    countryCode: 352,
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    countryCode: 853,
    name: 'Macao',
  },
  {
    code: 'MK',
    countryCode: 389,
    name: 'Macedonia',
  },
  {
    code: 'MG',
    countryCode: 261,
    name: 'Madagascar',
  },
  {
    code: 'MW',
    countryCode: 265,
    name: 'Malawi',
  },
  {
    code: 'MY',
    countryCode: 60,
    name: 'Malaysia',
  },
  {
    code: 'MV',
    countryCode: 960,
    name: 'Maldives',
  },
  {
    code: 'ML',
    countryCode: 223,
    name: 'Mali',
  },
  {
    code: 'MT',
    countryCode: 356,
    name: 'Malta',
  },
  {
    code: 'MH',
    countryCode: 692,
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    countryCode: 596,
    name: 'Martinique',
  },
  {
    code: 'MR',
    countryCode: 222,
    name: 'Mauritania',
  },
  {
    code: 'MU',
    countryCode: 230,
    name: 'Mauritius',
  },
  {
    code: 'YT',
    countryCode: 262,
    name: 'Mayotte',
  },
  {
    code: 'MX',
    countryCode: 52,
    name: 'Mexico',
  },
  {
    code: 'FM',
    countryCode: 691,
    name: 'Micronesia, Federated States of Micronesia',
  },
  {
    code: 'MD',
    countryCode: 373,
    name: 'Moldova',
  },
  {
    code: 'MC',
    countryCode: 377,
    name: 'Monaco',
  },
  {
    code: 'MN',
    countryCode: 976,
    name: 'Mongolia',
  },
  {
    code: 'ME',
    countryCode: 382,
    name: 'Montenegro',
  },
  {
    code: 'MS',
    countryCode: 1664,
    name: 'Montserrat',
  },
  {
    code: 'MA',
    countryCode: 212,
    name: 'Morocco',
  },
  {
    code: 'MZ',
    countryCode: 258,
    name: 'Mozambique',
  },
  {
    code: 'MM',
    countryCode: 95,
    name: 'Myanmar',
  },
  {
    code: 'NA',
    countryCode: 264,
    name: 'Namibia',
  },
  {
    code: 'NR',
    countryCode: 674,
    name: 'Nauru',
  },
  {
    code: 'NP',
    countryCode: 977,
    name: 'Nepal',
  },
  {
    code: 'NL',
    countryCode: 31,
    name: 'Netherlands',
  },
  {
    code: 'AN',
    countryCode: 599,
    name: 'Netherlands Antilles',
  },
  {
    code: 'NC',
    countryCode: 687,
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    countryCode: 64,
    name: 'New Zealand',
  },
  {
    code: 'NI',
    countryCode: 505,
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    countryCode: 227,
    name: 'Niger',
  },
  {
    code: 'NG',
    countryCode: 234,
    name: 'Nigeria',
  },
  {
    code: 'NU',
    countryCode: 683,
    name: 'Niue',
  },
  {
    code: 'NF',
    countryCode: 672,
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    countryCode: 1670,
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    countryCode: 47,
    name: 'Norway',
  },
  {
    code: 'OM',
    countryCode: 968,
    name: 'Oman',
  },
  {
    code: 'PK',
    countryCode: 92,
    name: 'Pakistan',
  },
  {
    code: 'PW',
    countryCode: 680,
    name: 'Palau',
  },
  {
    code: 'PS',
    countryCode: 970,
    name: 'Palestinian Territory, Occupied',
  },
  {
    code: 'PA',
    countryCode: 507,
    name: 'Panama',
  },
  {
    code: 'PG',
    countryCode: 675,
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    countryCode: 595,
    name: 'Paraguay',
  },
  {
    code: 'PE',
    countryCode: 51,
    name: 'Peru',
  },
  {
    code: 'PH',
    countryCode: 63,
    name: 'Philippines',
  },
  {
    code: 'PN',
    countryCode: 872,
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    countryCode: 48,
    name: 'Poland',
  },
  {
    code: 'PT',
    countryCode: 351,
    name: 'Portugal',
  },
  {
    code: 'PR',
    countryCode: 1939,
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    countryCode: 974,
    name: 'Qatar',
  },
  {
    code: 'RO',
    countryCode: 40,
    name: 'Romania',
  },
  {
    code: 'RU',
    countryCode: 7,
    name: 'Russia',
  },
  {
    code: 'RW',
    countryCode: 250,
    name: 'Rwanda',
  },
  {
    code: 'RE',
    countryCode: 262,
    name: 'Reunion',
  },
  {
    code: 'BL',
    countryCode: 590,
    name: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    countryCode: 290,
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  {
    code: 'KN',
    countryCode: 1869,
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    countryCode: 1758,
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    countryCode: 590,
    name: 'Saint Martin',
  },
  {
    code: 'PM',
    countryCode: 508,
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    countryCode: 1784,
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    countryCode: 685,
    name: 'Samoa',
  },
  {
    code: 'SM',
    countryCode: 378,
    name: 'San Marino',
  },
  {
    code: 'ST',
    countryCode: 239,
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    countryCode: 966,
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    countryCode: 221,
    name: 'Senegal',
  },
  {
    code: 'RS',
    countryCode: 381,
    name: 'Serbia',
  },
  {
    code: 'SC',
    countryCode: 248,
    name: 'Seychelles',
  },
  {
    code: 'SL',
    countryCode: 232,
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    countryCode: 65,
    name: 'Singapore',
  },
  {
    code: 'SK',
    countryCode: 421,
    name: 'Slovakia',
  },
  {
    code: 'SI',
    countryCode: 386,
    name: 'Slovenia',
  },
  {
    code: 'SB',
    countryCode: 677,
    name: 'Solomon Islands',
  },
  {
    code: 'SO',
    countryCode: 252,
    name: 'Somalia',
  },
  {
    code: 'ZA',
    countryCode: 27,
    name: 'South Africa',
  },
  {
    code: 'SS',
    countryCode: 211,
    name: 'South Sudan',
  },
  {
    code: 'GS',
    countryCode: 500,
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'ES',
    countryCode: 34,
    name: 'Spain',
  },
  {
    code: 'LK',
    countryCode: 94,
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    countryCode: 249,
    name: 'Sudan',
  },
  {
    code: 'SR',
    countryCode: 597,
    name: 'Suriname',
  },
  {
    code: 'SJ',
    countryCode: 47,
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    countryCode: 268,
    name: 'Swaziland',
  },
  {
    code: 'SE',
    countryCode: 46,
    name: 'Sweden',
  },
  {
    code: 'CH',
    countryCode: 41,
    name: 'Switzerland',
  },
  {
    code: 'SY',
    countryCode: 963,
    name: 'Syrian Arab Republic',
  },
  {
    code: 'TW',
    countryCode: 886,
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    countryCode: 992,
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    countryCode: 255,
    name: 'Tanzania, United Republic of Tanzania',
  },
  {
    code: 'TH',
    countryCode: 66,
    name: 'Thailand',
  },
  {
    code: 'TL',
    countryCode: 670,
    name: 'Timor-Leste',
  },
  {
    code: 'TG',
    countryCode: 228,
    name: 'Togo',
  },
  {
    code: 'TK',
    countryCode: 690,
    name: 'Tokelau',
  },
  {
    code: 'TO',
    countryCode: 676,
    name: 'Tonga',
  },
  {
    code: 'TT',
    countryCode: 1868,
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    countryCode: 216,
    name: 'Tunisia',
  },
  {
    code: 'TR',
    countryCode: 90,
    name: 'Turkey',
  },
  {
    code: 'TM',
    countryCode: 993,
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    countryCode: 1649,
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    countryCode: 688,
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    countryCode: 256,
    name: 'Uganda',
  },
  {
    code: 'UA',
    countryCode: 380,
    name: 'Ukraine',
  },
  {
    code: 'AE',
    countryCode: 971,
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    countryCode: 44,
    name: 'United Kingdom',
  },
  {
    code: 'US',
    countryCode: 1,
    name: 'United States',
  },
  {
    code: 'UY',
    countryCode: 598,
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    countryCode: 998,
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    countryCode: 678,
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    countryCode: 58,
    name: 'Venezuela, Bolivarian Republic of Venezuela',
  },
  {
    code: 'VN',
    countryCode: 84,
    name: 'Vietnam',
  },
  {
    code: 'VG',
    countryCode: 1284,
    name: 'Virgin Islands, British',
  },
  {
    code: 'VI',
    countryCode: 1340,
    name: 'Virgin Islands, U.S.',
  },
  {
    code: 'WF',
    countryCode: 681,
    name: 'Wallis and Futuna',
  },
  {
    code: 'YE',
    countryCode: 967,
    name: 'Yemen',
  },
  {
    code: 'ZM',
    countryCode: 260,
    name: 'Zambia',
  },
  {
    code: 'ZW',
    countryCode: 263,
    name: 'Zimbabwe',
  },
]

export const getCountriesByCountryCode = (countryCode) =>
  rawCountryCodes.filter(
    (item) => item.countryCode.toString() === countryCode.toString()
  )
export const getCountryNamesByCountryCode = (countryCode) =>
  getCountriesByCountryCode(countryCode).map((country) => country.name)
