import { extend, keys } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Default event columns
 */
export const defaultEventColumns = [
  'application',
  'name',
  'status',
  'description',
  'created',
  'info',
]

export const defaultNotesColumns = {
  created: 1,
  note: 1,
  userFirstName: 1,
  userLastName: 1,
}

/**
 * Messages initial state
 */
export const messagesState = {
  apiKey: {
    busy: false,
    data: null,
    error: null,
  },
  applications: {
    busy: false,
    busyToggle: false,
    data: [],
    error: null,
  },
  eventColumns: [],
  numbers: [],
  template: {},
  templates: [],
  notesColumns: {
    created: 1,
    note: 1,
    userFirstName: 1,
    userLastName: 1,
  },
}

/**
 * Messages reducer
 */
export default handleActions(
  {
    APPLICATION_EVENTS_COLUMNS_TOGGLE: (state, { payload }) => {
      const eventColumns = extend({}, state.eventColumns, {
        [payload]: state.eventColumns[payload] ? 0 : 1,
      })
      const columnKeys = keys(eventColumns)
      const total = columnKeys
        .map((key) => {
          return eventColumns[key]
        })
        .reduce((sum, value) => {
          return sum + value
        }, 0)
      if (total === 0) {
        eventColumns[columnKeys[0]] = 1
      }
      return extend({}, state, {
        eventColumns,
      })
    },
    APPLICATION_NOTES_COLUMNS_TOGGLE: (state, { payload }) => {
      const notesColumns = extend({}, state.notesColumns, {
        [payload]: state.notesColumns[payload] ? 0 : 1,
      })
      const notesKeys = keys(notesColumns)
      const total = notesKeys
        .map((key) => {
          return notesColumns[key]
        })
        .reduce((sum, value) => {
          return sum + value
        }, 0)
      if (total === 0) {
        notesColumns[notesKeys[0]] = 1
      }
      return extend({}, state, {
        notesColumns,
      })
    },
    APPLICATION_KEY: (state) => {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          busy: true,
        },
      }
    },
    APPLICATION_KEY_FAIL: (state, { payload }) => {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          busy: false,
          error: payload,
        },
      }
    },
    APPLICATION_KEY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          busy: false,
          data: payload,
        },
      }
    },
    APPLICATION_KEY_RESET: (state) => {
      return {
        ...state,
        apiKey: {
          busy: false,
          data: null,
          error: null,
        },
      }
    },
    APPLICATION_NUMBERS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        numbers: payload,
      })
    },
    APPLICATION_TOGGLE: (state) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busyToggle: true,
        },
      }
    },
    APPLICATION_TOGGLE_COMPLETE: (state) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busyToggle: false,
        },
      }
    },
    MESSAGE_TEMPLATE_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        template: extend({}, state.template, payload || {}),
      })
    },
    MESSAGE_TEMPLATE_RESET: (state) => {
      return extend({}, state, {
        template: {},
      })
    },
    USER_MESSAGE_TEMPLATES_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        templates: payload.results,
      }
    },
    USER_APPLICATIONS: (state) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busy: true,
        },
      }
    },
    USER_APPLICATIONS_FAIL: (state, { payload }) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busy: false,
          error: payload,
        },
      }
    },
    USER_APPLICATIONS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busy: false,
          data: payload || [],
        },
      }
    },
    APPLICATION_DRAFTS: (state, { payload }) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busy: true,
        },
      }
    },
    APPLICATION_DRAFTS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          busy: false,
          data: payload || [],
        },
      }
    },
  },
  messagesState
)
