import { createActionSaga, createFormSaga, customer,user } from '../../api'
import { actionSuccess } from '../../common/action'

export const accountDelete = 'account-delete'
export const accountReload = 'account-reload'
export const accountSave = 'account-save'
export const accountSaveCompany = 'account-save-company'
export const accountSavePolicyGroup = 'account-save-policy-group'
export const companySaveAccount = 'company-save-account'
export const systemAccounts = 'system-accounts'
export const systemAccountCompanies = 'system-account-companies'
export const systemAccountUserCompaniesAssigned =
  'system-account-user-companies-assigned'
export const systemAccountUserCompaniesUnassigned =
  'system-account-user-companies-unassigned'
export const systemAccountUserCompaniesAssignedReload =
  'system-account-user-companies-assigned-reload'
export const systemAccountUserCompaniesUnassignedReload =
  'system-account-user-companies-unassigned-reload'
export const systemAccountsPolicyGroups = 'system-accounts-policy-groups'

const {
  deleteAccount,
  getAccount,
  getAccounts,
  getPolicyGroupAccounts,
  saveAccount,
  saveCompany,
  savePolicyGroup,
  getAssignedCompanies,
  getUnassignedCompanies,
} = user

const { getUsers, saveUser } = customer

const accounts = [
  createActionSaga(accountReload, getAccount, {
    busy: true,
  }),
  createActionSaga(systemAccounts, getAccounts),
  createActionSaga(systemAccountsPolicyGroups, getPolicyGroupAccounts),
  createActionSaga(systemAccountCompanies, getUsers),
  createActionSaga(systemAccountUserCompaniesAssigned, getAssignedCompanies),
  createActionSaga(
    systemAccountUserCompaniesAssignedReload,
    getAssignedCompanies,
    {
      busy: true,
    }
  ),
  createActionSaga(
    systemAccountUserCompaniesUnassigned,
    getUnassignedCompanies
  ),
  createActionSaga(
    systemAccountUserCompaniesUnassignedReload,
    getUnassignedCompanies,
    {
      busy: true,
    }
  ),
  createActionSaga(accountDelete, deleteAccount),
  createActionSaga(accountSaveCompany, saveCompany),
  createActionSaga(accountSavePolicyGroup, savePolicyGroup),
  createActionSaga(companySaveAccount, saveUser),
  createFormSaga(accountSave, saveAccount, {
    message: (payload, result) => {
      return result.new
        ? 'Profile added successfully'
        : 'Profile was updated successfully'
    },
    success: function* (result) {
      yield actionSuccess(accountReload, result)
    },
  }),
]

export default accounts
