import axios, { Method } from 'axios'

import { API } from 'app/api/API'
import { getRequestHeaders } from 'app/api/utils'

export class Requests {
  api: API
  baseURL: string

  constructor(api: API, baseUrl: string) {
    this.api = api

    this.baseURL = api.config.base + baseUrl
  }

  get headers() {
    return getRequestHeaders(this.api)
  }

  request = async (method: Method, url: string, data?: any) => {
    // We're using axios here and not the v1 api object because
    // the api object has v1 baseUrl setup.
    const response = await axios({
      method,
      baseURL: this.baseURL,
      headers: this.headers,
      url,
      data,
    })
    return response.data
  }

  get = (url: string) => this.request('GET', url)

  post = (url: string, data?: any) => this.request('POST', url, data)

  put = (url: string, data?: any) => this.request('PUT', url, data)

  delete = (url: string, data?: any) => this.request('DELETE', url, data)
}
