import { z } from 'zod'

import { CarouselCardWidth } from 'app/types/zod/common/Enums'
import { suggestions } from 'app/types/zod/rcs/content/suggestions'
import { rcsBaseCard } from '../rich-card'
import { RcsContent } from '..'

export const rcsCarouselCardContent = rcsBaseCard

export const rcsCarouselContent = z.object({
  width: CarouselCardWidth,
  rich_cards: z.array(rcsCarouselCardContent),
  suggestions: z.optional(suggestions),
})

export type RcsCarouselCardContent = z.infer<typeof rcsCarouselCardContent>

export type RcsCarouselContent = z.infer<typeof rcsCarouselContent>

export const isRcsCarouselCardContent = (
  node: RcsContent
): node is RcsCarouselCardContent => 'title' in node && !('orientation' in node)

export const isRcsCarouselContent = (
  node: RcsContent
): node is RcsCarouselContent => 'rich_cards' in node
