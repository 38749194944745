import { isUndefined } from 'lodash'

import { contact, createActionSaga, createFormSaga } from '../../api'
import { actionSuccess } from '../../common/action'

export const userContacts = 'user-contacts'
export const userListContacts = 'user-list-contacts'
export const userContactsReload = 'user-contacts-reload'
export const userContactNotes = 'user-contact-notes'
export const userContactNotesReload = 'user-contact-notes-reload'
export const contactDelete = 'contact-delete'
export const contactNoteDelete = 'contact-note-delete'
export const contactReload = 'contact-reload'
export const contactRemove = 'contact-remove'
export const contactResetFailedSms = 'contact-reset-failed-sms'
export const contactSave = 'contact-save'
export const contactDownload = 'contact-download'

export const userContactGroups = 'user-contact-groups'
export const userContactGroupsReload = 'user-contact-groups-reload'
export const contactGroupDelete = 'contact-group-delete'
export const contactGroupReload = 'contact-group-reload'
export const contactGroupSave = 'contact-group-save'
export const contactNotesDownload = 'contact-notes-download'
export const contactLoad = 'contact-load'

const {
  deleteContact,
  deleteGroup,
  deleteContactNote,
  downloadContacts,
  getContactNotes,
  getContacts,
  getListContacts,
  getContact,
  getGroups,
  getGroup,
  removeContact,
  resetFailedSms,
  saveContact,
  saveGroup,
  downloadContactNotes,
} = contact

const contacts = [
  // Contacts
  createActionSaga(userContacts, getContacts),
  createActionSaga(userContactsReload, getContacts, {
    busy: true,
  }),
  createActionSaga(userListContacts, getListContacts),

  createActionSaga(userContactNotes, getContactNotes),
  createActionSaga(userContactNotesReload, getContactNotes, {
    busy: true,
  }),
  createActionSaga(contactNotesDownload, downloadContactNotes),

  createActionSaga(contactDelete, deleteContact),

  createActionSaga(contactReload, getContact, {
    busy: true,
  }),
  createActionSaga(contactNoteDelete, deleteContactNote),
  createActionSaga(contactReload, getContact, {
    busy: true,
  }),
  createActionSaga(contactRemove, removeContact),
  createActionSaga(contactResetFailedSms, resetFailedSms, {
    busy: 'form',
  }),
  createFormSaga(contactSave, saveContact, {
    message: 'Contact saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/contacts/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(contactReload, result)
    },
  }),
  // Groups
  createActionSaga(userContactGroups, getGroups),
  createActionSaga(userContactGroupsReload, getGroups, {
    busy: true,
  }),
  createActionSaga(contactGroupDelete, deleteGroup),
  createActionSaga(contactGroupReload, getGroup, {
    busy: true,
  }),
  createFormSaga(contactGroupSave, saveGroup, {
    message: 'Contact group saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        if (!isUndefined(result.id)) {
          return '/groups/' + result.id
        } else {
          return '/groups'
        }
      }
    },
    success: function* (result) {
      yield actionSuccess(contactGroupReload, result)
    },
  }),
  createActionSaga(contactDownload, downloadContacts),
  createActionSaga(contactLoad, {
    busy: true,
  }),
]

export default contacts
