import {
  createActionSaga,
  createFormSaga,
  customer,
  policy,
  ssoconnection as ssoconnectionApi,
} from '../../api'

export const systemSsoConnections = 'system-ssoconnections'
export const ssoConnectionsAll = 'ssoconnections-all'
export const ssoConnectionDelete = 'ssoconnection-delete'
export const ssoConnectionReload = 'ssoconnection-reload'
export const ssoConnectionSave = 'ssoconnection-save'
export const ssoConnectionCustomers = 'ssoconnection-customers'
export const ssoConnectionPolicyGroups = 'ssoconnection-policygroups'
export const ssoConnectionGetKey = 'ssoconnection-getkey'

const {
  getAllSsoConnections,
  getSsoConnection,
  getSsoConnections,
  saveSsoConnection,
  deleteSsoConnection,
  getSsoKey,
} = ssoconnectionApi

const { getCustomers } = customer

const { getGroups } = policy

const ssoconnection = [
  // Sso connections
  createActionSaga(systemSsoConnections, getSsoConnections),
  createActionSaga(ssoConnectionReload, getSsoConnection, { busy: true }),
  createFormSaga(ssoConnectionSave, saveSsoConnection, {
    message: 'Sso connection saved',
  }),
  createActionSaga(ssoConnectionDelete, deleteSsoConnection),
  createActionSaga(ssoConnectionsAll, getAllSsoConnections),
  createActionSaga(ssoConnectionGetKey, getSsoKey, { busy: true }),
  createActionSaga(ssoConnectionCustomers, getCustomers, { busy: true }),
  createActionSaga(ssoConnectionPolicyGroups, getGroups, { busy: true }),
]

export default ssoconnection
