import { isString } from 'lodash'
import { take } from 'redux-saga/effects'

import { createFormSaga, user } from '../../api'
import { ACTION_COMPLETE, actionName, actionPut } from '../../common/action'
import { userInfo } from '../sagas'

export const userLogin = 'user-login'
export const userLoginTwoFactor = 'user-login-two-factor'

const { login: loginUser, loginTwoFactor } = user

const login = [
  createFormSaga(userLogin, loginUser, {
    redirect: (payload, result) => {
      if (isString(result)) {
        return '/'
      } else {
        return '/login/twofactor'
      }
    },
    success: function* (result) {
      if (isString(result)) {
        yield actionPut(userInfo)
        yield take(actionName(userInfo, ACTION_COMPLETE))
      } else if (result.auth) {
        yield result
      }
    },
  }),
  createFormSaga(userLoginTwoFactor, loginTwoFactor, {
    redirect: '/',
    success: function* () {
      yield actionPut(userInfo)
      yield take(actionName(userInfo, ACTION_COMPLETE))
    },
  }),
]

export default login
