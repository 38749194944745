import { createActionSaga, customer, tracking as trackingApi } from '../../api'

export const userMessageLogs = 'user-message-logs'
export const trackingCustomers = 'tracking-customers'
export const reportsGraphical = 'reports-graphical'
export const reportsNumerical = 'reports-numerical'

const { getMessageLogs, getGraphicalReports, getNumericalReports } = trackingApi
const { getAllCustomers } = customer

const tracking = [
  createActionSaga(userMessageLogs, getMessageLogs),
  createActionSaga(trackingCustomers, getAllCustomers),
  createActionSaga(reportsGraphical, getGraphicalReports),
  createActionSaga(reportsNumerical, getNumericalReports),
]

export default tracking
