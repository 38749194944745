import * as faker from 'faker'
import {
  extend,
  random,
  range,
  sample,
  sampleSize,
  snakeCase,
  upperFirst,
  values,
} from 'lodash'
import moment from 'moment'

import { bind } from '../common/utils'
import { getPageNumber } from './'

/**
 * Permissions
 */
export const permissionMasks = {
  read: 1,
  create: 2,
  update: 4,
  delete: 8,
}

/**
 * Get policy permissions
 */
export function getPolicyPermissions(policy) {
  return (
    (policy.view ? permissionMasks.read : 0) +
    (policy.add ? permissionMasks.create : 0) +
    (policy.change ? permissionMasks.update : 0) +
    (policy.delete ? permissionMasks.delete : 0)
  )
}

/**
 * Policy API
 */
export class Policy {
  constructor(api) {
    this.api = api
    bind(this, [
      'countGroupUsers',
      'deleteGroup',
      'getMockDomains',
      'getMockGroups',
      'getMockPolicies',
      'getGroup',
      'getGroups',
      'getAccountGroups',
      'getPolicies',
      'getPolicyGroupPolicies',
      'saveGroup',
      'savePermissions',
    ])
  }

  /**
   * Delete group
   */
  deleteGroup({ id }) {
    return this.api.delete('mgmt/policies/groups/' + id)
  }

  /**
   * Get mock domains
   */
  getMockDomains() {
    if (!this.domains) {
      this.domains = range(5).map((i) => {
        return {
          id: i + 1 + '',
          name: upperFirst(faker.lorem.word()),
        }
      })
    }
    return this.domains
  }

  /**
   * Get mock groups
   */
  getMockGroups() {
    if (!this.groups) {
      this.groups = range(20).map((i) => {
        const policies = {}
        sampleSize(this.getMockPolicies(), random(10, 50)).forEach((policy) => {
          policies[policy.id] = extend({}, policy)
        })
        return {
          id: i + 1 + '',
          created: faker.date.recent(90),
          name: upperFirst(faker.lorem.words(random(2, 5))),
          policies,
          users: 0,
        }
      })
    }
    return this.groups
  }

  /**
   * Get mock policies
   */
  getMockPolicies() {
    if (!this.policies) {
      this.policies = range(100).map((i) => {
        const domain = sample(this.getMockDomains()).name,
          title = faker.lorem.words(random(1, 5))
        return {
          id: i + 1 + '',
          domain,
          name: [domain]
            .concat(title.split(' '))
            .map((word) => {
              return upperFirst(word.toLowerCase())
            })
            .join('.'),
          key: snakeCase(title).toUpperCase(),
          permissions: sampleSize(
            values(permissionMasks).slice(1),
            random(1, 3)
          ).reduce((sum, value) => sum + value, permissionMasks.read),
        }
      })
    }
    return this.policies
  }

  /**
   * Get group
   */
  getGroup({ id }) {
    return this.api.get('mgmt/policies/groups/' + id).then((result) => {
      return {
        id: result.id + '',
        created: moment(result.created).toDate(),
        name: result.name,
        policies: [],
        users: result.users,
      }
    })
  }

  /**
   * Get groups
   */
  getGroups(options = {}) {
    return this.api
      .get(
        'mgmt/policies/groups?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          created: 'created',
          users: 'users',
          name: 'name',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              created: new Date(result.created),
              name: result.name,
              policies: [],
              users: result.users,
            }
          }),
        }
      })
  }

  getAccountGroups(options = {}) {
    const { user } = options.extra
    return this.api
      .get(
        `mgmt/users/${user}/policygroups?page=` + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          assignment: 'assignment',
          name: 'name',
          created: 'created',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              created: new Date(result.created),
              name: result.name,
              assignment: result.assignment,
            }
          }),
        }
      })
  }

  /**
   * Count group users
   */
  countGroupUsers(group) {
    return this.api.user.getMockAccounts().reduce((total, { policyGroups }) => {
      return total + (policyGroups.indexOf(group.id) >= 0 ? 1 : 0)
    }, 0)
  }

  /**
   * Get all
   */
  getPolicies(options = {}) {
    return this.api
      .get(
        'mgmt/policies?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          domain: 'domain',
          name: 'policyvalue',
          key: 'policykey',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              domain: result.domain,
              name: result.policyvalue,
              key: result.policykey,
              permissions: getPolicyPermissions(result),
              view: result.view,
              add: result.add,
              change: result.change,
              delete: result.delete,
            }
          }),
        }
      })
  }

  getPolicyGroupPolicies(options = {}) {
    const { id } = options.extra

    return this.api
      .get(
        `mgmt/policies/groups/${id}/policies?page=` + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          domain: 'domain',
          name: 'policyvalue',
          key: 'policykey',
          assigned: 'assignment',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              domain: result.domain,
              name: result.policyvalue,
              key: result.policykey,
              permissions: getPolicyPermissions(result),
              assignment: result.assignment,
              view: result.view,
              add: result.add,
              change: result.change,
              delete: result.delete,
            }
          }),
        }
      })
  }

  /**
   * Save group
   */
  async saveGroup(group) {
    if (group.id) {
      try {
        const result = await this.api.put('mgmt/policies/groups/' + group.id, {
          name: group.name,
        })
        return {
          ...result,
          id: result.id + '',
          created: moment(result.create).toDate(),
          policies: [],
        }
      } catch (error) {
        return Promise.reject(new Error('Failed to update policy group'))
      }
    } else {
      try {
        const result = await this.api.post('mgmt/policies/groups', {
          name: group.name,
        })
        return {
          ...result,
          id: result.id + '',
          created: moment(result.create).toDate(),
          policies: [],
          new: true,
        }
      } catch (error) {
        return Promise.reject(new Error('Failed to create policy group'))
      }
    }
  }

  /**
   * Change permissions to a policy in a group
   */
  savePermissions({ id, request, successCallback }) {
    return this.api
      .put(`mgmt/policies/groups/${id}/policies`, request)
      .then(() => successCallback())
  }
}
