import { Component } from 'react'
import Paging from 'react-bootstrap/Pagination'
import { isFunction, isUndefined, range } from 'lodash'

import './PopupPagination.scss'

export const DEFAULT_LIMIT = 10
export const DEFAULT_MAX = 5
export const DEFAULT_PAGE = 1

export default class PopupPagination extends Component {
  render() {
    return (
      <div className="popup-pagination">
        <Paging>
          {this.getFirst()}
          {this.getPrev()}
          {this.getItems()}
          {this.getNext()}
          {this.getLast()}
        </Paging>
      </div>
    )
  }

  getCount() {
    return this.props.count || 0
  }

  getFirst() {
    return (
      <Paging.First
        className="first"
        disabled={this.getPage() === 1}
        onClick={() => this.go(1)}
      />
    )
  }

  getItems() {
    const max = this.getMax(),
      current = this.getPage(),
      last = this.getLastPage(),
      half = Math.floor(max / 2),
      rem = max % 2
    let left = current - (rem ? half : half - 1),
      right = current + half
    if (left < 1) {
      right += 1 - left
      left = 1
    }
    if (right > last) {
      left -= right - last
      right = last
    }
    if (left < 1) {
      left = 1
    }
    return range(left, right + 1).map((page) => {
      const active = page === current
      return (
        <Paging.Item
          className={'page page-' + page}
          key={page}
          active={page === current}
          onClick={() => active || this.go(page)}
        >
          {page}
        </Paging.Item>
      )
    })
  }

  getLastPage() {
    return Math.ceil(this.getCount() / this.getLimit())
  }

  getLimit() {
    return this.props.limit || DEFAULT_LIMIT
  }

  getMax() {
    return this.props.max || DEFAULT_MAX
  }

  getLast() {
    return (
      <Paging.Last
        className="last"
        disabled={this.getPage() === this.getLastPage()}
        onClick={() => this.go(this.getLastPage())}
      />
    )
  }

  getNext() {
    const current = this.getPage(),
      last = this.getLastPage()
    if (current >= last) {
      return
    }
    return <Paging.Next className="next" onClick={() => this.go(current + 1)} />
  }

  getPage() {
    if (!isUndefined(this.props.page)) {
      return this.props.page
    }
    if (!isUndefined(this.props.start)) {
      return Math.floor(this.props.start / this.getLimit()) + 1
    }
    return DEFAULT_PAGE
  }

  getPrev() {
    const current = this.getPage()
    if (current <= 1) {
      return
    }
    return <Paging.Prev className="prev" onClick={() => this.go(current - 1)} />
  }

  go(page) {
    if (isFunction(this.props.onGoto)) {
      this.props.onGoto(page)
    }
  }
}
