import { z } from 'zod'

import { datetimeStringWithOffset, integer, nonEmptyString } from '../../utils'

export const systemApplication = z
  .object({
    id: integer,
    modulename: nonEmptyString,
    name: nonEmptyString,
    naviname: z.nullable(nonEmptyString),
    created: datetimeStringWithOffset,
    short_description: nonEmptyString,
    channels: nonEmptyString,
    icon: nonEmptyString,
    applicationevent_key: nonEmptyString,
  })
  .strict()
