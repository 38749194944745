import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { isEmpty } from 'lodash'

interface VatSelectProps extends FormControlProps {
  name: string
  defaultValue?: React.SelectHTMLAttributes<string>['defaultValue']
  prepend?: boolean
  errors?: string | string[]
  options: number[]
}

const VatSelect = ({
  errors,
  prepend = false,
  name,
  options,
  ...rest
}: VatSelectProps) => {
  const { t } = useTranslation()

  return (
    <>
      <InputGroup>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="qf">%</i>
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}

        <Form.Control
          data-cy="vat-select"
          name={name}
          as="select"
          isInvalid={!!errors && !isEmpty(errors)}
          {...rest}
        >
          <option value="">-- {t('Select VAT')} --</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option.toLocaleString()} %
            </option>
          ))}
        </Form.Control>
      </InputGroup>
      {errors && (
        <span className={clsx('text-danger', !prepend && 'ml-0')}>
          {t(errors)}
        </span>
      )}
    </>
  )
}

export default VatSelect
