import { datadogRum } from '@datadog/browser-rum'

import packageJson from '../package.json'

if (process.env.REACT_APP_DD_RUM_ENABLED === 'true') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: process.env.REACT_APP_DD_SERVICE,
    env: process.env.REACT_APP_DD_ENV,
    version: packageJson.version,
    sampleRate: 100,
    trackInteractions: false,
  })
}
