import {
  cleanup,
  configurations as configurationsApi,
  createActionSaga,
  systemApi,
} from '../api'
export const configurationsResources = 'configurations-resources'
export const configurationsResourcesReload = 'configurations-resources-reload'
export const cleanupObjects = 'cleanup-objects'
export const systemApis = 'system-apis'

const { getConfigurations } = configurationsApi
const { getCleanupObjects } = cleanup
const { getSystemApis } = systemApi

const configurations = [
  createActionSaga(configurationsResources, getConfigurations, {}),
  createActionSaga(configurationsResourcesReload, getConfigurations, {}),
  createActionSaga(cleanupObjects, getCleanupObjects, {}),
  createActionSaga(systemApis, getSystemApis, {}),
]

export default configurations
