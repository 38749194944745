import { extend, keys } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Default columns
 */
export const defaultColumns = [
  'created',
  'status',
  'sender',
  'destination',
  'message',
  'charset',
  'smses',
  'direction',
]

/**
 * Tracking initial state
 */
export const trackingState = {
  customers: {},
  logColumns: [],
  reports: {
    busy: false,
    error: null,
    graphical: [],
    numerical: [],
  },
}

/**
 * User contacts reducer
 */
export default handleActions(
  {
    MESSAGE_LOGS_COLUMNS_TOGGLE: (state, { payload }) => {
      const logColumns = extend({}, state.logColumns, {
        [payload]: state.logColumns[payload] ? 0 : 1,
      })
      const columnKeys = keys(logColumns)
      const total = columnKeys
        .map((key) => {
          return logColumns[key]
        })
        .reduce((sum, value) => {
          return sum + value
        }, 0)
      if (total === 0) {
        logColumns[columnKeys[0]] = 1
      }
      return extend({}, state, {
        logColumns,
      })
    },
    TRACKING_CUSTOMERS_RESET: (state) => {
      return extend({}, state, {
        customers: {},
      })
    },
    TRACKING_CUSTOMERS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        customers: payload || {},
      })
    },
    REPORTS_GRAPHICAL: (state) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: true,
        },
      }
    },
    REPORTS_GRAPHICAL_RESET: (state) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          error: null,
          graphical: [],
        },
      }
    },
    REPORTS_GRAPHICAL_FAIL: (state, { payload }) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          error: payload,
        },
      }
    },
    REPORTS_GRAPHICAL_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          graphical: payload || [],
        },
      }
    },
    REPORTS_NUMERICAL: (state) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: true,
        },
      }
    },
    REPORTS_NUMERICAL_RESET: (state) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          error: null,
          numerical: [],
        },
      }
    },
    REPORTS_NUMERICAL_FAIL: (state, { payload }) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          error: payload,
        },
      }
    },
    REPORTS_NUMERICAL_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          busy: false,
          numerical: payload || [],
        },
      }
    },
  },
  trackingState
)
