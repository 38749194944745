import { isEmpty } from 'lodash'

export const applicationViewSelector = ({
  auth,
  configurations,
  messages: { applications },
}) => ({
  auth,
  configurations,
  applications: applications?.data?.all || [],
})

export const serviceNameSelector = ({ configurations }) =>
  configurations.data.serviceName

export const applicationsSelector = ({ messages: { applications } }) =>
  applications?.data?.all

export const applicationDataSelector = ({
  messages: {
    applications: { busy, data },
  },
}) => ({ applications: data, busy })

export const configurationsSelector = ({ configurations }) => configurations

export const resourcesSelector = ({ router: { resources } }) => resources

export const routingSelector = ({ router: { routing } }) =>
  isEmpty(routing) ? null : routing

export const systemApisSelector = ({ configurations }) =>
  configurations.systemApis

export const templateSelector = ({ messages: { templates } }) => templates

export const userSelector = ({ auth: { user } }) => user

export const loggedInSelector = ({ auth: { loggedIn } }) => loggedIn

export const activeCustomerSelector = ({
  auth: {
    customer: { active },
  },
}) => active

export const systemCustomerSelector = ({
  system: {
    customers: { customer },
  },
}) => customer

export const systemApplicationsSelector = ({ system: { customers } }) =>
  customers.applications

export const customerIdSelector = ({
  auth: {
    customer: {
      active: { id },
    },
  },
}) => id

export const dataCleanupObjectsSelector = ({
  configurations: { dataCleanupObjects },
}) => dataCleanupObjects

export const dataCleanupObjectsStateSelector = ({
  configurations: { dataCleanupObjectsState },
}) => dataCleanupObjectsState

export const dataCleanupOptionsSelector = ({
  configurations: { dataCleanupOptions },
}) => dataCleanupOptions

export const productGroupsSelector = ({
  system: {
    billing: { groups },
  },
}) => groups

export const contactGroupsSelector = ({ contacts: { groups } }) => groups

export const tableSelector = ({ table }) => table

export const senderListSelector = ({ auth: { senderList } }) => senderList

export const customerExtIdSelector = (state) =>
  state.auth.customer.active?.extid
