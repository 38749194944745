import { AxiosRequestConfig } from 'axios'
import { last, sortBy } from 'lodash'
import { z } from 'zod'

import {
  rangeInputValueToString,
  stringToRangeInputValue,
} from 'app/common/range-input/RangeInput'
import { megaByte, toISOString } from 'app/common/utils'
import { TableRequestOptions } from 'app/types/app'
import { customerStorage } from 'app/types/zod/common/company'
import { Media, media } from 'app/types/zod/common/media'
import {
  storageApi,
  storageApiListItem,
  StorageFormValues,
} from 'app/types/zod/common/Storage'
import {
  nonEmptyString,
  parsedRequest,
  parsedTableRequest,
} from 'app/types/zod/utils'
import { API } from './API'
import { getPageNumber, getTableQueryHeaders } from '.'

export interface FileUploadOptions {
  customerId: number
  file: File
  additionalFields?: Record<string, string>
  onUploadProgress?: AxiosRequestConfig['onUploadProgress']
}

const serviceStorageUrl = 'service/storage'

const handleMediaList = (list: Media[], options: TableRequestOptions) => {
  let results = list.map((item) => ({
    ...item,
    LastModified: toISOString(item.LastModified),
  }))

  const search = options.filters?.search
  if (search) {
    results = results.filter((item: Media) => {
      const mediaTypeFilter =
        !search.mediaType || getMediaType(item) === search.mediaType

      const mimeTypeFilter =
        !search.mimeType ||
        search.mimeType.toString().split(',').includes(getMimeType(item))

      const maxSizeFilter =
        !(typeof search.maxSize === 'number') ||
        Number.isNaN(search.maxSize) ||
        item.Size <= search.maxSize

      const filenameFilter =
        !search.filename ||
        getFileName(item)
          ?.toLowerCase()
          .includes(search.filename.toString().toLowerCase())

      return (
        mediaTypeFilter && maxSizeFilter && mimeTypeFilter && filenameFilter
      )
    })
  }

  const sort = options.filters?.sorted?.split(':')
  if (sort) {
    results = sortBy(results, sort[0])
    if (sort[1] === 'desc') {
      results.reverse()
    }
  }

  return results
}

export class Storage {
  api: API

  constructor(api: API) {
    this.api = api
  }

  // Media
  upload = async (options: FileUploadOptions) =>
    this.api.uploadFile(serviceStorageUrl, options)

  list = parsedTableRequest(media, async (options: TableRequestOptions) => {
    if (!options.customerId) {
      throw new Error('Missing customerId')
    }

    const response = await this.api.get(
      `${serviceStorageUrl}?list_items`,
      {},
      true,
      {
        'X-customer': options.customerId,
      }
    )

    const results = handleMediaList(response.items, options)

    return {
      count: results.length,
      next: null,
      previous: null,
      results,
    }
  })

  listMedia = (type: 'list_items' | 'list_meta_items') =>
    parsedTableRequest(media, async (options: TableRequestOptions) => {
      const customerId = options.filters?.search.customerId

      if (!customerId) {
        throw new Error('Missing customerId')
      }

      const response = await this.api.get(
        `mgmt/companies/${customerId}/storage?${type}`
      )

      const results = handleMediaList(response.items, options)

      return {
        count: results.length,
        next: null,
        previous: null,
        results,
      }
    })

  listSystem = this.listMedia('list_items')

  listMeta = this.listMedia('list_meta_items')

  delete = async (customerId: number, keys: string[]) => {
    return this.api.delete(
      serviceStorageUrl,
      {},
      true,
      {
        'X-customer': customerId,
      },
      { files: keys }
    )
  }

  // Storages
  createStorage = async ({
    customerId,
    storage,
  }: {
    customerId: number
    storage: StorageFormValues
  }) => this.api.post(`mgmt/companies/${customerId}/storage`, storage)

  updateStorage = async ({
    customerId,
    storage,
  }: {
    customerId: number
    storage: StorageFormValues
  }) => this.api.put(`mgmt/companies/${customerId}/storage`, storage)

  deleteStorage = async (customerId: number) =>
    this.api.delete(`mgmt/companies/${customerId}/storage`)

  getStorages = parsedTableRequest(
    storageApiListItem,
    async (options: TableRequestOptions) => {
      const search = options.filters?.search
      if (search?.used_space) {
        search.used_space = mbFilterToBytes(search.used_space.toString())
      }
      if (search?.free_space) {
        search.free_space = mbFilterToBytes(search.free_space.toString())
      }
      const page = getPageNumber(options)
      const headers = getTableQueryHeaders(options)
      return await this.api.get(`mgmt/storages?page=${page}`, {}, true, headers)
    }
  )

  getCustomerStorageInfo = parsedRequest(
    customerStorage,
    async (customerId: number) =>
      this.api.get(serviceStorageUrl, {}, true, {
        'X-customer': customerId,
      })
  )

  getSystemStorageInfo = parsedRequest(storageApi, async (customerId: number) =>
    this.api.get(`mgmt/companies/${customerId}/storage`)
  )

  getStorageUrls = parsedRequest(z.array(nonEmptyString), async () => {
    const resources = await this.api.get(`mgmt/common-resources`)
    return resources.storage
  })
}

export const getMediaType = (media: Media) =>
  media.Key.split('/')[1] || 'unknown'
export const getFileType = (media: Media) =>
  media.Key.split('/')[2] || 'unknown'
export const getMimeType = (media: Media) =>
  media.Key.split('/').slice(1, 3).join('/')
export const getFileName = (media: Media) => last(media.Key.split('/'))

const mbFilterToBytes = (filter: string) => {
  const values = stringToRangeInputValue(filter)

  if (values.min) {
    const mbMin = Number.parseInt(values.min)
    if (mbMin) {
      values.min = (megaByte * mbMin).toString()
    }
  }
  if (values.max) {
    const mbMax = Number.parseInt(values.max)
    if (mbMax) {
      values.max = (megaByte * mbMax).toString()
    }
  }
  return rangeInputValueToString(values)
}
