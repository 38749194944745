import { z } from 'zod'

import { CompanyStatus, DemoStatus } from 'app/api/serializers/Company'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
} from '../../utils'

export const customerStorage = z
  .object({
    created: datetimeStringWithOffset,
    used_space: integer,
    size: integer,
  })
  .describe('customerStorage')

export const companyApi = z
  .object({
    id: integer,
    name: nonEmptyString,
    licensecustomer: z.boolean(),
    vatcode: nonEmptyString,
    extid: nullableString,
    status: z.nativeEnum(CompanyStatus),
    demo: z.nativeEnum(DemoStatus),
    filtering: z.boolean(),
    mtlimit: z.number().or(z.literal('')),
    sender: nonEmptyString,
    sendertype: nonEmptyString,
    country_code: integer,
    maskmsisdn: z.boolean(),
    maskmessage: z.boolean(),
    throttle: integer,
    validityperiod: integer,
    info: z.string(),
    created: datetimeStringWithOffset,
    delete_grace_period: z.nullable(datetimeStringWithOffset),
    your_reference: nullableString,
    our_reference: nullableString,
    external_info: nullableString,
    billing_interval: integer,
    payment_term: integer,
    customer_storage: z.nullable(customerStorage),
  })
  .strict()

export const customerTag = z.object({
  id: integer,
  constant: z.boolean(),
  customer: z.object({
    id: integer,
    created: datetimeStringWithOffset,
    name: nonEmptyString,
  }),
  tag_value: nonEmptyString,
  created: datetimeStringWithOffset,
})

export type CustomerTag = z.infer<typeof customerTag>

export type CustomerStorage = z.infer<typeof customerStorage>

export type CompanyApi = z.infer<typeof companyApi>
