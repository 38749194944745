import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System sso connection initial state
 */

export const emptyOidcSetting = {
  id: '',
  client_id: '',
  client_secret: '',
  scope: '',
  response_type: '',
  response_mode: '',
  configuration: '',
  sign_algo: '',
  sign_key: '',
  verify_tsl: '',
  timeout: '',
  proxy: '',
}

export const emptySsoconnection = {
  id: '',
  key: '',
  name: '',
  connection_type: '',
  connection_status: '',
  default_customer: '',
  default_policygroup: '',
  confirm_user: '',
  description: '',
  oidcsetting: emptyOidcSetting,
}

export const initialSsoconnectionErrors = emptySsoconnection
export const initialSsoconnection = {
  emptySsoconnection,
  ...{
    connection_type: 'OIDC',
    connection_status: 'ACTIVE',
    confirm_user: true,
    oidcsetting: {
      scope: 'openid profile',
      response_type: 'id_token token',
      response_mode: 'fragment',
      sign_algo: 'RS256',
      verify_tsl: true,
      timeout: 10,
    },
  },
}

export const ssoconnectionState = {
  ssoconnection: initialSsoconnection,
  customers: {},
  policygroups: {},
}

/**
 * System sso connection reducer
 */
export default handleActions(
  {
    SSOCONNECTION_RESET: (state) => {
      return extend({}, state, {
        ssoconnection: initialSsoconnection,
      })
    },
    SSOCONNECTION_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        ssoconnection: extend(
          {},
          state.ssoconnection,
          payload || initialSsoconnection
        ),
      })
    },
    SSOCONNECTION_GETKEY_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        ssoconnection: extend({}, state.ssoconnection, { key: payload }),
      })
    },
    SSOCONNECTION_CUSTOMERS_RESET: (state) => {
      return extend({}, state, {
        customers: {},
      })
    },
    SSOCONNECTION_CUSTOMERS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        customers: extend({}, state.customers, payload || {}),
      })
    },
    SSOCONNECTION_POLICYGROUPS_RESET: (state) => {
      return extend({}, state, {
        policygroups: {},
      })
    },
    SSOCONNECTION_POLICYGROUPS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        policygroups: extend({}, state.policygroups, payload || {}),
      })
    },
  },
  ssoconnectionState
)
