import { z } from 'zod'

import { integer, nonEmptyString } from '../../utils'

export const systemApi = z
  .object({
    id: integer,
    api_protocol: nonEmptyString,
    description: nonEmptyString,
    messaging_channel_name: nonEmptyString,
    messaging_channel_description: nonEmptyString,
  })
  .strict()
  .describe('systemApi')

export type SystemApi = z.infer<typeof systemApi>
