import { z } from 'zod'

import { integer, nonEmptyString, optionalString } from '../../utils'

export const media = z
  .object({
    ETag: nonEmptyString,
    Key: nonEmptyString,
    Owner: z.object({
      DisplayName: nonEmptyString,
      ID: nonEmptyString,
    }),
    Size: integer,
    StorageClass: nonEmptyString,
    LastModified: nonEmptyString,
    url: nonEmptyString,
    thumbnail: optionalString,
  })
  .strict()
  .describe('media')

export type Media = z.infer<typeof media>
