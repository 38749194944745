import { Component } from 'react'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import { forEach } from 'lodash'

import config from './config'

import en from './locale.en.json'
import fi from './locale.fi.json'

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('lang') || config.lang.default,
  fallbackLng: config.lang.fallback,
  keySeparator: false,
  nsSeparator: false,
  resources: {
    fi: {
      translation: fi,
    },
    en: {
      translation: en,
    },
  },
})

/**
 * Add resource
 */
export function add(locales = {}) {
  forEach(locales, (translations, lang) => {
    i18n.addResourceBundle(lang, 'translation', translations)
  })
}

/**
 * Change language
 */
export function change(lng) {
  return i18n.changeLanguage(lng, () => localStorage.setItem('lang', lng))
}

/**
 * Export t
 */
export function t(...args) {
  return i18n.t(...args)
}

/**
 * Wrap with HOC to pass lang
 */
export function wrap(Child, locales = {}) {
  add(locales)
  return class extends Component {
    constructor(...args) {
      super(...args)
      this.state = {
        lang: i18n.language,
      }
    }

    render() {
      const { lang } = this.state
      return (
        <Child change={change} i18n={i18n} lang={lang} t={t} {...this.props} />
      )
    }

    componentDidMount() {
      i18n.on(
        'languageChanged',
        (this.listener = (lang) => {
          if (lang !== this.state.lang) {
            this.setState({ lang })
          }
        })
      )
    }

    componentWillUnmount() {
      i18n.off('languageChanged', this.listener)
    }
  }
}

/**
 * Export default
 */
export default i18n
