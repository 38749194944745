import moment from 'moment'

import { throwErrorIfHasPendingJobs } from 'app/globalState'
import { downloadFileWithNameFromHeaders } from '../../common/utils'
import { getPageNumber } from '..'

const folderFilters = {
  reportDaterange: 'report_daterange',
  activeFolder: 'activefolder',
  sender: 'sender',
  body: 'body',
  created: 'created',
  download: 'download',
}

export class Inbox {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/smsinbox'
  }

  get({ id, customerId }) {
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return response
      })
  }

  post(request) {
    throwErrorIfHasPendingJobs()

    const { receive } = request

    let data = {
      applicationid: request.applicationId,
      name: request.name,
      destination: request.number,
      keyword: request.keyword,
      receive_enddatetime: moment(
        receive.date + ' ' + receive.time,
        'DD.MM.YYYY HH:mm'
      ).format('YYYY-MM-DD HH:mm:ss'),
    }

    if (receive.response) {
      data.responsebody = receive.message
    }

    if (receive.notification.active) {
      const {
        notification: { sms, email, activeSms, activeEmail },
      } = receive
      if (activeSms) {
        data.sms_action = {
          ...sms,
          smsfrom: sms.smsfrom === '' ? request.number : sms.smsfrom,
        }
      }
      if (activeEmail) {
        data.email_action = email
      }
    }

    return this.api
      .post('app/smsinbox', data, true, {
        'X-Customer': request.customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  update(request) {
    const { id, customerId, action, data } = request

    if (action !== 'ABORT') {
      throwErrorIfHasPendingJobs()
    }

    const values =
      action === 'MODIFY'
        ? {
            ...data,
            responsebody: data.hasResponse ? data.responsebody : null,
          }
        : {}
    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          action,
          ...values,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  getFolders = (request = {}) => {
    const {
      extra: { customerId, id, activeFolder },
    } = request
    const options = {
      ...request,
      search: {
        ...request.search,
        activeFolder,
        reportDaterange: request.search.created,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}/${id}/folders?page=${getPageNumber(request)}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, folderFilters),
        }
      )
      .then((result) => {
        const { activefolder, foldermessages, folders } = result

        return {
          count: foldermessages.messagecount,
          results: foldermessages.messages,
          activefolder: activefolder,
          additional: {
            folders: folders.map((folder) => {
              return {
                id: folder.id,
                applicationEventId: folder.applicationevent_id,
                name: folder.foldername,
                messageCount: folder.message_count,
                type: folder.type,
                filter: folder.filter,
                filterType: folder.filtertype,
                customerId: folder.customer_id,
                created: folder.created,
              }
            }),
          },
        }
      })
  }

  downloadFolders = async ({ id, customerId, options, message }) => {
    const {
      search: { download },
    } = options
    const result = await this.api.get(
      `${this.endpointUrl}/${id}/folders`,
      {},
      true,
      {
        'X-Customer': customerId,
        ...this.api.withFilters(options, folderFilters),
      },
      download === 'excel' ? true : false,
      true
    )

    downloadFileWithNameFromHeaders({ result, message })
  }

  postFolders(request) {
    const { id, customerId } = request
    return this.api
      .post(
        `${this.endpointUrl}/${id}/folders`,
        {
          foldername: request.name,
          filter: request.filter,
          filtertype: request.filterType,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        if (response) {
          return {
            success: true,
          }
        }
      })
  }

  moveFolder(request) {
    const { customerId, id } = request
    return this.api
      .put(
        `${this.endpointUrl}/${id}/folders`,
        {
          activefolder: request.activeFolder,
          action: 'move',
          source: request.activeFolder,
          target: request.target,
          messages: request.messages,
        },
        true,
        {
          'X-Customer': customerId,
          // 'id': applicationevent_id
        }
      )
      .then((response) => {
        if (response) {
          return {
            success: true,
          }
        }
      })
  }

  emptyTrash(request) {
    const { customerId, id } = request
    return this.api
      .put(
        `${this.endpointUrl}/${id}/folders`,
        {
          activefolder: request.activeFolder,
          action: 'remove',
          messages: request.messages,
        },
        true,
        {
          'X-Customer': customerId,
          // 'id': applicationevent_id
        }
      )
      .then((response) => {
        if (response) {
          return {
            success: true,
          }
        }
      })
  }

  updateFolder(request) {
    const { id, folderId, customerId } = request
    return this.api
      .put(
        `${this.endpointUrl}/${id}/folders/${folderId}`,
        {
          action: 'modify',
          foldername: request.name,
          filter: request.filter,
          filtertype: request.filterType,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        if (response) {
          return {
            success: true,
          }
        }
      })
  }

  deleteFolder(request) {
    const { id, folderId, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}/folders/${folderId}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        if (response) {
          return {
            success: true,
          }
        }
      })
  }
}
