import DatePickerWrapper from 'react-datepicker'
import { getMonth, getYear, isValid, parse } from 'date-fns'
import { range } from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'

import { wrap } from '../../i18n'

export default wrap(
  ({
    t,
    onChange,
    value,
    disabledDays,
    locale,
    isBirthday,
    disabled,
    startYear,
    initEmpty,
  }) => {
    const years = range(
      isBirthday ? 1930 : startYear || getYear(new Date()),
      getYear(new Date()) + 15,
      1
    )
    const parsedDate = parse(value, 'dd.MM.yyyy', new Date())
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return (
      <DatePickerWrapper
        className="form-control"
        dateFormat="dd.MM.yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 3,
              display: 'flex',
              justifyContent: 'space-evenly',
              width: 'max-content',
            }}
          >
            <div onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {
                <button
                  type="button"
                  className="date-arrow-button ml-2 mb-2 mt-2 btn  "
                >
                  <i className="qf qf-1x qf-chevron-left"></i>
                </button>
              }
            </div>
            <select
              className="ml-2 mb-2 mt-2 btn-sm form-control"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {t(option)}
                </option>
              ))}
            </select>
            <select
              className="ml-2 mb-2 mt-2 btn-sm form-control"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {t(option)}
                </option>
              ))}
            </select>
            <div onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {
                <button
                  type="button"
                  className="date-arrow-button ml-2 mb-2 mt-2 btn "
                >
                  <i className="qf qf-1x qf-chevron-right"></i>
                </button>
              }
            </div>
          </div>
        )}
        selected={
          value !== '' && isValid(parsedDate)
            ? parsedDate
            : isBirthday || initEmpty
            ? undefined
            : new Date()
        }
        onChange={onChange}
        minDate={disabledDays?.before || undefined}
        maxDate={disabledDays?.after || undefined}
        locale={locale}
        disabled={disabled}
      />
    )
  }
)
