/**
 * Config
 */

const environment =
  process.env.PUBLIC_URL ||
  process.env.REACT_APP_PUBLIC_URL ||
  'http://localhost:8000'

const config = {
  api: {
    base: environment + '/api/',
    //base: 'http://localhost:8000/api/',
    version: 'v1',
  },
  lang: {
    default: 'en',
    fallback: 'en',
  },
  log: {
    redux: false,
  },
}

export default config
