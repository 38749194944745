import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * App initial state
 */
export const appState = {
  busy: false,
  busyCount: 0,
  ready: false,
}

/**
 * App reducer
 */
export default handleActions(
  {
    APP_BUSY: (state, { payload }) => {
      const addend = !!payload ? 1 : -1,
        busyCount = state.busyCount + addend,
        busy = busyCount > 0
      return extend({}, state, {
        busy,
        busyCount,
      })
    },
    INITIALIZE_COMPLETE: (state) => {
      return extend({}, state, {
        ready: true,
      })
    },
  },
  appState
)
