import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System policies initial state
 */
export const policiesState = {
  group: {},
}

/**
 * Policies reducer
 */
export default handleActions(
  {
    POLICY_GROUP_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        group: extend({}, state.group, payload || {}),
      })
    },
    POLICY_GROUP_RESET: (state) => {
      return extend({}, state, {
        group: {},
      })
    },
  },
  policiesState
)
