import axios from 'axios'
import { extend, forEach, isEmpty, isUndefined, keys, omit } from 'lodash'
import Cookies from 'universal-cookie'

import { ApiKeys } from 'app/api/ApiKeys'
import { ExternalIntegrations } from 'app/api/ExternalIntegrations'
import { Integrations } from 'app/api/Integrations'
import { Unsubscription } from 'app/api/Unsubscription'
import { ApplicationDraft } from './applications/ApplicationDraft'
import { CallToAction } from './services/CallToAction'
import { Dialog } from './services/Dialog'
import { FeedbackAndInquiry } from './services/FeedbackAndInquiry'
import { FlowEventApi } from './services/FlowEventApi'
import { GroupMessage } from './services/GroupMessage'
import { GroupSubscription } from './services/GroupSubscription'
import { Inbox } from './services/Inbox'
import { MoDialog } from './services/MoDialog'
import { NpsSurvey } from './services/NpsSurvey'
import { QuickMessage } from './services/QuickMessage'
import { Billing } from './Billing'
import { Cleanup } from './Cleanup'
import { Configurations } from './Configurations'
import { Contact } from './Contact'
import { Customer } from './Customer'
import { Dashboard } from './Dashboard'
import { Log } from './Log'
import { Message } from './Message'
import { MoNumber } from './MoNumber'
import { NewsFeed } from './NewsFeed'
import { Policy } from './Policy'
import { Rcs } from './Rcs'
import { Router } from './Router'
import { SenderId } from './SenderId'
import { SettingsUser } from './SettingsUser'
import { SsoConnection } from './SsoConnection'
import { Storage } from './Storage'
import { SystemApi } from './SystemApi'
import { Tracking } from './Tracking'
import { User } from './User'

export const COOKIE_TOKEN = 'token'
export const REFRESH_TOKEN = 'refresh'
export const TOKEN_SEPARATOR = ':'
export const TOKEN_MAX_AGE = 60 * 60 * 24

const parsemsisdns = (numbers) => {
  if (Array.isArray(numbers)) {
    return numbers.map((number) => {
      return typeof number === 'string' ? number : number.phonenumber
    })
  }
  return []
}

export const setApplicationDefaultValues = (
  name,
  applicationId,
  applicationEventKey,
  end,
  begin,
  id,
  values
) => {
  //possible ApiKey

  const phonenumbers = parsemsisdns(values.activation_message?.msisdns)

  return {
    application: {
      name,
      allow_api_usage: values.allow_api_usage,
      systemapplication_id: applicationId,
      applicationevent_key: applicationEventKey,
      begin,
      end: end < begin ? undefined : end,
      id,
      application_info: {
        sendinfo: {
          message: values.activation_message?.body,
          sender: values.destination,
          recipients: {
            msisdns: phonenumbers,
            groups: values.activation_message?.groups,
          },
          send: begin,
        },
        receiveinfo: {
          ends: end,
          number: values.destination,
        },
      },
    },
  }
}

/**
 * The API
 */
export class API {
  constructor(config) {
    this.config = config
    this.cookies = new Cookies()
    this.token = ''
    this.refresh = ''
    this.dashboard = new Dashboard(this)
    this.ssoconnection = new SsoConnection(this)
    this.billing = new Billing(this)
    this.contact = new Contact(this)
    this.customer = new Customer(this)
    this.log = new Log(this)
    this.message = new Message(this)
    this.moNumber = new MoNumber(this)
    this.policy = new Policy(this)
    this.router = new Router(this)
    this.tracking = new Tracking(this)
    this.user = new User(this)
    this.settingsUser = new SettingsUser(this)
    this.configurations = new Configurations(this)
    this.systemApi = new SystemApi(this)
    this.newsfeed = new NewsFeed(this)
    this.integrations = new Integrations(this)
    this.apiKeys = new ApiKeys(this)
    this.cleanup = new Cleanup(this)
    this.storage = new Storage(this)
    this.senderId = new SenderId(this)
    this.unsubscription = new Unsubscription(this)
    this.externalIntegrations = new ExternalIntegrations(this)
    this.services = {
      inbox: new Inbox(this),
      dialog: new Dialog(this),
      callToAction: new CallToAction(this),
      feedbackAndInquiry: new FeedbackAndInquiry(this),
      groupMessage: new GroupMessage(this),
      quickMessage: new QuickMessage(this),
      moDialog: new MoDialog(this),
      applicationDraft: new ApplicationDraft(this),
      npsSurvey: new NpsSurvey(this),
      groupSubscription: new GroupSubscription(this),
      verify: new FlowEventApi(this, 'app/verify'),
      rcs: new Rcs(this),
    }
  }

  /**
   * API root
   */
  get root() {
    return this.config.base + this.config.version + '/'
  }

  /**
   * Delete
   */
  delete(
    endpoint,
    query = {},
    useAuth = true,
    headers = {},
    data,
    includeFullResponse = false
  ) {
    return this.fetch(
      'delete',
      endpoint + this.params(query),
      data || null,
      headers,
      useAuth,
      false,
      includeFullResponse
    )
  }

  destroyToken() {
    this.cookies.remove(COOKIE_TOKEN, { path: '/' })
    this.cookies.remove(REFRESH_TOKEN, { path: '/' })
    return this.setToken()
  }

  /**
   * Perform request
   */
  fetch(
    method,
    endpoint,
    data = {},
    headers = {},
    useAuth = true,
    isArrayBuffer = false,
    includeFullResponse = false,
    onUploadProgress
  ) {
    const args = [this.root + endpoint]
    if (['post', 'put'].indexOf(method) >= 0) {
      args.push(data)
    }
    const config = {}
    if (method === 'delete' && !isEmpty(data)) {
      config.data = data
    }
    if (isArrayBuffer) {
      config.responseType = 'arraybuffer'
    }
    if (isUndefined(config.headers)) {
      config.headers = extend({ 'content-type': 'application/json' }, headers)
    }
    if (useAuth && this.token) {
      config.headers['Authorization'] = 'Bearer ' + this.token
    }
    if (this.user.currentUser && this.user.currentUser.language) {
      config.headers['Accept-Language'] = this.user.currentUser.language
    }
    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress
    }

    args.push(config)

    if (includeFullResponse) {
      return axios[method](...args)
    }

    return axios[method](...args).then((response) => response.data)
  }

  /**
   * Get request
   */
  get(
    endpoint,
    query = {},
    useAuth = true,
    headers = {},
    isArrayBuffer = false,
    includeFullResponse = false
  ) {
    return this.fetch(
      'get',
      endpoint + this.params(query),
      null,
      headers,
      useAuth,
      isArrayBuffer,
      includeFullResponse
    )
  }

  /**
   * Check if API has token
   */
  hasToken() {
    return !!this.token
  }

  /**
   * Initialize
   */
  init() {
    return this.setToken(
      this.cookies.get(COOKIE_TOKEN),
      this.cookies.get(REFRESH_TOKEN)
    )
  }

  /**
   * Query params
   */
  params(query = {}, prependQuestionMark = true) {
    const params = keys(query)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      })
      .join('&')
    return params && prependQuestionMark ? '?' + params : params || ''
  }

  /**
   * Post request
   */
  post(endpoint, body = {}, useAuth = true, headers = {}, query = {}) {
    return this.fetch(
      'post',
      endpoint + this.params(query),
      body,
      headers,
      useAuth
    )
  }

  /**
   * Upload request with progress callback
   */
  upload(endpoint, data, headers = {}, onUploadProgress) {
    return this.fetch(
      'post',
      endpoint,
      data,
      headers,
      true,
      false,
      true,
      onUploadProgress
    )
  }

  uploadFile(url, options) {
    const formData = new FormData()

    formData.append('File', options.file)

    if (options.additionalFields) {
      for (const field of Object.entries(options.additionalFields)) {
        formData.append(...field)
      }
    }

    return this.upload(
      url,
      formData,
      {
        'X-Customer': options.customerId,
      },
      options.onUploadProgress
    )
  }

  /**
   * Put request
   */
  put(endpoint, body = {}, useAuth = true, headers = {}, query = {}) {
    return this.fetch(
      'put',
      endpoint + this.params(query),
      body,
      headers,
      useAuth
    )
  }

  /**
   * Set property
   */
  set(name, value) {
    this[name] = value
    return this
  }

  /**
   * Set token
   */
  setToken(token = '', refresh = '') {
    return this.set('token', token).set('refresh', refresh)
  }

  /**
   * Store persistent data
   */
  store() {
    this.cookies.set(COOKIE_TOKEN, this.token, {
      maxAge: TOKEN_MAX_AGE,
      path: '/',
    })
    this.cookies.set(REFRESH_TOKEN, this.refresh, {
      maxAge: TOKEN_MAX_AGE,
      path: '/',
    })
    return this
  }

  /**
   * With filters
   */
  withFilters(filters = {}, pairs = {}) {
    const options = {
      ...omit(filters, ['offset', 'limit', 'extra', 'order', 'search', 'sort']),
    }
    if (!isUndefined(filters.limit)) {
      options.page_size = parseInt(filters.limit, 10)
    }
    if (
      !isUndefined(filters.sort) &&
      filters.order &&
      !isUndefined(pairs[filters.sort])
    ) {
      if (
        pairs[filters.sort] === 'created_range' &&
        !isUndefined(pairs[filters.sort])
      ) {
        options.sorted = 'created:' + filters.order
      } else if (
        pairs[filters.sort] === 'statustime_range' &&
        !isUndefined(pairs[filters.sort])
      ) {
        options.sorted = 'statustime:' + filters.order
      } else {
        options.sorted = pairs[filters.sort] + ':' + filters.order
      }
    }
    if (!isEmpty(filters.search)) {
      forEach(pairs, (value, key) => {
        if (!isUndefined(filters.search[key])) {
          options[value] = filters.search[key]
        }
      })
    }
    return {
      'X-Filter': JSON.stringify(options),
    }
  }
}
