import { combineReducers } from 'redux'

import auth from '../auth/reducer'
import { formReducer as form } from '../common/form'
import table from '../common/table/reducer'
import tablePopup from '../common/table-popup/reducer'
import configurations from '../configurations/reducer'
import newsfeed from '../newsfeed/reducer'
import app from '../reducer'
import messages from '../service/applications/reducer'
import service from '../service/reducer'
import router from '../service/router/reducer'
import system from '../system/reducer'
import contacts from '../tools/contact/reducer'
import tracking from '../tools/tracking/reducer'

export default combineReducers({
  app,
  auth,
  contacts,
  form,
  messages,
  router,
  system,
  table,
  tablePopup,
  tracking,
  configurations,
  newsfeed,
  service,
})
