import { z } from 'zod'

import {
  apiEntityFields,
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from '../../utils'

export const matcher = z
  .object({
    priority: z.number(),
    matcher: nonEmptyString,
  })
  .passthrough()

export const matcherApi = matcher
  .extend({
    constant: z.boolean(),
  })
  .merge(apiEntityFields)
  .strict()

// v2

// These are updated versions of flow data schemas. We'll leave
// the previous schemas untouched so that there's no need to worry about
// Group Subscription, Verify and RCS
export const matcherResponse = z
  .object({
    constant: z.boolean(),
    matcher: nonEmptyString.or(z.array(nonEmptyString)),
    priority: integer,
  })
  .extend({
    id: integer,
    created: datetimeStringWithOffset,
  })
  .strict()
  .describe('matcherResponse')

export const matcherUi = matcherResponse
  .partial({
    id: true,
    created: true,
  })
  .strip()
  .describe('matcher')
