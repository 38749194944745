import { z } from 'zod'

import { FlowType, StatType } from 'app/types/flow'
import {
  flowEventPartialFields,
  flowEventResponse,
} from 'app/types/zod/flow/flow-event'
import {
  receiveSmsFlowFunction,
  receiveSmsFlowFunctionResponse,
  sendSmsFlowFunction,
  sendSmsFlowFunctionResponse,
  subscriptionFlowFunction,
  subscriptionFlowFunctionResponse,
} from 'app/types/zod/flow/flow-function/sms'
import { matcherResponse, matcherUi } from 'app/types/zod/flow/matcher'
import { integer } from 'app/types/zod/utils'

export const rootFlowEventResponse = flowEventResponse
  .extend({
    flow_type: z.literal(FlowType.ROOT),
    parent: z.null(),
    stat_type: z.literal(''),
  })
  .strict()
  .describe('rootFlowEventResponse')

export const rootFlowEvent = rootFlowEventResponse
  .partial(flowEventPartialFields)
  .strip()
  .describe('rootFlowEvent')

export const smsReceiveActivationFlowEventResponse = flowEventResponse
  .extend({
    flow_type: z.literal(FlowType.ACTIVATION),
    flow_functions: z.tuple([receiveSmsFlowFunctionResponse]),
    children: z.tuple([]),
    parent: integer,
  })
  .strict()
  .describe('smsReceiveActivationFlowEventResponse')

export const smsReceiveActivationFlowEvent =
  smsReceiveActivationFlowEventResponse
    .partial(flowEventPartialFields)
    .extend({
      flow_functions: z.tuple([receiveSmsFlowFunction]),
    })
    .strip()
    .describe('smsActivationMessageFlowEvent')

export const errorMessageFlowEventResponse = flowEventResponse
  .extend({
    flow_type: z.literal(FlowType.ERROR),
    stat_type: z.literal(StatType.ERROR),
    flow_functions: z.tuple([sendSmsFlowFunctionResponse]),
    flowevent_matchers: z.tuple([matcherResponse]),
    children: z.tuple([]),
  })
  .strict()
  .describe('errorMessageFlowEventResponse')

export const errorMessageFlowEvent = errorMessageFlowEventResponse
  .partial(flowEventPartialFields)
  .extend({
    flow_functions: z.tuple([sendSmsFlowFunction]),
    flowevent_matchers: z.tuple([matcherUi]),
  })
  .strip()
  .describe('errorMessageFlowEvent')

export const unsubscribeFlowEventResponse = flowEventResponse
  .extend({
    flow_type: z.literal(FlowType.FLOW_ITEM),
    stat_type: z.literal(StatType.UNSUBSCRIBED),
    flow_functions: z.tuple([subscriptionFlowFunctionResponse]),
    flowevent_matchers: z.tuple([matcherResponse]),
    children: z.tuple([]),
  })
  .strict()
  .describe('unsubscribeFlowEventResponse')

export const unsubscribeFlowEvent = unsubscribeFlowEventResponse
  .partial(flowEventPartialFields)
  .extend({
    flow_functions: z.union([
      z.tuple([subscriptionFlowFunction]),
      z.tuple([subscriptionFlowFunction, sendSmsFlowFunction]),
    ]),
    flowevent_matchers: z.tuple([matcherUi]),
  })
  .strip()
  .describe('unsubscribeFlowEvent')
