import { z } from 'zod'

import {
  defaultActionsSchema,
  senderIdApiItem,
  senderIdSelectorItem,
} from 'app/system/senderid/models/form-model'
import { TableRequestOptions } from 'app/types/app'
import { StatusEnumType } from 'app/types/zod/common/Enums'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  parsedTableRequest,
} from 'app/types/zod/utils'
import { parsedRequest } from 'app/types/zod/utils'
import { API } from './API'
import { getPageNumber, getTableQueryHeaders } from '.'

export type SenderIdPayload = {
  registered_sender_id: {
    id?: number
    sender_id: string
    status: StatusEnumType
    action: string
    whitelisted_sender_id: {
      id?: number
      registeredsenderid_id?: number
      customers: {
        customer_id: number
      }[]
    }
  }
}

export type SenderIdUsagePayload = {
  sender_id?: string
  action?: string
}

const senderIdUsageSchema = z.object({
  action_senderids: z.array(
    z.object({
      id: integer,
      sender_id: nonEmptyString,
      action: nonEmptyString,
      created: datetimeStringWithOffset,
      status: nonEmptyString,
    })
  ),
  sender_id_customers: z.array(
    z.object({
      id: integer,
      name: nonEmptyString,
      registered_sender_ids: z.array(
        z.object({
          id: integer,
          sender_id: nonEmptyString,
        })
      ),
    })
  ),
})

export type SenderIdUsage = z.infer<typeof senderIdUsageSchema>

export class SenderId {
  api: API
  constructor(api: API) {
    this.api = api
  }

  getSenderIdTableList = parsedTableRequest(
    senderIdApiItem,
    async (options: TableRequestOptions) => {
      const headers = getTableQueryHeaders(options)
      return this.api.get(
        `mgmt/senderid?page=${getPageNumber(options)}`,
        {},
        true,
        headers
      )
    }
  )

  getAllSenderIds = parsedTableRequest(senderIdApiItem, async () => {
    const filter = JSON.stringify({ page_size: 1000 })
    const result = await this.api.get('mgmt/senderid', {}, true, {
      'X-Filter': filter,
    })
    return result
  })

  // used as sender option in message applications
  getAvailableSenderIds = parsedRequest(
    z.array(senderIdSelectorItem),
    async (customerId: number) => {
      const result = await this.api.get('service/senderid', {}, true, {
        'X-Customer': JSON.stringify(customerId),
      })
      return result
    }
  )

  getSenderId = parsedRequest(senderIdApiItem, async (id: string) => {
    const result = await this.api.get(`mgmt/senderid/${id}`)
    return result
  })

  getDefaultActions = parsedRequest(defaultActionsSchema, async () => {
    const result = await this.api.get('mgmt/senderid/defaultaction')
    return result
  })

  saveSenderId = parsedRequest(
    senderIdApiItem,
    async (payload: SenderIdPayload) => {
      const { id } = payload.registered_sender_id
      if (!id) {
        const result = await this.api.post('mgmt/senderid', payload)
        return result
      } else {
        const result = await this.api.put(`mgmt/senderid/${id}`, payload)
        return result
      }
    }
  )

  async deleteSenderId(id: number) {
    await this.api.delete(`mgmt/senderid/${id}`)
  }

  getSenderIdUsage = parsedRequest(
    senderIdUsageSchema,
    async (payload: SenderIdUsagePayload) => {
      const filter = JSON.stringify(payload)
      const result = await this.api.get('mgmt/senderid/usage', {}, true, {
        'X-Filter': filter,
      })
      return result
    }
  )
}
