import React from 'react'
import Helmet from 'react-helmet'

const CustomTitle = ({ title }) => {
  var defaultTitle = 'Quriiri'
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  )
}

export { CustomTitle }
