import { nullable, z } from 'zod'

import {
  ErrorMessageItem,
  ReceiveActivationItem,
  RootItem,
  UnsubscribeItem,
} from 'app/tools/unsubscription/Unsubscription/flow/types/event-item'
import { FlowStatus } from 'app/types/flow'
import {
  applicationData,
  applicationDataResponse,
} from 'app/types/zod/flow/application-data'
import {
  errorMessageFlowEvent,
  errorMessageFlowEventResponse,
  rootFlowEvent,
  rootFlowEventResponse,
  smsReceiveActivationFlowEvent,
  smsReceiveActivationFlowEventResponse,
  unsubscribeFlowEvent,
  unsubscribeFlowEventResponse,
} from 'app/types/zod/flow/flow-event/sms'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
} from 'app/types/zod/utils'

export enum Step {
  MO_NUMBER,
  KEYWORDS,
  RESPONSE,
  ERROR,
}

export type UnsubscribeEvents = [
  RootItem,
  ReceiveActivationItem,
  UnsubscribeItem,
  ErrorMessageItem | null
]

export const moNumberFormValues = z
  .object({
    destination: nonEmptyString,
  })
  .describe('moNumberFormValues')

export const keywordsFormValues = z
  .object({
    keywords: z.array(nonEmptyString).min(1),
  })
  .describe('keyowordsFormValues')

export const responseFormValues = z
  .object({
    hasResponseMessage: z.boolean(),
    responseMessage: z.nullable(nonEmptyString),
  })
  .describe('responseFormValues')

export const errorFormValues = z
  .object({
    hasErrorMessage: z.boolean(),
    errorMessage: z.nullable(nonEmptyString),
  })
  .describe('errorFormValues')

export const unsubscriptionFormValues = moNumberFormValues
  .merge(keywordsFormValues)
  .merge(responseFormValues)
  .merge(errorFormValues)

export type ResponseFormValues = z.infer<typeof responseFormValues>
export type ErrorFormValues = z.infer<typeof errorFormValues>
export type MoNumberFormValues = z.infer<typeof moNumberFormValues>
export type UnsubscriptionFormValues = z.infer<typeof unsubscriptionFormValues>

export const stepValidation = [
  moNumberFormValues,
  keywordsFormValues,
  responseFormValues,
  errorFormValues,
]

export const unsubscribeFlowApplicationEvent = rootFlowEvent
  .extend({
    children: z.tuple([
      smsReceiveActivationFlowEvent.extend({
        children: z.union([
          z.tuple([unsubscribeFlowEvent]),
          z.tuple([unsubscribeFlowEvent, errorMessageFlowEvent]),
        ]),
      }),
    ]),
  })
  .describe('unsubscribeFlowEvent')

const unsubscribeEvent = z
  .object({
    application: applicationData,
    flow: unsubscribeFlowApplicationEvent,
  })
  .describe('unsubscribeEvent')

export type UnsubscribeEvent = z.infer<typeof unsubscribeEvent>

export const unsubscribeFlowApplicationEventResponse = rootFlowEventResponse
  .extend({
    children: z.tuple([
      smsReceiveActivationFlowEventResponse.extend({
        children: z.union([
          z.tuple([unsubscribeFlowEventResponse]),
          z.tuple([
            unsubscribeFlowEventResponse,
            errorMessageFlowEventResponse,
          ]),
        ]),
      }),
    ]),
  })
  .describe('unsubscribeFlowEvent')

export const unsubscribeEventResponse = z
  .object({
    application: applicationDataResponse,
    flow: unsubscribeFlowApplicationEventResponse,
  })
  .describe('unsubscribeEvent')

export type UnsubscribeEventResponse = z.infer<typeof unsubscribeEventResponse>

export const unsubscribeEventListItem = z
  .object({
    id: integer,
    systemapplication_id: integer,
    begin: datetimeStringWithOffset,
    end: nullable(datetimeStringWithOffset),
    allow_api_usage: z.boolean(),
    apikey: nullable(nonEmptyString),
    name: nonEmptyString,
    description: nullableString,
    created: datetimeStringWithOffset,
    status: z.nativeEnum(FlowStatus),
    applicationevent_key: z.string().uuid(),
    messages: integer,
    receiveinfo: z.object({
      leave_keywords: z.array(nonEmptyString),
      receive_number: nonEmptyString,
    }),
  })
  .describe('unsubscribeEventListItem')

export type UnsubscribeEventListItem = z.infer<typeof unsubscribeEventListItem>

export const unsubscribeEventList = z
  .array(unsubscribeEventListItem)
  .describe('unsubscribeEventList')
