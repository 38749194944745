import { z } from 'zod'

import { CarouselCardWidth } from '../../common/Enums'
import { editorId } from '../../utils'
import { rcsCarouselCardContent, rcsCarouselContent } from '../content/carousel'
import { rcsEditorRichCardMedia } from './media'
import { superRefineRichCard } from './rich-card'
import { editorSuggestions } from './suggestions'

export const rcsCarouselCardNode = rcsCarouselCardContent
  .extend({
    editorId,
    width: CarouselCardWidth,
    suggestions: editorSuggestions,
    media: z.optional(rcsEditorRichCardMedia),
  })
  .superRefine(superRefineRichCard)

export const rcsCarouselNode = rcsCarouselContent.extend({
  editorId,
  rich_cards: z.array(editorId),
  suggestions: editorSuggestions,
})

export type RcsCarouselCardNode = z.infer<typeof rcsCarouselCardNode>

export type RcsCarouselNode = z.infer<typeof rcsCarouselNode>
