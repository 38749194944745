import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

import './Loading.scss'

function Loading(props) {
  return (
    <span className={'Loading' + (props.absolute ? ' absolute' : '')}>
      <Spinner animation="border" role="status" />
    </span>
  )
}

export default Loading
