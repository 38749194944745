import { z } from 'zod'

import { apiEntityFields, integer, nonEmptyString } from '../../utils'

const group = z
  .object({
    name: nonEmptyString,
  })
  .passthrough()

export const groupApi = group
  .extend({
    status: z.enum(['ACTIVE', 'PASSIVE']),
    member_count: integer,
    recipient_count: integer,
    users: z.optional(integer),
  })
  .merge(apiEntityFields)
  .strict()
