import { z } from 'zod'

import { FlowType } from 'app/types/flow'
import {
  rcsApplicationData,
  rcsApplicationDataApi,
} from '../application-data/rcs'
import {
  receiveRcsFlowFunction,
  receiveRcsFlowFunctionApi,
  SendRcsFlowFunction,
  sendRcsFlowFunction,
  SendRcsFlowFunctionApi,
  sendRcsFlowFunctionApi,
} from '../flow-function/rcs'
import {
  flowEventApiBase,
  FlowEventBase,
  flowEventBase,
  FlowEventBaseApi,
} from '.'

const sendRcsFlowEvent: z.ZodType<SendRcsFlowEvent> = z.lazy(() =>
  flowEventBase.extend({
    children: z.array(sendRcsFlowEvent),
    flow_functions: z.tuple([sendRcsFlowFunction]),
  })
)

const receiveRcsFlowEvent = flowEventBase.extend({
  children: z.array(sendRcsFlowEvent),
  flow_functions: z.array(receiveRcsFlowFunction),
  flow_type: z.literal(FlowType.ACTIVATION),
})

const rcsActivationEvents = z
  .tuple([sendRcsFlowEvent])
  .or(z.tuple([sendRcsFlowEvent, receiveRcsFlowEvent]))

export const rcsRootEvent = flowEventBase.extend({
  flow_type: z.literal(FlowType.ROOT),
  children: rcsActivationEvents,
})

const rcsEvent = z.object({
  application: rcsApplicationData,
  flow: rcsRootEvent,
})

const sendRcsFlowEventApi: z.ZodType<SendRcsFlowEventApi> = z.lazy(() =>
  flowEventApiBase.extend({
    children: z.array(sendRcsFlowEventApi),
    flow_functions: z.tuple([sendRcsFlowFunctionApi]),
  })
)

const receiveRcsFlowEventApi = flowEventApiBase.extend({
  children: z.array(sendRcsFlowEventApi),
  flow_functions: z.array(receiveRcsFlowFunctionApi),
  flow_type: z.literal(FlowType.ACTIVATION),
})

const rcsActivationEventsApi = z
  .tuple([sendRcsFlowEventApi])
  .or(z.tuple([sendRcsFlowEventApi, receiveRcsFlowEventApi]))

const rcsRootEventApi = flowEventApiBase.extend({
  flow_type: z.literal(FlowType.ROOT),
  children: rcsActivationEventsApi,
})

export const rcsEventApi = z
  .object({
    application: rcsApplicationDataApi,
    flow: rcsRootEventApi,
  })
  .describe('rcsEventApi')

export interface SendRcsFlowEvent extends FlowEventBase {
  children: SendRcsFlowEvent[]
  flow_functions: SendRcsFlowFunction[]
}

interface SendRcsFlowEventApi extends FlowEventBaseApi {
  children: SendRcsFlowEventApi[]
  flow_functions: SendRcsFlowFunctionApi[]
}

export type ReceiveRcsFlowEvent = z.infer<typeof receiveRcsFlowEvent>

export type RcsRootEvent = z.infer<typeof rcsRootEvent>

export type RcsActivationEvents = z.infer<typeof rcsActivationEvents>

export type RcsEvent = z.infer<typeof rcsEvent>

export type ReceiveRcsFlowEventApi = z.infer<typeof receiveRcsFlowEventApi>

export type RcsFlowEventApi = SendRcsFlowEventApi | ReceiveRcsFlowEventApi

export type RcsEventApi = z.infer<typeof rcsEventApi>
