import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System policies initial state
 */
export const newsfeed = {
  news: {},
}

/**
 * Policies reducer
 */
export default handleActions(
  {
    NEWS_FEED_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        news: extend({}, state.news, payload || {}),
      })
    },
    NEWS_FEED_RESET: (state) => {
      return extend({}, state, {
        news: {},
      })
    },
  },
  newsfeed
)
