import { createActionSaga, createFormSaga, router } from '../../api'
import { actionSuccess } from '../../common/action'

export const systemRoutingRules = 'system-routing-rules'
export const routingRuleDelete = 'routing-rule-delete'
export const routingRuleReload = 'routing-rule-reload'
export const routingRuleSave = 'routing-rule-save'

const { deleteRoutingRule, getRoutingRules, getRoutingRule, saveRoutingRule } =
  router

const routing = [
  createActionSaga(systemRoutingRules, getRoutingRules),
  createActionSaga(routingRuleDelete, deleteRoutingRule),
  createActionSaga(routingRuleReload, getRoutingRule, { busy: true }),
  createFormSaga(routingRuleSave, saveRoutingRule, {
    message: 'Routing rule saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/system/routing/rules/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(routingRuleReload, result)
    },
  }),
]

export default routing
