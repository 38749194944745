import {
  createActionSaga,
  createFormSaga,
  newsfeed as newsfeedApi,
} from '../../api'
import { actionSuccess } from '../../common/action'

export const systemNewsFeed = 'system-news-feed'
export const articleDelete = 'article-delete'
export const articleSave = 'article-save'
export const articlesReload = 'article-reload'

const { getNewsFeeds, deleteArticle, saveArticle, getArticle } = newsfeedApi

const newsfeed = [
  createActionSaga(systemNewsFeed, getNewsFeeds),
  createActionSaga(articleDelete, deleteArticle),
  createActionSaga(articlesReload, getArticle, { busy: true }),
  createFormSaga(articleSave, saveArticle, {
    message: 'Article saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/system/newsfeed/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(articlesReload, result)
    },
  }),
]

export default newsfeed
