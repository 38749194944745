import { setApplicationDefaultValues } from 'app/api/API'
import { hasActivationMessage } from 'app/common/editor/utils'
import { downloadFileWithNameFromHeaders } from 'app/common/utils'
import { throwErrorIfHasPendingJobs } from 'app/globalState'
import {
  getBeginTime,
  getEndTime,
} from 'app/service/applications/nps-survey/NpsUtils'
import {
  addFlowChildren,
  addSendEmailFunction,
  addSmsNotificationFunction,
  addSurveyResponseFunction,
  detail,
  flowMatcher,
  flowPriority,
  flowType,
  fromApi,
  getActivationMessageFlow,
  getRemindMessageFlow,
  scheduleType,
  statType,
  toApi,
} from '../FlowAdapter'

export const parseNpsSurvey = (values, activationBody) => {
  const surveyFlows = [
    statType.DETRACTORS,
    statType.PASSIVES,
    statType.PROMOTERS,
  ]
  const sender = values.destination

  const getFlowEvents = (activationBody, sender) => {
    const begin = getBeginTime(values)
    if (hasActivationMessage(values)) {
      // Activation message
      if (activationBody) {
        values.activation_message.body = activationBody
      }
      return {
        flow_type: flowType.ACTIVATION,
        id: values.activation_message?.id,
        flowevent_schedule: {
          id: values.activation_message?.flowEventScheduleId,
          scheduled_datetime: begin,
          schedule_type: scheduleType.SCHEDULED,
        },
        children: getRemindMessageFlow(values, sender, begin),
        flow_functions: getActivationMessageFlow(
          values.activation_message.activationFunction?.id,
          values.activation_message,
          sender
        ),
      }
    }
    return null
  }

  let survey =
    //activation receiving
    {
      id: values.receiveMessageId,

      ...addFlowChildren(
        values.receiveMessageFlowFunctionId,
        values,
        flowType.ACTIVATION,
        '',
        false,
        false
      ),
      flow_type: flowType.ACTIVATION,
    }
  survey.children = values.survey.map((data, i) => {
    const surveyItem = {
      ...setNewLevel(data, flowType.INPUT, surveyFlows[i], false, sender),
      flow_type: flowType.FLOW_ITEM,
    }

    // Use flowevent_matchers from the original flow event
    // when updating.
    if (data.flowevent_matchers) {
      surveyItem.flowevent_matchers = data.flowevent_matchers
    }

    // Checks children inside each survey
    if (data.children.length > 0 && data.children[0].isActive) {
      surveyItem.children = data.children.map((childrenData) => {
        const surveyChild = {
          ...setNewLevel(
            childrenData,
            flowType.FLOW_ITEM,
            statType.UNKNOWN,
            true,
            sender
          ),
          flow_type: flowType.FLOW_ITEM,
        }

        // Use flowevent_matchers from the original flow event
        // when updating.
        if (childrenData.flowevent_matchers) {
          surveyChild.flowevent_matchers = childrenData.flowevent_matchers
        }

        return surveyChild
      })
    }

    return surveyItem
  })

  if (values.is_error_message) {
    //error message
    survey.children.push({
      id: values.errorFlowEvent?.id,
      ...addFlowChildren(
        values.errorFlowEvent?.flowFunctionId,
        values,
        flowType.ERROR,
        statType.ERROR,
        false,
        false
      ),
      stat_type: flowType.ERROR,
      flow_type: flowType.FLOW_ITEM,
      flowevent_matchers: values.errorFlowEvent?.flowEventMatchers || [
        {
          priority: flowPriority.LOW,
          matcher: flowMatcher.MATCH_DEFAULT,
        },
      ],
    })
  }

  const flowEvents = getFlowEvents(activationBody, sender)
  const payload = flowEvents
    ? [{ ...survey }, { ...flowEvents }]
    : [{ ...survey }]

  return toApi(payload, values.flowId)
}

export const setNewLevel = (values, flowType, level, isChild, sender) => {
  let flowFunctions = null

  let addSending = undefined

  if (values.response) {
    // return msg
    addSending = {
      id: values.flowEventId,
      sendsmsfunction: addSurveyResponseFunction(values, sender),
    }
  }

  flowFunctions = {
    ...addFlowChildren(undefined, values, flowType, level, isChild, false),
    flow_functions: addSending ? [{ ...addSending }] : [],
    flow_type: flowType.FLOW_FUNCTION,
  }
  if (values.sms_action?.smsfrom) {
    // sms notification
    const { parentFunctionId } = values.sms_action
    const flowFunctionFromSmsAction = {
      id: parentFunctionId,
      sendsmsfunction: addSmsNotificationFunction(values.sms_action),
      flow_type: flowType.FLOW_FUNCTION,
      detail: detail.SMS_NOTIFICATION,
    }
    flowFunctions.flow_functions.push(flowFunctionFromSmsAction)
  }
  if (values.email_action?.email_from) {
    //email notification
    const { parentFunctionId } = values.email_action
    const addEmail = addSendEmailFunction(values.email_action, sender)
    const flowFunctionFromEmailAction = {
      id: parentFunctionId,
      ...addEmail,
      flow_type: flowType.FLOW_FUNCTION,
      detail: detail.EMAIL_NOTIFICATION,
    }
    flowFunctions.flow_functions.push(flowFunctionFromEmailAction)
  }
  const { id } = values
  return {
    id,
    ...flowFunctions,
    flow_type: flowType.FLOW_FUNCTION,
  }
}

export class NpsSurvey {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/npssurvey'
    this.abortUrl = 'app/abort'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => fromApi(response))
  }

  post(request) {
    throwErrorIfHasPendingJobs()

    const { customerId, application, flow } = request
    const payload = { application, flow }
    return this.api
      .post(`${this.endpointUrl}`, payload, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  update(request) {
    throwErrorIfHasPendingJobs()

    const { id, customerId, data, flow } = request

    const applicationValues = setApplicationDefaultValues(
      data.name,
      data.systemapplication_id,
      data.applicationevent_key,
      getEndTime(data) || null,
      data.is_scheduled ? getBeginTime(data) : null,
      parseInt(id),
      data
    )
    const parsed = parseNpsSurvey(data)
    const surveyChildren = parsed.flow.children[0].children
    const flowChildren = flow.flow.children[0].children

    if (surveyChildren.length < flowChildren.length) {
      flow.flow.children[0].children.pop()
    }
    const combined = { ...parsed, ...applicationValues }

    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          ...combined,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  abort(request) {
    const { id, customerId } = request

    return this.api
      .get(`${this.abortUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return true
      })
  }
  delete(request) {
    const { id, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  // The UI has no feature to download NPS Survey reports.
  // This function is not in use.
  getReports = (request = {}) => {
    const {
      extra: {
        customerId,
        id,
        columns,
        download,
        filterInput: { reportDaterange, number, onLoad },
      },
    } = request

    const options = {
      ...request,
      search: {
        ...request.search,
        showcolumns: columns || '',
        reportDaterange: !onLoad
          ? `${reportDaterange.from} - ${reportDaterange.to}`
          : {},
        number,
        download,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}report/${id}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            reportDaterange: 'report_daterange',
            number: 'msisdn',
            contact__msisdn: 'contact__msisdn',
            itemmessage: 'itemmessage',
            response: 'response',
            created: 'created',
            showcolumns: 'showcolumns',
            download: 'download',
          }),
        },
        download === 'excel' ? 'true' : false,
        true
      )
      .then((result) => {
        const {
          responses,
          count,
          report_daterange,
          showcolumns,
          guicolumns,
          rawreportcolumns,
        } = result.data

        if (download !== '') {
          downloadFileWithNameFromHeaders({ result })
          return this.getReports({
            ...request,
            extra: { ...request.extra, download: '' },
          })
        }

        return {
          count,
          results: responses,
          additional: {
            rawreportcolumns,
            report_daterange,
            showcolumns,
            guicolumns,
          },
        }
      })
  }

  getStatistics(request) {
    const { id, customerId, dateRange, dateResolution, comparedRange } = request
    return this.api.get(`${this.endpointUrl}statistics/${id}`, {}, true, {
      'X-Customer': customerId,
      'X-filter': JSON.stringify({
        statistics_daterange: dateRange,
        statistics_dateresolution: dateResolution,
        compared_daterange: comparedRange,
      }),
    })
  }
}
