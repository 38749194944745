import { Form } from 'react-bootstrap'
import { last, trim } from 'lodash'

export type RangeInputValue = { min?: string | number; max?: string | number }

export const stringToRangeInputValue = (value?: string) => {
  if (!value) {
    return {}
  }
  const values = value.split('-').map(trim)

  return {
    min: values[0],
    max: values[1],
  }
}

export const rangeInputValueToString = (value: RangeInputValue) =>
  `${value.min || ''} - ${value.max || ''}`

interface RangeInputProps {
  name: string
  disabled?: boolean
  value?: RangeInputValue
  onChange: (value: RangeInputValue) => void
  step?: number
}

const RangeInput = ({
  name,
  disabled = false,
  value = {},
  onChange,
  step = 1,
}: RangeInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e
    const inputName = last(target.name.split('-')) as string
    const update = {
      ...value,
      [inputName]: target.value.trim(),
    }
    onChange(update)
  }

  return (
    <div className="d-flex align-items-center">
      <span className="flex-grow-1">
        <Form.Control
          disabled={disabled}
          name={`${name}-min`}
          type="number"
          value={value.min || ''}
          min={0}
          onChange={handleChange}
          step={step}
        />
      </span>
      <span className="pl-1 pr-1 flex-grow-0">&mdash;</span>
      <span className="flex-grow-1">
        <Form.Control
          disabled={disabled}
          name={`${name}-max`}
          type="number"
          value={value.max || ''}
          min={0}
          onChange={handleChange}
          step={step}
        />
      </span>
    </div>
  )
}

export default RangeInput
