import { t } from 'app/i18n'

export const integrationStatuses = {
  INITIALIZING: t('Initializing'),
  CREATED: t('Created'),
  PENDING: t('Pending'),
  PROCESSING: t('Processing'),
  FAILED: t('Failed'),
  RETRY: t('Retry'),
  ABORTED: t('Aborted'),
  TIMEOUT: t('Timeout'),
  FINISHED: t('Finished'),
}
