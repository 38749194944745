import 'moment/locale/fi'
import { Component } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { registerLocale } from 'react-datepicker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment'
import clsx from 'clsx'
import fi from 'date-fns/locale/fi'
import { isEqual, isFunction, isObject, isUndefined } from 'lodash'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-day-picker/lib/style.css'

import { wrap } from '../../i18n'
import { bind } from '../utils'
import CustomDatePicker from './CustomDatePicker'

import './DatePicker.scss'

/**
 * DatePicker
 */

class DatePickerWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
    bind(this, ['onChange'])
  }

  render() {
    const range = this.isRange()
    return (
      <div className={clsx('DatePicker', { range })}>
        {range ? this.getRange() : this.getSingle()}
      </div>
    )
  }

  componentDidMount() {
    registerLocale('fi', fi)
    if (!isUndefined(this.props.value)) {
      this.setState({
        value: this.props.value,
      })
    }
  }

  componentDidUpdate(props) {
    if (!isEqual(this.props.value, props.value)) {
      this.setState({
        value: this.props.value,
      })
    }
  }

  /**
   * Day picker props
   */
  getDayPickerProps(extend = {}) {
    return {
      locale: this.getLocale(),
      localeUtils: MomentLocaleUtils,
      ...extend,
    }
  }

  /**
   * Get locale
   */
  getLocale() {
    return this.props.lang || 'fi'
  }

  /**
   * Format
   */
  getFormat() {
    return this.props.format || 'DD.MM.YYYY'
  }

  /**
   * Get range
   */
  getRange() {
    const format = this.getFormat(),
      dateToday = formatDate(moment(), this.getFormat(), this.getLocale()),
      { t } = this.props,
      { from, to } = this.state.value,
      range = {
        [t('This month')]: [moment().startOf('month'), moment().endOf('month')],
        [t('Last month')]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        [t('Last 2 months')]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().endOf('month'),
        ],
        [t('Last 12 months')]: [
          moment().subtract(11, 'month').startOf('month'),
          moment().endOf('month'),
        ],
        [t('This year')]: [moment().startOf('year'), moment().endOf('month')],
        [t('Last 3 years')]: [
          moment().subtract(2, 'year').startOf('year'),
          moment(),
        ],
        [t('Last 7 days')]: [moment().subtract(6, 'days'), moment()],
        [t('Yesterday')]: [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days'),
        ],
        // [t('Last 30 days')]: [moment().subtract(29, 'days'), moment()],
      },
      dateRangeConfig = {
        format,
        separator: ' - ',
        applyLabel: t('Ok'),
        cancelLabel: t('Cancel'),
        customRangeLabel: t('Custom Range'),
        firstDay: 1,
        daysOfWeek: [
          t('Mo'),
          t('Tu'),
          t('We'),
          t('Th'),
          t('Fr'),
          t('Sa'),
          t('Su'),
        ],
        monthNames: [
          t('January'),
          t('February'),
          t('March'),
          t('April'),
          t('May'),
          t('June'),
          t('July'),
          t('August'),
          t('September'),
          t('October'),
          t('November'),
          t('December'),
        ],
      }

    return (
      <span className="form-control">
        <DateRangePicker
          ranges={range}
          alwaysShowCalendars={true}
          showDropdowns={true}
          locale={dateRangeConfig}
          startDate={from || dateToday}
          endDate={to || dateToday}
          onEvent={this.handleDateRange}
        >
          <span>
            {from || dateToday} - {to || dateToday}
          </span>
        </DateRangePicker>
      </span>
    )
  }

  /**
   * Get single
   */
  getOldSingle() {
    const format = this.getFormat()
    return (
      <DayPickerInput
        dayPickerProps={{
          ...this.getDayPickerProps(),
          disabledDays: this.props.disabledDays,
        }}
        inputProps={{
          className: 'form-control',
          disabled: this.props.disabled,
        }}
        format={format}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={format}
        onDayChange={this.onChange}
        value={this.state.value}
      />
    )
  }

  getSingle() {
    const url = window.location.href
    return (
      <CustomDatePicker
        value={this.state.value}
        onChange={this.onChange}
        t={this.props.t}
        locale={this.getLocale()}
        disabledDays={this.props.disabledDays}
        disabled={this.props.disabled}
        initEmpty={this.props.initEmpty}
        isBirthday={url.match(/contact/gi)}
        startYear={this.props.startYear}
      />
    )
  }

  /**
   * Value is range
   */
  isRange() {
    return (
      isObject(this.props.value) &&
      !isUndefined(this.props.value.from) &&
      !isUndefined(this.props.value.to)
    )
  }

  /**
   * On change
   */
  handleDateRange = (event, picker) => {
    const { value } = this.state,
      target = {
        name: this.props.name,
        type: 'datepicker',
      }
    const range = {
      ...value,
      from: formatDate(picker.startDate._d, this.getFormat(), this.getLocale()),
      to: formatDate(picker.endDate._d, this.getFormat(), this.getLocale()),
    }
    this.setState({
      value: range,
    })
    this.props.onChange({
      target: {
        ...target,
        value: range,
      },
    })
  }

  onChange(date, { selected }, dayPicker) {
    if (!isFunction(this.props.onChange)) {
      return
    }
    const { value } = this.state,
      formatted =
        date !== null
          ? formatDate(date, this.getFormat(), this.getLocale())
          : date,
      target = {
        name: this.props.name,
        type: 'datepicker',
      }
    if (this.isRange()) {
      const range = {
        ...value,
        [dayPicker.input.name]: formatted,
      }
      this.setState({
        value: range,
      })
      this.props.onChange({
        target: {
          ...target,
          value: range,
        },
      })
    } else {
      this.setState({
        value: formatted,
      })
      this.props.onChange({
        target: {
          ...target,
          value: formatted,
        },
      })
    }
  }
}

export default wrap(DatePickerWrapper)
