import {
  BillingType,
  Currency,
  Direction,
  Price,
  ProductGroup,
  SMSTransactionBilling,
  TransactionType,
} from 'app/types/app'
import { Serializer } from './Serializer'

export enum ProductStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
}

export interface ProductIn {
  id: number
  name: string
  billingtype: BillingType
  code: string
  description: string | null
  created: string
  modified: string
  disabled: boolean
  currency: Currency
  transaction_type: TransactionType
  productgroup: ProductGroup
  sms_transaction_billing: SMSTransactionBilling | null
  price?: Price
  purchase_price?: Price
  price_info: Price[]
  status: ProductStatus
}

export interface Product extends ProductIn {
  operator?: string
  direction?: Direction
  countrycode?: string
}

class ProductIO extends Serializer<ProductIn, Product> {
  in = (data: ProductIn) => {
    let sms_transaction_billing_data = {}
    if (data.sms_transaction_billing) {
      const { countrycode, operator, direction } = data.sms_transaction_billing
      sms_transaction_billing_data = {
        countrycode,
        operator,
        direction,
      }
    }
    data.price_info = data.price_info.map((price) => ({
      ...price,
      current:
        price.id === data.price?.id || price.id === data.purchase_price?.id,
    }))
    const product: Product = {
      ...data,
      ...sms_transaction_billing_data,
    }
    return product
  }
}

export default new ProductIO()
