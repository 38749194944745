import { z } from 'zod'

import { RcsSuggestedActionContent, suggestedActionContent } from './actions'
import { RcsSuggestedReplyContent, suggestedReplyContent } from './replies'

export const rcsMaxSuggestions = 11
export const rcsRichCardMaxSuggestions = 4
export const rcsMediaCarouselCardMaxSuggestions = 3
export const rcsMediumMediaCarouselCardMaxSuggestions = 2
export const rcsTallMediaCarouselCardMaxSuggestions = 1

const suggestion = suggestedActionContent.or(suggestedReplyContent)

export const suggestions = z.array(suggestion)

export type RcsSuggestionContent =
  | RcsSuggestedReplyContent
  | RcsSuggestedActionContent
