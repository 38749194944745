import { omit, partial } from 'lodash'

import {
  downloadFileWithNameFromHeaders,
  emptyStringToNull,
} from 'app/common/utils'
import { throwErrorIfHasPendingJobs } from 'app/globalState'
import { Id, TableRequestOptions } from 'app/types/app'
import { RcsEvent, rcsEventApi } from 'app/types/zod/flow/flow-event/rcs'
import {
  Agent,
  AgentApi,
  agentApi,
  AgentConfiguration,
  AgentConfigurationApi,
  agentConfigurationApiSchemaArray,
  customerAgentApiArray,
  RcsAgentStatus,
} from 'app/types/zod/rcs/agent'
import { rcsLogEvent } from 'app/types/zod/rcs/message-log'
import { parsedRequest, parsedTableRequest } from 'app/types/zod/utils'
import { API } from './API'
import { FileUploadOptions } from './Storage'
import { getPageNumber, getTableQueryHeaders } from '.'

export type BrandMedia = 'logo' | 'banner'

export class Rcs {
  api: API

  constructor(api: API) {
    this.api = api
  }

  // Service
  getRcsEvent = parsedRequest(rcsEventApi, async (customerId: Id, id: Id) => {
    const url = `app/rcsconversation/${id}`
    return await this.api.get(url, {}, true, {
      'X-Customer': customerId,
    })
  })

  createRcsEvent = parsedRequest(
    rcsEventApi,
    async (customerId, event: RcsEvent) => {
      throwErrorIfHasPendingJobs()
      return this.api.post(`app/rcsconversation`, event, true, {
        'X-Customer': customerId,
      })
    }
  )

  abortRcsEvent = (id: Id, customerId: number) => {
    return this.api.get(`app/abort/${id}`, {}, true, {
      'X-Customer': customerId,
    })
  }

  getCustomerLogs = parsedTableRequest(
    rcsLogEvent,
    async (options: TableRequestOptions) => {
      const page = getPageNumber(options)
      const headers = getTableQueryHeaders(options)
      const url = `service/rcsmessagelogs?page=${page}`
      return this.api.get(url, {}, true, headers)
    }
  )

  getCustomerAgents = parsedRequest(
    customerAgentApiArray,
    async (customerId: number) => {
      const headers = getTableQueryHeaders({
        customerId,
        filters: { page_size: 1000 },
      })
      const url = `service/listrcsagents`
      const result = await this.api.get(url, {}, true, headers)
      return result.results
    }
  )

  getActiveAgents = async (customerId: number) => {
    const agents = await this.getCustomerAgents(customerId)
    return agents.filter((agent) => agent.status === RcsAgentStatus.ACTIVE)
  }

  // System management
  createAgent = parsedRequest(agentApi, async (agent: Agent) => {
    const customerId = agent.customer_id
    return this.api.post(`mgmt/companies/${customerId}/rcsagents`, agent)
  })

  updateAgentStatus = async (status: RcsAgentStatus, agent: AgentApi) => {
    const customerId = agent.customer.id
    return this.api.put(
      `mgmt/companies/${customerId}/rcsagent/${agent.id}/status`,
      { status }
    )
  }

  activateAgent = partial(this.updateAgentStatus, RcsAgentStatus.ACTIVE)

  disableAgent = partial(this.updateAgentStatus, RcsAgentStatus.PASSIVE)

  updateAgent = parsedRequest(agentApi, async (agent: AgentApi) => {
    const customerId = agent.customer.id
    agent.description = emptyStringToNull(agent.description)
    agent.privacy_policy = emptyStringToNull(agent.privacy_policy)
    agent.terms_of_service = emptyStringToNull(agent.terms_of_service)

    const data = omit(agent, 'customer')

    return this.api.put(
      `mgmt/companies/${customerId}/rcsagent/${agent.id}`,
      data
    )
  })

  deleteAgent = (agent: AgentApi) => {
    const {
      customer: { id: customerId },
      id,
    } = agent
    return this.api.delete(`mgmt/companies/${customerId}/rcsagent/${id}`)
  }

  uploadBrandMedia = async (
    type: BrandMedia,
    customerId: number,
    agentId: number,
    options: FileUploadOptions
  ) => {
    return this.api.uploadFile(
      `mgmt/companies/${customerId}/rcsagent/${agentId}/media`,
      {
        ...options,
        additionalFields: { type },
      }
    )
  }

  removeBrandMedia = async (
    type: BrandMedia,
    customerId: number,
    agentId: number
  ) => {
    return this.api.delete(
      `mgmt/companies/${customerId}/rcsagent/${agentId}/media`,
      {},
      true,
      {},
      { type }
    )
  }

  getAgents = parsedTableRequest(
    agentApi,
    async (options: TableRequestOptions) => {
      const customerId = options.filters?.search.customer_id
      const page = getPageNumber(options)
      const headers = getTableQueryHeaders(options)
      const url = customerId
        ? `mgmt/companies/${customerId}/rcsagents?page=${page}`
        : `mgmt/rcsagents?page=${page}`
      return this.api.get(url, {}, true, headers)
    }
  )

  getAgentByCompany = parsedRequest(
    agentApi,
    async ({
      customerId,
      agentId,
    }: {
      customerId: number
      agentId: number
    }) => {
      const url = `mgmt/companies/${customerId}/rcsagent/${agentId}`
      return this.api.get(url)
    }
  )

  createAgentConfiguration = async (
    customerId: number,
    configuration: AgentConfiguration
  ) => {
    const agentId = configuration.rcs_agent_id
    return this.api.post(
      `mgmt/companies/${customerId}/rcsagent/${agentId}/configs`,
      configuration
    )
  }

  updateAgentConfiguration = async (
    customerId: number,
    configuration: AgentConfigurationApi
  ) => {
    const id = configuration.id
    const agentId = configuration.rcs_agent_id
    return this.api.put(
      `mgmt/companies/${customerId}/rcsagent/${agentId}/config/${id}`,
      configuration
    )
  }

  deleteAgentConfiguration = async (
    customerId: number,
    configuration: AgentConfigurationApi
  ) => {
    const id = configuration.id
    const agentId = configuration.rcs_agent_id
    return this.api.delete(
      `mgmt/companies/${customerId}/rcsagent/${agentId}/config/${id}`
    )
  }

  getAgentConfigurations = parsedRequest(
    agentConfigurationApiSchemaArray,
    async ({
      customerId,
      agentId,
    }: {
      customerId: number
      agentId: number
    }) => {
      const headers = getTableQueryHeaders({ filters: { page_size: 1000 } })
      const url = `mgmt/companies/${customerId}/rcsagent/${agentId}/configs`
      const result = await this.api.get(url, {}, true, headers)
      return result.results
    }
  )

  getWebhookUrl = async ({
    agentId,
    agentKey,
  }: {
    agentId: number
    agentKey: string
  }) => {
    const resources = await this.api.get(`mgmt/common-resources`)
    const { rbm_receive_url_template } = resources
    const webhookUrl = rbm_receive_url_template
      .replace('{rcs_agent_id}', agentId.toString())
      .replace('{agent_key}', agentKey)
    return webhookUrl
  }

  getLogs = parsedTableRequest(
    rcsLogEvent,
    async (options: TableRequestOptions) => {
      const page = getPageNumber(options)
      const headers = getTableQueryHeaders(options)
      const url = `mgmt/rcsmessagelogs?page=${page}`
      return this.api.get(url, {}, true, headers)
    }
  )

  downloadLogs = async (
    endpoint: string,
    download: 'excel' | 'csv',
    options: TableRequestOptions,
    message?: string
  ) => {
    options.download = download

    const headers = getTableQueryHeaders(options)

    const result = await this.api.get(
      endpoint,
      {},
      true,
      headers,
      download === 'excel' ? true : false,
      true
    )

    downloadFileWithNameFromHeaders({ result, message })
  }

  downloadServiceLogs = async (
    download: 'excel' | 'csv',
    options: TableRequestOptions,
    message?: string
  ) =>
    this.downloadLogs(
      `service/downloadrcsmessagelogs`,
      download,
      options,
      message
    )

  downloadSystemLogs = async (
    download: 'excel' | 'csv',
    options: TableRequestOptions,
    message?: string
  ) =>
    this.downloadLogs(`mgmt/downloadrcsmessagelogs`, download, options, message)
}
