import moment from 'moment'

import { bind } from '../common/utils'
import { getPageNumber } from './'

export const types = {
  LONG_CODE: 'Long code',
  SHORT_CODE: 'Short code',
  VIRTUAL_SHORT_CODE: 'Virtual short code',
}

/**
 * MoNumber API
 */
export class MoNumber {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteMoNumber',
      'getMoNumber',
      'getMoNumbers',
      'saveMoNumber',
    ])
  }

  /**
   * Delete mo number
   */
  deleteMoNumber({ number }) {
    return this.api.delete(`mgmt/mos/${number}`).then(() => {
      return {
        success: true,
      }
    })
  }

  /**
   * Get single mo number by ID
   */
  getMoNumber({ id }) {
    return this.api.get('mgmt/mos/' + id).then((result) => {
      return {
        type: result.numbertype,
        customer: result.customer_id,
        number: result.number,
        description: result.description,
        customer_description: result.customer_description,
        created: moment(result.created),
        routeruleinfo: result.routeruleinfo,
      }
    })
  }

  /**
   * Get mo numbers
   */
  getMoNumbers(request = {}) {
    const options = {
      ...request,
      search: {
        ...request.extra,
        ...request.search,
      },
    }
    return this.api
      .get(
        'mgmt/mos?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          customer: 'customer_id',
          description: 'description',
          customer_description: 'customer_description',
          number: 'number',
          type: 'numbertype',
          created: 'created',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              number: result.number,
              type: result.numbertype,
              customer: result.customer_id,
              description: result.description,
              customer_description: result.customer_description,
              created: moment(result.created),
            }
          }),
        }
      })
  }

  /**
   * Save mo number
   */
  async saveMoNumber(moNumber) {
    if (moNumber.action === 'add') {
      return await this.api
        .post('mgmt/mos', {
          customer_id: moNumber.customer,
          numbertype: moNumber.type,
          number: moNumber.number,
          description: moNumber.description,
          customer_description: moNumber.customer_description,
        })
        .then((result) => {
          return {
            number: result.number,
            customer: result.customer_id,
            type: result.numbertype,
            description: result.description,
            customer_description: result.customer_description,
          }
        })
    } else {
      return await this.api
        .put('mgmt/mos/' + moNumber.action, {
          customer_id: moNumber.customer,
          numbertype: moNumber.type,
          number: moNumber.number,
          description: moNumber.description,
          customer_description: moNumber.customer_description,
        })
        .then((result) => {
          return {
            number: result.number,
            customer: result.customer_id,
            type: result.numbertype,
            description: result.description,
            customer_description: result.customer_description,
          }
        })
    }
  }

  async updateMoNumberDescription({
    customerId,
    number,
    customer_description,
  }) {
    const headers = {
      'X-Customer': customerId,
    }

    return this.api.put(
      `service/customermonumbers/${number}`,
      { customer_description },
      true,
      headers
    )
  }
}
