import { omit, some, values } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import {
  formatFileSize,
  getCurrentDateAsIsoString,
  kiloByte,
  megaByte,
} from 'app/common/utils'
import { ActionType, RcsContentType } from 'app/types/rcs'
import {
  CarouselCardWidth,
  CarouselCardWidthEnum,
  RichCardAlignment,
  RichCardMediaHeight,
  RichCardOrientation,
} from 'app/types/zod/common/Enums'
import { RcsContent } from 'app/types/zod/rcs/content'
import { RcsCarouselContent } from 'app/types/zod/rcs/content/carousel'
import {
  RcsMediaContent,
  RcsRichCardMedia,
} from 'app/types/zod/rcs/content/media'
import {
  rcsMaxSuggestions,
  rcsMediaCarouselCardMaxSuggestions,
  rcsMediumMediaCarouselCardMaxSuggestions,
  rcsRichCardMaxSuggestions,
  RcsSuggestionContent,
  rcsTallMediaCarouselCardMaxSuggestions,
} from 'app/types/zod/rcs/content/suggestions'
import { isSuggestedReplyContent } from 'app/types/zod/rcs/content/suggestions/replies'
import { RcsTextContent } from 'app/types/zod/rcs/content/text'
import {
  isRcsCarouselCardNode,
  isRcsCarouselNode,
  isRcsRichCardNode,
  RcsData,
  RcsNode,
} from 'app/types/zod/rcs/form-data'
import { RcsBranch } from 'app/types/zod/rcs/form-data/branch'
import {
  RcsCarouselCardNode,
  RcsCarouselNode,
} from 'app/types/zod/rcs/form-data/carousel'
import {
  RcsMediaFileContent,
  RcsMediaNode,
} from 'app/types/zod/rcs/form-data/media'
import { RcsRichCardNode } from 'app/types/zod/rcs/form-data/rich-card'
import {
  CallNumberAction,
  CreateCalendarEventAction,
  isSuggestedReply,
  OpenUrlAction,
  RcsSuggestedReply,
  RcsSuggestion,
  ViewLocationAction,
} from 'app/types/zod/rcs/form-data/suggestions'
import { RcsTextNode } from 'app/types/zod/rcs/form-data/text'
import { WithEditorId } from 'app/types/zod/utils'

export const createEditorId = () => uuidv4()

const defaultTextContent: RcsTextContent = {
  text: '',
  suggestions: [],
}
const defaultMediaContent: RcsMediaContent = {
  file: {
    file_content_type: 'MEDIA',
    url: '',
  },
  suggestions: [],
}

export const defaultRichCardMedia: RcsRichCardMedia = {
  height: RichCardMediaHeight.enum.MEDIUM,
  file: {
    file_content_type: 'MEDIA',
    url: '',
  },
}

export const createRcsNode = (type: RcsContentType): RcsNode =>
  itemCreator[type]()

export const createTextItem = (content?: RcsTextContent): RcsTextNode => {
  const nodeContent = content || defaultTextContent

  return {
    ...addEditorId(nodeContent),
    suggestions: nodeContent.suggestions.map(addEditorId),
  }
}

export const createMediaItem = (content?: RcsMediaContent): RcsMediaNode => {
  const nodeContent = content || defaultMediaContent

  return {
    ...addEditorId(nodeContent),
    suggestions: nodeContent.suggestions.map(addEditorId),
  }
}

export const createRichCardItem = (): RcsRichCardNode => {
  return {
    title: '',
    description: '',
    editorId: createEditorId(),
    orientation: RichCardOrientation.enum.VERTICAL,
    thumbnail_alignment: RichCardAlignment.enum.LEFT,
    suggestions: [],
  }
}

export const createCarouselCardItem = (
  width?: CarouselCardWidthEnum
): RcsCarouselCardNode => {
  return {
    title: '',
    description: '',
    editorId: createEditorId(),
    width: width || CarouselCardWidth.enum.MEDIUM,
    suggestions: [],
  }
}

export const createCarouselItem = (): RcsCarouselNode => {
  return {
    editorId: createEditorId(),
    width: CarouselCardWidth.enum.MEDIUM,
    rich_cards: [],
    suggestions: [],
  }
}

export const createBranch = (options?: Partial<RcsBranch>): RcsBranch => {
  const editorId = options?.editorId || createEditorId()
  const nodes = options?.nodes || []
  return {
    root: false,
    ...(options || {}),
    nodes,
    editorId,
  }
}

export const createSuggestedReply = ({
  text = '',
  branchId = createEditorId(),
}: {
  text?: string
  branchId?: string
} = {}): RcsSuggestedReply => {
  return {
    editorId: createEditorId(),
    text,
    postback_data: branchId,
  }
}

export const createOpenUrlAction = ({
  url = '',
  text = '',
}: {
  url?: string
  text?: string
} = {}): OpenUrlAction => {
  const editorId = createEditorId()
  return {
    editorId,
    text,
    postback_data: `${ActionType.OPEN_URL}_${editorId}`,
    action: { url },
  }
}

export const createCallNumberAction = ({
  number = '',
  text = '',
}: {
  number?: string
  text?: string
} = {}): CallNumberAction => {
  const editorId = createEditorId()
  return {
    editorId,
    text,
    postback_data: `${ActionType.CALL_NUMBER}_${editorId}`,
    action: { number },
  }
}

export const createCreateCalendarEventAction = (
  text?: string
): CreateCalendarEventAction => {
  const editorId = createEditorId()
  return {
    editorId,
    text: text || '',
    postback_data: `${ActionType.CREATE_CALENDAR_EVENT}_${editorId}`,
    action: {
      start_datetime: getCurrentDateAsIsoString(),
      end_datetime: getCurrentDateAsIsoString(),
      title: '',
      description: '',
    },
  }
}

export const createViewLocationAction = (text?: string): ViewLocationAction => {
  const editorId = createEditorId()
  return {
    editorId,
    text: text || '',
    postback_data: `${ActionType.VIEW_LOCATION}_${editorId}`,
    action: {
      show: {
        label: '',
        latitude: 0,
        longitude: 0,
      },
    },
  }
}

const itemCreator: { [k in RcsContentType]: () => RcsNode } = {
  TEXT: createTextItem,
  MEDIA: createMediaItem,
  IMAGE: createMediaItem,
  VIDEO: createMediaItem,
  RICH_CARD: createRichCardItem,
  CAROUSEL: createCarouselItem,
  CAROUSEL_CARD: createCarouselCardItem,
}

export const hasSuggestions = (node: RcsNode) =>
  'suggestions' in node && node.suggestions && node.suggestions.length > 0

export const getMaxSuggestions = (node: RcsNode) => {
  if (isRcsRichCardNode(node)) {
    return rcsRichCardMaxSuggestions
  }

  if (isRcsCarouselCardNode(node)) {
    return !!node.media
      ? node.media.height === RichCardMediaHeight.enum.TALL
        ? rcsTallMediaCarouselCardMaxSuggestions
        : node.media.height === RichCardMediaHeight.enum.MEDIUM
        ? rcsMediumMediaCarouselCardMaxSuggestions
        : rcsMediaCarouselCardMaxSuggestions
      : rcsRichCardMaxSuggestions
  }

  return rcsMaxSuggestions
}

export const carouselCardHasTextAndSuggestions = (node: RcsCarouselCardNode) =>
  node.suggestions.length > 0 && (!!node.title || !!node.description)

export const carouselHasSuggestions = (
  node: RcsCarouselNode,
  nodes: RcsData['nodes']
) => {
  const carouselCards = node.rich_cards
    .map((id) => nodes[id])
    .filter(isRcsCarouselCardNode)

  return (
    (node.suggestions && node.suggestions.length > 0) ||
    some(
      carouselCards.map(
        (card) => card.suggestions && card.suggestions.length > 0
      )
    )
  )
}

export const getRootBranch = (branches: RcsData['branches']) =>
  values(branches).find((branch) => branch.root)

export const getSuggestedReplies = (suggestions: RcsSuggestion[]) =>
  suggestions.filter(isSuggestedReply)

export const getSuggestedRepliesContent = (
  suggestions: RcsSuggestionContent[]
) => suggestions.filter(isSuggestedReplyContent)

export const getEditorId = (node: WithEditorId) => node.editorId

export const editorIds = (nodes: WithEditorId[]) => nodes.map(getEditorId)

export function normalize<T extends WithEditorId>(items: T[]) {
  return items.reduce(
    (result, item) => ({ ...result, [item.editorId]: item }),
    {} as { [k in T['editorId']]: T }
  )
}

export const getCarouselCardSuggestions = (carousel: RcsCarouselContent) =>
  carousel.rich_cards.reduce((suggestions, card) => {
    return [...suggestions, ...(card.suggestions || [])]
  }, [] as RcsSuggestionContent[])

export function omitEditorId<T extends WithEditorId>(
  item: T
): Omit<T, 'editorId'> {
  return omit(item, 'editorId')
}

export function addEditorId<T extends RcsContent | RcsSuggestionContent>(
  item: T
): T & WithEditorId {
  return {
    ...item,
    editorId: createEditorId(),
  }
}

export const hasLogo = (node: RcsNode) =>
  !isRcsRichCardNode(node) &&
  !isRcsCarouselNode(node) &&
  !isRcsCarouselCardNode(node)

// Rcs media types
export const rcsImageFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/png',
]

export const rcsVideoFileTypes = [
  'video/h263',
  'video/m4v',
  'video/mp4',
  'video/mpeg',
  'video/mpeg4',
  'video/webm',
]

export const rcsMediaTypes = rcsImageFileTypes.concat(rcsVideoFileTypes)

// Maximum media file sizes

export const rcsAgentLogoMaxSize = 50 * kiloByte

export const rcsAgentHeroMaxSize = 200 * kiloByte

export const mediaStorageMaxUploadSize = 10 * megaByte

export const rcsMessageMediaMaxSize = 10 * megaByte

export const rcsThumbnailMaxSize = 100 * kiloByte

export const rcsRichCardVideoMaxSize = 10 * megaByte

export const rcsRichCardImageMaxSize = 2 * megaByte

export const rcsCarouselCardVideoMaxSize = 5 * megaByte

export const rcsCarouselCardImageMaxSize = 1 * megaByte

const invalidFileTypeMsg = 'Please select a file with a valid type'
const maxSizeExceededMsg = 'Maximum file size exceeded!'
const maxSizeMsg = 'Maximum file size:'

export const validateRcsAgentLogo = (file: File) =>
  validateRcsAgentImage(rcsAgentLogoMaxSize, file)

export const validateRcsAgentHero = (file: File) =>
  validateRcsAgentImage(rcsAgentHeroMaxSize, file)

const createMaxSizeErrorMsg = (size: number) => [
  maxSizeExceededMsg,
  maxSizeMsg,
  formatFileSize(size),
]

const validateRcsAgentImage = (size: number, file: File) => {
  if (!rcsImageFileTypes.includes(file.type)) {
    return [invalidFileTypeMsg]
  }

  if (file.size > size) {
    return createMaxSizeErrorMsg(size)
  }
}

export const validateMediaUpload = (file: File) => {
  if (!rcsMediaTypes.includes(file.type)) {
    return [invalidFileTypeMsg]
  }

  if (file.size > mediaStorageMaxUploadSize) {
    return createMaxSizeErrorMsg(mediaStorageMaxUploadSize)
  }
}

export const getMediaContentUrl = (content?: RcsMediaFileContent) => {
  if (typeof content === 'string') {
    return content
  } else if (content) {
    return content.url
  } else {
    return ''
  }
}
