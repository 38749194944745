import { isFunction } from 'lodash'
import moment from 'moment'

import { throwErrorIfHasPendingJobs } from 'app/globalState'

export class FeedbackAndInquiry {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/smsfeedback'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => response)
  }

  post(request) {
    throwErrorIfHasPendingJobs()

    const { customerId } = request

    let data = {
      applicationid: request.applicationId,
      applicationevent_key: request.applicationEventKey,
      eventid: 0,
      description: '',
      status: '',
      name: request.name,
      destination: request.destination,
      keyword: request.keyword,
      responsebody: request.receive.response
        ? request.receive.message
        : undefined,
      receive_enddatetime: moment(
        request.receive.date + ' ' + request.receive.time,
        'DD.MM.YYYY HH:mm'
      ).format('YYYY-MM-DD HH:mm:ss'),
      sendsmsaction: {
        recipients: 0,
        status: '',
        message: {
          source: request.destination,
          senderlistid: 0,
          groups: request.groups,
          msisdns: isFunction(request.msisdns.filter)
            ? request.msisdns.filter(Boolean)
            : [],
          smsbody: request.smsbody,
        },
        schedule: request.schedule,
        remind: request.remind,
      },
    }

    if (request.receive.notification.active) {
      const {
        notification: { sms, email, activeSms, activeEmail },
      } = request.receive

      if (activeSms)
        data.sms_action = {
          ...sms,
          smsfrom: sms.smsfrom === '' ? request.number : sms.smsfrom,
          msisdns: isFunction(sms.msisdns.filter)
            ? sms.msisdns.filter(Boolean)
            : [],
        }

      if (activeEmail) data.email_action = email
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  update(request) {
    const { id, customerId, action } = request

    if (action !== 'ABORT') {
      throwErrorIfHasPendingJobs()
    }

    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          action,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  delete() {}
}
