import { getPageNumber } from 'app/api'
import { throwErrorIfHasPendingJobs } from 'app/globalState'
import { downloadFileWithNameFromHeaders } from '../../common/utils'

export class CallToAction {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/calltoaction'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        const { dialog_data, dialogsmsaction } = response
        return {
          ...response,
          dialog: {
            id: dialog_data.id,
            parentMessage: '',
            dialogsmsaction,
            errorMessage: dialog_data.errormessage,
            ...this.recursiveTreeMessage(dialog_data.children),
          },
        }
      })
  }

  post(request) {
    throwErrorIfHasPendingJobs()

    const { customerId } = request

    const data = {
      applicationid: request.applicationId,
      applicationevent_key: request.applicationEventKey,
      name: request.name,
      description: '',
      status: '',
      eventid: null,
      sendsmsaction: {
        recipients: 0,
        status: '',
        message: {
          source: request.source,
          senderlistid: 0,
          groups: request.groups,
          msisdns: request.msisdns.filter(Boolean),
          smsbody: request.smsbody,
        },
        schedule: request.schedule,
        remind: request.remind,
      },
      dialogsmsaction: {
        destination: request.source,
        keyword: '',
        status: '',
        dialog_enddatetime: request.dialog_enddatetime,
      },
      dialog_data: {
        id: 0,
        parent: null,
        level: 0,
        choices: null,
        response: null,
        error_response: request.dialogData.errorResponse,
        children: request.dialogData.dialogChildren.map((child) => {
          return {
            parent: 0,
            level: 1,
            choices: child.choices,
            response: child.response.map((response, index) => {
              return {
                response: child.response[index].response,
                type: index > 0 ? '>' : '',
                criteria: index > 0 ? child.response[index].criteria : '',
                sms_action: child.response[index].activeSms
                  ? child.response[index].sms_action
                  : undefined,
                email_action: child.response[index].activeEmail
                  ? child.response[index].email_action
                  : undefined,
              }
            }),
          }
        }),
      },
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return {
          id: response.id,
          applicationId: response.applicationid,
          source: response.source,
          groups: response.groups,
          sender: response.sender,
          smsBody: response.smsbody,
          scheduleSendDatetime: response.schedule_senddatetime,
          success: true,
        }
      })
  }

  update(request) {
    const { id, customerId, action } = request

    if (action !== 'ABORT') {
      throwErrorIfHasPendingJobs()
    }

    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          action,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  getReports = (request = {}) => {
    const {
      extra: {
        customerId,
        id,
        columns,
        download,
        filterInput: {
          reportDaterange,
          number,
          showInitial,
          activeDialogBox,
          onLoad,
        },
      },
    } = request

    const options = {
      ...request,
      search: {
        ...request.search,
        showcolumns: columns || '',
        reportDaterange: !onLoad
          ? `${reportDaterange.from} - ${reportDaterange.to}`
          : {},
        number,
        download,
        showInitial,
        activeDialogBox,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}report/${id}?page=${getPageNumber(request)}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            reportDaterange: 'report_daterange',
            number: 'msisdn',
            contact__msisdn: 'contact__msisdn',
            itemmessage: 'itemmessage',
            response: 'response',
            created: 'created',
            showcolumns: 'showcolumns',
            download: 'download',
            showInitial: 'includeoriginals',
            activeDialogBox: 'activedialogbox',
          }),
        },
        download === 'excel' ? true : false,
        true
      )
      .then((result) => {
        const {
          responses,
          count,
          report_daterange,
          dialog_data,
          showcolumns,
          guicolumns,
        } = result.data

        if (download !== '') {
          downloadFileWithNameFromHeaders({ result })
          return this.getReports({
            ...request,
            extra: { ...request.extra, download: '' },
          })
        }

        return {
          count,
          results: responses,
          additional: {
            report_daterange,
            showcolumns,
            guicolumns,
            dialog: {
              id: dialog_data.id,
              parentMessage: dialog_data.response,
              ...this.recursiveTreeMessage(dialog_data.children),
            },
          },
        }
      })
  }

  recursiveTreeMessage(data) {
    if (!data.length) return {}
    return {
      messages: data.map((result) => {
        const { id, choices, response, children, sms, email } = result

        return {
          id,
          request: choices,
          response,
          sms,
          email,
          dialog: this.recursiveTreeMessage(children),
        }
      }),
    }
  }
}
