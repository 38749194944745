import Paging from 'react-bootstrap/Pagination'
import clsx from 'clsx'
import { range } from 'lodash'

import './Pagination.scss'

export const DEFAULT_LIMIT = 10
export const DEFAULT_MAX = 5
export const DEFAULT_PAGE = 1

interface PaginationProps {
  count?: number
  fixed?: boolean
  limit?: number
  max?: number
  page?: number
  start?: number
  onGoto?: (page: number) => void
}

const Pagination = ({
  count = 0,
  fixed = true,
  limit = DEFAULT_LIMIT,
  onGoto = () => {},
  max = DEFAULT_MAX,
  page,
  start,
}: PaginationProps) => {
  const currentPage =
    page || (start ? Math.floor(start / limit) + 1 : DEFAULT_PAGE)
  const lastPage = Math.ceil(count / limit)
  const half = Math.floor(max / 2)
  const rem = max % 2
  let left = currentPage - (rem ? half : half - 1)
  let right = currentPage + half
  if (left < 1) {
    right += 1 - left
    left = 1
  }
  if (right > lastPage) {
    left -= right - lastPage
    right = lastPage
  }
  if (left < 1) {
    left = 1
  }

  return (
    <Paging className={clsx(fixed && 'fixed')}>
      <Paging.First
        className="first"
        disabled={currentPage === 1}
        onClick={() => onGoto(1)}
      />
      {currentPage > 1 && (
        <Paging.Prev className="prev" onClick={() => onGoto(currentPage - 1)} />
      )}
      {range(left, right + 1).map((page) => {
        return (
          <Paging.Item
            className={'page page-' + page}
            key={page}
            active={page === currentPage}
            onClick={() => page === currentPage || onGoto(page)}
          >
            {page}
          </Paging.Item>
        )
      })}
      {currentPage < lastPage && (
        <Paging.Next className="next" onClick={() => onGoto(currentPage + 1)} />
      )}
      <Paging.Last
        className="last"
        disabled={currentPage === lastPage}
        onClick={() => onGoto(lastPage)}
      />
    </Paging>
  )
}

export default Pagination
