import { create } from 'zustand'

import { displayErrorToast } from 'app/common/utils'
import i18n from 'app/i18n'
import { CleanupAction } from 'app/types/cleanup'
import {
  PendingCleanup,
  PendingJobData,
} from 'app/types/zod/common/data-cleanup/pending'

interface GlobalState {
  pendingJobs: PendingCleanup[]
  actions: {
    addPendingJobs: (pendingJobs: PendingCleanup[]) => void
    setPendingJobs: (pendingJobs: PendingCleanup[]) => void
  }
}

export const useGlobalState = create<GlobalState>()((set) => ({
  pendingJobs: [],
  actions: {
    addPendingJobs: (newJobs) => {
      set((state) => ({
        ...state,
        pendingJobs: [...state.pendingJobs, ...newJobs],
      }))
    },
    setPendingJobs: (pendingJobs) => {
      set({
        pendingJobs,
      })
    },
  },
}))

export const filterCleanups = (dataObject: string) => (job: PendingCleanup) =>
  job.data_object === dataObject && job.action === CleanupAction.DELETE

const sumIfSomeNotNull = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return null
  }

  return Number(a) + Number(b)
}

const combineCleanups =
  (dataObject: string) =>
  (jobs: PendingCleanup[]): PendingJobData | null => {
    const filtered = jobs.filter(filterCleanups(dataObject))

    return filtered.reduce((result, job) => {
      const { data_object, total_amount, processed_amount } = job
      if (result === null) {
        return {
          data_object,
          total_amount,
          processed_amount,
        }
      } else {
        return {
          data_object,
          total_amount: sumIfSomeNotNull(result.total_amount, total_amount),
          processed_amount: sumIfSomeNotNull(
            result.processed_amount,
            processed_amount
          ),
        }
      }
    }, null as PendingJobData | null)
  }

const combineContactCleanups = combineCleanups('Contact')

const combineApplicationEventCleanups = combineCleanups('ApplicationEvent')

export const usePendingJobs = () =>
  useGlobalState<PendingJobData[]>((state) =>
    [
      combineContactCleanups(state.pendingJobs),
      combineApplicationEventCleanups(state.pendingJobs),
    ].filter((job): job is PendingJobData => job !== null)
  )

export const useGlobalActions = () => useGlobalState((state) => state.actions)

export const applicationEventBatchDeleteError =
  'applicationEventBatchDeleteError'

export const throwErrorIfHasPendingJobs = () => {
  const hasPendingJobs = useGlobalState.getState().pendingJobs.length > 0

  if (hasPendingJobs) {
    displayErrorToast(
      i18n.t(
        'Creating and updating events is disabled while removing events or contacts'
      )
    )

    throw new Error(applicationEventBatchDeleteError)
  }
}
