import { z } from 'zod'

import { mtBatch, mtBatchApi } from '../../common/batches'
import { rcsContent } from '../../rcs/content'
import { apiEntityFields, integer, nonEmptyString } from '../../utils'
import { flowFunctionApiBase, flowFunctionBase } from '.'

const smsFallback = z.object({
  sms_message: z.object({
    sender: nonEmptyString,
    text: nonEmptyString,
  }),
  conditions: z.object({
    unavailable: z.literal(true),
    carrier_error: z.literal(true),
    expired: z.literal(true),
    incapable: z.literal(true),
  }),
})

const sendRcsFunction = z.object({
  id: z.optional(z.number()),
  agent_id: z.nullable(z.number()),
  body: z.array(rcsContent),
  fallback: z.optional(z.nullable(smsFallback)),
  send_rcs_batches: z.array(mtBatch),
})

const sendRcsFunctionApi = sendRcsFunction
  .extend({
    enable_duplicates: z.boolean(),
    send_rcs_batches: z.array(mtBatchApi),
    use_session: z.boolean(),
    validity: integer,
  })
  .merge(apiEntityFields)
  .strict()

const receivercsfunction = z.object({
  id: z.optional(z.number()),
  agent_id: z.nullable(z.number()),
})

const receivercsfunctionApi = receivercsfunction.merge(apiEntityFields).strict()

export const sendRcsFlowFunction = flowFunctionBase.extend({
  sendrcsfunction: sendRcsFunction,
})

export const receiveRcsFlowFunction = flowFunctionBase.extend({
  receivercsfunction,
})

export const sendRcsFlowFunctionApi = flowFunctionApiBase.extend({
  customerrouterule_id: z.nullable(integer),
  order: integer,
  sendrcsfunction: sendRcsFunctionApi,
})

export const receiveRcsFlowFunctionApi = flowFunctionApiBase.extend({
  customerrouterule_id: z.nullable(integer),
  order: integer,
  receivercsfunction: receivercsfunctionApi,
})

export type SendRcsFlowFunction = z.infer<typeof sendRcsFlowFunction>

export type ReceiveRcsFlowFunction = z.infer<typeof receiveRcsFlowFunction>

export type SendRcsFlowFunctionApi = z.infer<typeof sendRcsFlowFunctionApi>

export type ReceiveRcsFlowFunctionApi = z.infer<
  typeof receiveRcsFlowFunctionApi
>

export type RcsFlowFunctionApi =
  | SendRcsFlowFunctionApi
  | ReceiveRcsFlowFunctionApi

export const isSendRcsFlowFunctionApi = (
  fn: RcsFlowFunctionApi
): fn is SendRcsFlowFunctionApi => 'sendrcsfunction' in fn
