import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System policies initial state
 */
export const newsFeedState = {
  articles: {},
}

/**
 * Policies reducer
 */
export default handleActions(
  {
    ARTICLE_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        articles: extend({}, state.articles, payload || {}),
      })
    },
    NEWS_FEED_RESET: (state) => {
      return extend({}, state, {
        articles: {},
      })
    },
  },
  newsFeedState
)
