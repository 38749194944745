import { createActionSaga, tracking } from '../../api'

export const systemUserMessageLogs = 'system-user-message-logs'

const { getSystemMessageLogs } = tracking

const messageLogs = [
  createActionSaga(systemUserMessageLogs, getSystemMessageLogs),
]

export default messageLogs
