import { nullable, z } from 'zod'

import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
} from 'app/types/zod/utils'

export const contactResponseItem = z.object({
  id: integer,
  first_name: nullableString,
  last_name: nullableString,
  msisdn: nonEmptyString,
  phone: nullableString,
  email: nullableString,
  customernumber: nullable(integer),
  birthday: nullableString,
  gender: nullableString,
  address: nullableString,
  postalcode: nullableString,
  city: nullableString,
  param1: nullableString,
  param2: nullableString,
  param3: nullableString,
  param4: nullableString,
  receivesms: z.boolean(),
  failedsms_count: integer,
  created: datetimeStringWithOffset,
  modified: datetimeStringWithOffset,
  notecount: integer,
})

export type ContactResponseItem = z.infer<typeof contactResponseItem>
