import { createActionSaga, createFormSaga, policy } from '../../api'
import { actionSuccess } from '../../common/action'

export const systemPolicies = 'system-policies'
export const systemUserPolicies = 'system-user-policies'
export const systemPolicyGroups = 'system-policy-groups'
export const systemAccountPolicyGroups = 'system-account-policy-groups'
export const policyGroupDelete = 'policy-group-delete'
export const policyGroupReload = 'policy-group-reload'
export const policyGroupSave = 'policy-group-save'
export const policySavePermissions = 'policy-save-permissions'

const {
  deleteGroup,
  getPolicies,
  getPolicyGroupPolicies,
  getGroup,
  getGroups,
  getAccountGroups,
  saveGroup,
  savePermissions,
} = policy

const policies = [
  createActionSaga(systemPolicies, getPolicies),
  createActionSaga(systemUserPolicies, getPolicyGroupPolicies),
  createActionSaga(systemPolicyGroups, getGroups),
  createActionSaga(systemAccountPolicyGroups, getAccountGroups),
  createActionSaga(policyGroupDelete, deleteGroup),
  createActionSaga(policyGroupReload, getGroup, {
    busy: true,
  }),
  createActionSaga(policySavePermissions, savePermissions),
  createFormSaga(policyGroupSave, saveGroup, {
    message: (payload, result) => {
      return result.new
        ? 'Policy group added successfully'
        : 'Policy group was updated successfully'
    },
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/system/policy-groups/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(policyGroupReload, result)
    },
  }),
]

export default policies
