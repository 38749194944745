import { z } from 'zod'

import { FlowStatus } from 'app/types/flow'
import { RcsAgentBillingCategory } from 'app/types/zod/rcs/agent'
import { groupApi } from '../../common/group'
import { rcsRootEvent } from '../../flow/flow-event/rcs'
import {
  datetimeStringWithOffset,
  editorId,
  integer,
  nonEmptyString,
  optionalString,
} from '../../utils'
import { sendRcsEventBranch } from './branch'
import {
  RcsCarouselCardNode,
  rcsCarouselCardNode,
  RcsCarouselNode,
  rcsCarouselNode,
} from './carousel'
import { RcsMediaNode, rcsMediaNode } from './media'
import { RcsRichCardNode, rcsRichCardNode } from './rich-card'
import { RcsTextNode, rcsTextNode } from './text'

export const rcsMessageNode = z.union([
  rcsTextNode,
  rcsMediaNode,
  rcsRichCardNode,
])

export const rcsNode = z.union([
  rcsMessageNode,
  rcsCarouselNode,
  rcsCarouselCardNode,
])

const rcsData = z.object({
  flow: z.optional(rcsRootEvent),
  nodes: z.record(editorId, rcsNode),
  branches: z.record(editorId, sendRcsEventBranch),
})

const smsFallback = z.object({
  sender: nonEmptyString,
  text: nonEmptyString,
})

export const rcsFormFields = {
  agentId: 'agent_id' as const,
  agentBillingCategory: 'agent_billing_category' as const,
}

const rcsFormValues = z.object({
  id: z.optional(z.number()),
  status: z.optional(z.nativeEnum(FlowStatus)),
  applicationevent_key: optionalString,
  systemapplication_id: integer,
  [rcsFormFields.agentId]: nonEmptyString,
  [rcsFormFields.agentBillingCategory]: z.nullable(
    z.nativeEnum(RcsAgentBillingCategory)
  ),
  name: nonEmptyString,
  data: rcsData,
  msisdns: z.array(z.string()),
  groups: z.array(groupApi),
  begin: z.nullable(datetimeStringWithOffset),
  scheduled: z.optional(z.boolean()),
  fallback: z.optional(smsFallback),
})

export const rcsDraftValues = rcsFormValues
  .pick({
    data: true,
    name: true,
    msisdns: true,
    groups: true,
  })
  .extend({
    agent_id: optionalString,
    fallback: z.optional(
      z.object({
        sender: optionalString,
        text: optionalString,
      })
    ),
  })

export type RcsNode = z.infer<typeof rcsNode>

export type NodeHandler = (node: RcsNode) => void

export type RcsFormValues = z.infer<typeof rcsFormValues>

export type RcsData = z.infer<typeof rcsData>

export const isRcsTextNode = (node: RcsNode): node is RcsTextNode =>
  'text' in node

export const isRcsMediaNode = (node: RcsNode): node is RcsMediaNode =>
  'file' in node

export const isRcsRichCardNode = (node: RcsNode): node is RcsRichCardNode =>
  'orientation' in node

export const isRcsCarouselCardNode = (
  node: RcsNode
): node is RcsCarouselCardNode => 'title' in node && !('orientation' in node)

export const isRcsCarouselNode = (node: RcsNode): node is RcsCarouselNode =>
  'rich_cards' in node
