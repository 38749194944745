import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Router initial state
 */
export const routerState = {
  resources: {
    busy: false,
    data: {},
    error: null,
  },
  routing: {},
  settings: {},
  apiKey: {},
  openHttpInfo: {},
  openHttpParams: {},
}

/**
 * Router reducer
 */
export default handleActions(
  {
    USER_OPENHTTP_INFO_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        openHttpInfo: {
          ...state.openHttpInfo,
          ...payload,
        },
      }
    },
    USER_OPENHTTP_PARAMS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        openHttpParams: {
          ...state.openHttpParams,
          ...payload,
        },
      }
    },
    USER_ROUTER_SETTINGS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload,
        },
      }
    },
    USER_ROUTER_RESOURCES: (state) => {
      return {
        ...state,
        resources: {
          ...state.resources,
          busy: true,
        },
      }
    },
    USER_ROUTER_RESOURCES_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        resources: {
          ...state.resources,
          busy: false,
          data: payload,
        },
      }
    },
    USER_ROUTER_RESOURCES_FAIL: (state, { payload }) => {
      return {
        ...state,
        resources: {
          ...state.resources,
          busy: false,
          error: payload,
        },
      }
    },
    USER_ROUTER_API_KEY: (state) => {
      return {
        ...state,
        apiKey: '',
      }
    },
    USER_ROUTER_API_KEY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        apiKey: payload.key,
      }
    },
    RESOURCES_RESET: (state) => {
      return extend({}, state, {
        resources: {
          busy: false,
          data: {},
        },
      })
    },
    ROUTING_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        routing: extend({}, state.routing, payload || {}),
      })
    },
    ROUTING_RESET: (state) => {
      return extend({}, state, {
        routing: {},
      })
    },
  },
  routerState
)
