import axios from 'axios'

import {
  ApiKeyPostData,
  SinglePurposeApiKey,
  TableRequestOptions,
  TableResponse,
} from 'app/types/app'
import {
  GlobalApiKey,
  globalApiKeyApi,
} from 'app/types/zod/common/global-apikey'
import { parsedRequest, parsedTableRequest } from 'app/types/zod/utils'
import { API } from './API'
import { getPageNumber } from '.'

export class ApiKeys {
  api: API
  globalKeyUrl = 'service/apikeys/global'
  singleUseKeyUrl = 'service/apikeys/singleuse'
  constructor(api: API) {
    this.api = api
  }

  createApiKey = parsedRequest(
    globalApiKeyApi,
    async (data: ApiKeyPostData, options: { customerId: number }) => {
      const headers = {
        'X-Customer': options.customerId,
        'Content-Type': 'application/json',
      }
      return this.api.post(this.globalKeyUrl, data, true, headers)
    }
  )

  updateApiKey = parsedRequest(
    globalApiKeyApi,
    async (id: number, data: GlobalApiKey, options: { customerId: number }) => {
      const headers = {
        'X-Customer': options.customerId,
        'Content-Type': 'application/json',
      }
      return this.api.put(`${this.globalKeyUrl}/${id}`, data, true, headers)
    }
  )

  regenerateApiKey = parsedRequest(
    globalApiKeyApi,
    async (data: GlobalApiKey, options: { customerId: number }) => {
      const headers = {
        'X-Customer': options.customerId,
        'Content-Type': 'application/json',
      }
      return this.api.put(
        `${this.globalKeyUrl}/${data.id}`,
        { ...data, apikey: null },
        true,
        headers
      )
    }
  )

  getGlobalApiKeys = parsedTableRequest(
    globalApiKeyApi,
    async (options: TableRequestOptions) => {
      const filters = {
        ...options.filters,
        ...options.filters?.search,
      }
      delete filters.search

      return this.api.get(
        `${this.globalKeyUrl}?page=${getPageNumber(options)}`,
        {},
        true,
        {
          'X-Customer': options.customerId,
          'X-Filter': JSON.stringify(filters),
        }
      )
    }
  )

  getSingleUseApiKeys = async (options: TableRequestOptions) => {
    const filters = {
      ...options.filters,
      ...options.filters?.search,
    }
    delete filters.search

    return this.api.get(
      `${this.singleUseKeyUrl}?page=${getPageNumber(options)}`,
      {},
      true,
      {
        'X-Customer': options.customerId,
        'X-Filter': JSON.stringify(filters),
      }
    ) as TableResponse<SinglePurposeApiKey>
  }

  getApiKey = parsedRequest(
    globalApiKeyApi,
    async (
      apikey: string,
      options: {
        customerId: number
      }
    ) => {
      return this.api.get(`service/apikeys/global/${apikey}`, {}, true, {
        'X-Customer': options.customerId,
      })
    }
  )

  deleteApiKey = async (id: number, options: { customerId: number }) => {
    const headers = {
      'X-Customer': options.customerId,
    }
    try {
      return await this.api.delete(
        `service/apikeys/global/${id}`,
        {},
        true,
        headers
      )
    } catch (error) {
      if (!axios.isAxiosError(error) || error.response?.status !== 404) {
        throw error
      }
    }
  }
}
