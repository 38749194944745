import { z } from 'zod'

import { systemApiSingle } from 'app/types/zod/common/global-apikey'
import { systemApi } from 'app/types/zod/common/system-api'
import { parsedRequest } from 'app/types/zod/utils'
import { API } from './API'

export class SystemApi {
  api: API

  constructor(api: API) {
    this.api = api
  }

  getSystemApis = parsedRequest(z.array(systemApi), () =>
    this.api.get(`app/systemapis`)
  )

  getSystemApi = parsedRequest(
    systemApiSingle,
    (id: number | string, customerId: number | string) =>
      this.api.get(`app/systemapis/${id}`, {}, true, {
        'X-Customer': customerId,
      })
  )
}
