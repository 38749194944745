import { nullable, z } from 'zod'

import { companyApi, customerTag } from 'app/types/zod/common/company'
import { externalIntegration } from 'app/types/zod/common/external-integrations'
import { globalApiKeyApi } from 'app/types/zod/common/global-apikey'
import { moNumber } from 'app/types/zod/common/mo-number'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
} from 'app/types/zod/utils'

export const zapierIntegrationApi = z
  .object({
    id: z.string().uuid(),
    created: datetimeStringWithOffset,
    api_keys: z.array(globalApiKeyApi),
    customer: companyApi,
    url: nonEmptyString,
    expiry_time: z.object({ milliseconds: integer }),
    external_integration: externalIntegration,
  })
  .describe('zapierIntegrationApi')

export const zapierIntegration = zapierIntegrationApi
  .extend({
    expiry_time: integer,
  })
  .describe('zapierIntegration')

export type ZapierIntegration = z.infer<typeof zapierIntegration>

export const zapierIntegrationDetails = z
  .object({
    id: z.string().uuid(),
    enabled: z.boolean(),
    sender: nullable(nonEmptyString),
    connected: nullable(datetimeStringWithOffset),
    customer: companyApi,
    customer_tag: z.array(customerTag),
    external_integration: externalIntegration,
    integration_name: nullableString,
    created: datetimeStringWithOffset,
  })
  .describe('zapierIntegrationDetails')

export type ZapierIntegrationDetails = z.infer<typeof zapierIntegrationDetails>

export const zapierIntegrationListItem = z
  .object({
    id: z.string().uuid(),
    enabled: z.boolean(),
    connected: z.nullable(datetimeStringWithOffset),
    sender: nullableString,
    customer: companyApi,
    customer_tag: z.array(customerTag),
    external_integration: externalIntegration,
    integration_name: nullableString,
    created: datetimeStringWithOffset,
  })
  .strict()
  .describe('zapierIntegrationListItem')

export type ZapierIntegrationListItem = z.infer<
  typeof zapierIntegrationListItem
>

export const zapierIntegrationList = z
  .array(zapierIntegrationListItem)
  .describe('zapierIntegrationList')

export const zapierIntegrationSubscription = z.object({
  id: integer,
  subscription_id: nonEmptyString,
  endpoint_type: z.enum(['MT', 'MO', 'DR']),
  endpoint_url: nonEmptyString,
  created: datetimeStringWithOffset,
  number: moNumber,
})

export type ZapierIntegrationSubscription = z.infer<
  typeof zapierIntegrationSubscription
>
