import { findIndex, isEmpty, pullAt } from 'lodash'

import {
  convertRemindFromFlow,
  convertStartTimeFromRemind,
} from 'app/service/applications/nps-survey/NpsUtils'
import { FlowType } from 'app/types/flow'

// Start of constants
export const flowMatcher = {
  MATCH_DEFAULT: 'MATCH_DEFAULT',
  DETRACTORS_MATCHER: 'MATCH_DETRACTORS',
  PASSIVES_MATCHER: 'MATCH_PASSIVES',
  PROMOTERS_MATCHERS: 'MATCH_PROMOTERS',
}

export const flowPriority = {
  HIGH: 100,
  NORMAL: 10,
  LOW: 1,
}

export const flowType = {
  ROOT: 'ROOT',
  INPUT: 'INPUT',
  REPORT: 'REPORT',
  FLOW_ITEM: 'FLOW_ITEM',
  FLOW_FUNCTION: 'FLOW_FUNCTION',
  ACTIVATION: 'ACTIVATION',
  REMIND: 'REMIND',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
}

export const functionType = {
  RECEIVE_SMS: 'RECEIVE_SMS',
  SEND_SMS: 'SEND_SMS',
  SEND_EMAIL: 'SEND_EMAIL',
  RECEIVE_EMAIL: 'RECEIVE_EMAIL',
  SEND_HTTP_REQUEST: 'SEND_HTTP_REQUEST',
  RECEIVE_HTTP_REQUEST: 'RECEIVE_HTTP_REQUEST',
}

export const statType = {
  PROMOTERS: 'PROMOTERS',
  PASSIVES: 'PASSIVES',
  DETRACTORS: 'DETRACTORS',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
  SUBSCRIBED: 'SUBSCRIBED',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
  VERIFY: 'VERIFY',
  CHECK_VERIFICATION: 'CHECK_VERIFICATION',
}

export const scheduleType = {
  SCHEDULED: 'SCHEDULED',
}

export const detail = {
  GROUP_SUBSCRIBE: 'GROUP_SUBSCRIBE',
  GROUP_UNSUBSCRIBE: 'GROUP_UNSUBSCRIBE',
  SMS_NOTIFICATION: 'SMS_NOTIFICATION',
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
  SUBSCRIBE_RESPONSE: 'SUBSCRIBE_RESPONSE',
  UNSUBSCRIBE_RESPONSE: 'UNSUBSCRIBE_RESPONSE',
  SEND_VERIFICATION: 'SEND_VERIFICATION',
  CHECK_VERIFICATION: 'CHECK_VERIFICATION',
}

export const action = {
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
}

export const receiverOptions = {
  0: 'REMIND_NOT_REPLIED',
  1: 'REMIND_REPLIED',
  2: 'REMIND_EVERYONE',
}

/* eslint-disable no-template-curly-in-string */
export const destinationSender = '${sender}'

// End of constants

// Start of flow API logic
export const toApi = (flowValues, id) => {
  return {
    flow: {
      id,
      flow_type: flowType.ROOT,
      children: flowValues,
    },
  }
}

export const getActivationMessageFlow = (id, values, sender) => {
  const flowFunction = addSendSmsFlowFunction(values, sender)
  const flowFunctionwithID = { id, ...flowFunction }
  return [flowFunctionwithID]
}

export const getRemindMessageFlow = (values, sender, begin) => {
  if (values.remind) {
    return values.remind.map((remindItem) => {
      const id = typeof remindItem.id === 'number' ? remindItem.id : undefined
      const remindData = {
        sendbatch_option: receiverOptions[remindItem.receivers],
        body: remindItem.remindbody,
        flowEventId: remindItem.flowEventId,
        sendSmsId: remindItem.sendSmsId,
      }
      return {
        flow_type: flowType.REMIND,
        id,
        flow_functions: [
          addSendSmsFlowFunction(remindData, sender, flowType.REMIND),
        ],
        flowevent_schedule: {
          id: remindItem.scheduleId,
          scheduled_datetime: convertStartTimeFromRemind(remindItem, begin),
          schedule_type: scheduleType.SCHEDULED,
        },
      }
    })
  }
  return null
}

const addSendSmsFlowFunction = (values, sender, type = flowType.ACTIVATION) => {
  if (type === flowType.ACTIVATION) {
    const sendsmsId = values.activationFunction?.sendsmsfunction?.id
    const activationData = {
      id: sendsmsId,
      body: values.body,
      msisdns: values.msisdns,
      groups: values.groups,
      batchId: values.batchId,
      sender,
    }
    return {
      flow_type: type,
      sendsmsfunction: addActivationSendSmsFunction(activationData),
    }
  } else if (type === flowType.REMIND) {
    return {
      flow_type: type,
      id: values.flowEventId,
      sendsmsfunction: {
        id: values.sendSmsId,
        use_session: true,
        sender,
        body: values.body,
        sendbatch_option: values.sendbatch_option,
      },
    }
  }

  return null
}

const addActivationSendSmsFunction = (values) => {
  let groups = []

  if (values.groups) {
    for (let i = 0; i < values.groups?.length; i++) {
      groups.push({ group: values.groups[i] })
    }
  }

  return {
    id: values.id,
    sender: values.sender,
    body: values.body,
    begin: values.begin,
    sendsms_batches: [
      {
        id: values.batchId,
        groups: groups,
        msisdns: values.msisdns,
      },
    ],
  }
}

export const addFlowChildren = (
  id,
  values,
  flowtype,
  statType,
  isChild,
  sendEmail
) => {
  const flowFunction = addFlowFunction(values, flowtype)
  let children = undefined
  if (!isEmpty(flowFunction)) {
    const flowFunctionWithId = { ...flowFunction, id }
    children = { ...flowFunctionWithId }
  }
  let data = null
  if (statType && statType !== statType.ERROR) {
    data = {
      ...addRecursiveFunction(values.survey, statType, isChild, sendEmail),
      flow_type: flowType.FLOW_FUNCTION,
      stat_type: statType,
    }
  }
  const flow = children
    ? { flow_functions: [{ ...children }], ...data }
    : { ...data }
  return flow
}

const addFlowFunction = (values, flowtype) => {
  let flowFunction = null
  let data = null
  let type = null

  if (flowtype === flowType.ACTIVATION) {
    data = addReceiveSmsFunction(values)
    type = { flow_type: flowType.ACTIVATION }
  } else if (flowtype === flowType.ERROR) {
    data = addSendErrorFunction(values)
    type = { flow_type: flowType.FLOW_FUNCTION }
  }
  flowFunction = { ...data, ...type }
  return { ...flowFunction, ...type }
}

const addReceiveSmsFunction = (values) => {
  if (values.receiveFunction) {
    const { id, destination } = values.receiveFunction
    return { receivesmsfunction: { id, destination } }
  }

  return {
    receivesmsfunction: { destination: values.destination },
  }
}

const addRecursiveFunction = (values, type, isChild, sendEmail) => {
  let matcher = null
  let priority = flowPriority.NORMAL

  if (sendEmail) {
    const data = addSendEmailFunction(values)
    const flowFunction = { ...data, ...type }
    return { ...flowFunction }
  } else {
    switch (type) {
      case statType.DETRACTORS:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.DETRACTORS_MATCHER
        break
      case statType.PASSIVES:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.PASSIVES_MATCHER
        break
      case statType.PROMOTERS:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.PROMOTERS_MATCHERS
        break
      case statType.ERROR:
        matcher = flowMatcher.MATCH_DEFAULT
        priority = flowPriority.LOW
        break
      default:
        matcher = flowMatcher.MATCH_DEFAULT
        priority = flowPriority.LOW
        break
    }
    return {
      flowevent_matchers: [{ priority, matcher }],
    }
  }
}

export const addSurveyResponseFunction = (values, sender) => {
  const { responseId, batchId, response } = values

  return {
    id: responseId,
    use_session: values.use_session || false,
    sender: sender || values.destination,
    body: response,
    enable_duplicates: values.enableDuplicates,
    sendsms_batches: [{ destination: destinationSender, id: batchId }],
  }
}

export const addSmsNotificationFunction = (smsAction) => {
  const { id, batchId, smsfrom, message } = smsAction
  let groups = []

  const msisdns = smsAction.msisdns?.map((number) => {
    return typeof number === 'string' ? { phonenumber: number } : number
  })

  if (smsAction.groups) {
    for (let i = 0; i < smsAction.groups?.length; i++) {
      groups.push({ group: smsAction.groups[i] })
    }
  }
  return {
    id,
    sender: smsfrom,
    body: message,
    sendsms_batches: [{ groups, msisdns, id: batchId }],
    use_session: smsAction.use_session || false,
  }
}

export const addSendErrorFunction = (values) => ({
  sendsmsfunction: {
    id: values.errorFlowEvent?.sendSmsFunctionId,
    use_session: false,
    sender: values.destination,
    body: values.error_message,
    sendsms_batches: [
      {
        id: values.errorFlowEvent?.smsBatchesId,
        destination: destinationSender,
      },
    ],
  },
})

export const splitEmailValue = (emails, idData = []) => {
  const previousIds = [...idData]

  return emails
    .split(',')
    .filter(Boolean)
    .map((email) => {
      const idIndex = findIndex(previousIds, (item) => item.email === email)

      if (idIndex !== -1) {
        const idItem = pullAt(previousIds, idIndex)[0]

        const id = idItem?.id

        return {
          email,
          id,
        }
      }

      return {
        email,
      }
    })
}

export const addSendEmailFunction = (values, sender) => {
  return {
    sendemailfunction: {
      id: values.id,
      sender: values.email_from,
      subject: values.email_subject,
      body: values.email_message,
      enable_duplicates: values.enableDuplicates,
      use_session: values.use_session || false,
      sendemail_batches: [
        {
          id: values.batchId,
          to: splitEmailValue(values.email_to, values.idData?.to),
          cc: values.email_cc
            ? splitEmailValue(values.email_cc, values.idData?.cc)
            : undefined,
          bcc: values.email_bcc
            ? splitEmailValue(values.email_bcc, values.idData?.bcc)
            : undefined,
        },
      ],
    },
  }
}

const getSurveyData = (flowFunctions) => {
  const result = flowFunctions.find((flowFunction) => {
    return flowFunction.detail == null
  })
  return result
    ? {
        flowEventId: result.id,
        ...result.sendsmsfunction,
        batchId: result.sendsmsfunction.sendsms_batches[0].id,
      }
    : null
}

const createActions = (flowFunctions) => {
  let actionsObj = {}
  if (flowFunctions) {
    const actions = flowFunctions.map((flowFunction) => {
      return setFlowNotification(flowFunction, flowFunction.detail)
    })
    actionsObj = actions.reduce((acc, item) => {
      if (item?.type === 'EMAIL_NOTIFICATION') {
        acc.email_action = item
      } else if (item?.type === 'SMS_NOTIFICATION') {
        acc.sms_action = item
      }
      return acc
    }, actionsObj)
  }
  return actionsObj
}

const createChildrenData = (children) => {
  if (children && children.length) {
    return children.map((nested) => {
      const responseData = getSurveyData(nested.flow_functions)
      const actionsData = createActions(nested.flow_functions)
      return {
        id: nested.id,
        isActive: true,
        response: responseData?.body,
        responseId: responseData?.id,
        batchId: responseData?.batchId,
        flowEventId: responseData?.flowEventId,
        sms_action: actionsData.sms_action,
        email_action: actionsData.email_action,
        flowevent_matchers: nested.flowevent_matchers,
      }
    })
  } else {
    return [
      {
        sms_action: undefined,
        email_action: undefined,
        isActive: false,
        response: null,
      },
    ]
  }
}

// Start of flow view logic
export const fromApi = (obj) => {
  let foundActivation = null
  const remind = []
  let errorMessage = ''
  let surveyItems = []
  let receiveMessageId = null
  let receiveMessageFlowFunctionId = null
  let errorFlowEvent = {}

  const children = obj.flow.children

  // Activation logic
  const activationMessage = children.find(({ flow_functions, flow_type }) => {
    if (flow_type === flowType.ACTIVATION) {
      return flow_functions.find(({ sendsmsfunction }) => sendsmsfunction)
    }
    return undefined
  })

  if (activationMessage) {
    const activationStartTime =
      activationMessage.flowevent_schedule?.scheduled_datetime
    const activationFunction = activationMessage.flow_functions.find(
      ({ sendsmsfunction }) => sendsmsfunction
    )
    if (activationFunction) {
      const sendData = activationFunction.sendsmsfunction?.sendsms_batches[0]
      const phoneNumbers = sendData.msisdns.map((data) => {
        return { id: data.id, phonenumber: data.phonenumber }
      })
      const groups = []
      if (sendData.groups.length > 0) {
        for (let i = 0; i < sendData.groups.length; i++) {
          groups.push(sendData.groups[i].group)
        }
      }
      foundActivation = {
        activationFunction,
        begin: activationStartTime,
        id: activationMessage.id,
        batchId: sendData.id,
        body: activationFunction.sendsmsfunction.body,
        msisdns: phoneNumbers,
        groups: groups,
        applicationevent_key: sendData.applicationevent_key,
        contacts: sendData.contacts,
        fromApiTimestamp: new Date().getTime(), // Used as a React key to trigger input component re-mount
        flowEventScheduleId: activationMessage.flowevent_schedule?.id,
      }
      // Remind logic
      activationMessage.children.forEach((remindFlow) => {
        const convertedRemind = convertRemindFromFlow(
          remindFlow,
          activationStartTime
        )
        if (convertedRemind) {
          remind.push(convertedRemind)
        }
      })
    }
  }

  // Survey logic
  const activateReceptionEvent = children.find(
    (event) =>
      event.flow_type === FlowType.ACTIVATION &&
      event.flow_functions?.find((flowFn) => !!flowFn.receivesmsfunction)
  )

  if (activateReceptionEvent) {
    receiveMessageId = activateReceptionEvent.id
    receiveMessageFlowFunctionId = activateReceptionEvent.flow_functions[0].id

    const part = activateReceptionEvent.children

    for (let j = 0; j < part.length; j++) {
      if (part[j].stat_type !== statType.ERROR) {
        const responseData = getSurveyData(part[j].flow_functions)
        const actionsData = createActions(part[j].flow_functions)
        const childrenData = createChildrenData(part[j].children)
        surveyItems[j] = {
          id: part[j].id,
          response: responseData?.body,
          responseId: responseData?.id,
          batchId: responseData?.batchId,
          flowEventId: responseData?.flowEventId,
          children: childrenData,
          sms_action: actionsData.sms_action,
          email_action: actionsData.email_action,
          destination: '',
          flowevent_matchers: part[j].flowevent_matchers,
        }
      } else if (part[j].flow_functions && part[j].flow_functions.length > 0) {
        const errorFunction = part[j].flow_functions[0]
        const errorSendSmsFunction = errorFunction?.sendsmsfunction

        errorFlowEvent = {
          id: part[j].id,
          flowFunctionId: errorFunction?.id,
          sendSmsFunctionId: errorSendSmsFunction?.id,
          smsBatchesId: errorSendSmsFunction?.sendsms_batches?.[0]?.id,
          flowEventMatchers: part[j].flowevent_matchers,
        }

        errorMessage = errorSendSmsFunction?.body
      }
    }
  }

  const status =
    activationMessage?.status === 'PENDING'
      ? activationMessage.status
      : obj.application.status

  return {
    payload: obj,
    parsed: {
      applicationEventId: obj.applicationevent_id,
      flowId: obj.flow.id,
      allow_api_usage: obj.application.allow_api_usage,
      receiveMessageId,
      receiveMessageFlowFunctionId,
      errorFlowEvent,
      remind,
      name: obj.application.name,
      systemapplication_id: obj.application.systemapplication_id,
      applicationevent_key: obj.application.applicationevent_key,
      created: obj.application.created,
      end_datetime: obj.application.end,
      start_datetime: obj.application.begin,
      api_key: obj.application.apikey,
      error_message: errorMessage,
      is_error_message: !!errorMessage,
      status,
      activation_message: foundActivation,
      survey: surveyItems,
      destination:
        activateReceptionEvent.flow_functions[0].receivesmsfunction
          ?.destination,
      receiveFunction:
        activateReceptionEvent.flow_functions[0].receivesmsfunction,
    },
  }
}

const setFlowNotification = (values, type) => {
  switch (values?.detail) {
    case detail.SMS_NOTIFICATION:
      const parsedSms =
        values?.sendsmsfunction && type === values?.detail
          ? setSmsNotification(values.sendsmsfunction, values.id)
          : undefined
      return { ...parsedSms, type }
    case detail.EMAIL_NOTIFICATION:
      const parsedEmail =
        values?.sendemailfunction && type === values?.detail
          ? setEmailNotification(values.sendemailfunction, values.id)
          : undefined
      return { ...parsedEmail, type }
    default:
      return undefined
  }
}

const setSmsNotification = (values, parentFunctionId) => {
  const groups = []
  const msisdns = []

  const { sendsms_batches, sender, id, body } = values

  if (sendsms_batches?.length) {
    const sendData = values.sendsms_batches[0]
    const batchId = sendData.id
    if (sendData.groups?.length) {
      for (let i = 0; i < sendData.groups.length; i++) {
        groups.push(sendData.groups[i].group)
      }
    }
    if (sendData.msisdns?.length) {
      for (let i = 0; i < sendData.msisdns.length; i++) {
        msisdns.push({
          id: sendData.msisdns[i].id,
          phonenumber: sendData.msisdns[i].phonenumber,
        })
      }
    }
    const smsfrom = sender === '$(SMS sender)' ? destinationSender : sender
    return {
      id,
      batchId,
      smsfrom,
      msisdns,
      groups,
      message: body || '',
      notificationgroup: false,
      parentFunctionId,
    }
  }
  return undefined
}

const setEmailNotification = (values, parentFunctionId) => {
  let to = ''
  let idData = {
    to: [],
    cc: [],
    bcc: [],
  }
  let cc = ''
  let bcc = ''
  let batchId = undefined

  if (values.sendemail_batches?.length > 0) {
    batchId = values.sendemail_batches[0].id
    const emailData = values.sendemail_batches[0]
    if (emailData.to?.length > 0) {
      for (let i = 0; i < emailData.to.length; i++) {
        to += emailData.to[i].email + ','
        idData.to.push(emailData.to[i])
      }
    }
    if (emailData.cc?.length > 0) {
      for (let i = 0; i < emailData.cc.length; i++) {
        cc += emailData.cc[i].email + ','
        idData.cc.push(emailData.cc[i])
      }
    }
    if (emailData.bcc?.length > 0) {
      for (let i = 0; i < emailData.bcc.length; i++) {
        bcc += emailData.bcc[i].email + ','
        idData.bcc.push(emailData.bcc[i])
      }
    }
  }
  return {
    id: values.id,
    idData,
    batchId,
    parentFunctionId,
    email_from: values.sender || '',
    email_to: to,
    email_cc: cc,
    email_bcc: bcc,
    email_subject: values.subject || '',
    email_message: values.body || '',
    email_to_notification: false, // not used in flow
    email_cc_notification: false, // not used in flow
    email_bcc_notification: false, // not used in flow
  }
}

// End of flow view logic
