import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Customers initial state
 */
export const customersState = {
  customer: {},
  applications: {},
  errors: {
    customer: '',
  },
}

/**
 * System customers reducer
 */
export default handleActions(
  {
    SYSTEM_CUSTOMER_APPLICATIONS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        applications: extend({}, state.applications, payload || {}),
      })
    },
    CUSTOMER_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        customer: extend({}, state.customer, payload || {}),
        errors: {
          ...state.errors,
          customer: '',
        },
      })
    },
    CUSTOMER_RELOAD_FAIL: (state, { payload }) => {
      const statusCode = payload?.response?.status
      return extend({}, state, {
        errors: {
          ...state.errors,
          customer: statusCode === 404 ? 'NOT_FOUND' : 'ERROR',
        },
      })
    },
    CUSTOMER_RESET: (state) => {
      return extend({}, state, {
        customer: {},
      })
    },
    CUSTOMER_ERROR_RESET: (state) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          customer: '',
        },
      }
    },
  },
  customersState
)
