import {
  createActionSaga,
  createFormSaga,
  customer,
  message,
  moNumber,
} from '../../api'
import { actionSuccess } from '../../common/action'

export const systemMoNumbers = 'system-mo-numbers'
export const customerMoNumbers = 'customer-mo-numbers'
export const moNumberCustomers = 'mo-number-customers'
export const moNumberDelete = 'mo-number-delete'
export const moNumberReload = 'mo-number-reload'
export const moNumberSave = 'mo-number-save'

const { deleteMoNumber, getMoNumbers, getMoNumber, saveMoNumber } = moNumber

const { getNumbers } = message

const { getCustomers } = customer

const moNumbers = [
  createActionSaga(systemMoNumbers, getMoNumbers),
  createActionSaga(customerMoNumbers, getNumbers),
  createActionSaga(moNumberCustomers, getCustomers, { busy: true }),
  createActionSaga(moNumberDelete, deleteMoNumber),
  createActionSaga(moNumberReload, getMoNumber, { busy: true }),
  createFormSaga(moNumberSave, saveMoNumber, {
    message: 'Mo number saved',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/system/mo-numbers/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(moNumberReload, result)
    },
  }),
]

export default moNumbers
