import { createFormSaga, user } from '../../api'

export const confirmAccountInvitation = 'confirm-account-invitation'

const { activateUserAccount } = user

const userAccountConfirmation = [
  createFormSaga(confirmAccountInvitation, activateUserAccount, {
    message: 'Account activated',
    redirect: '/account-activation/done',
  }),
]

export default userAccountConfirmation
