import { put, select, take, takeLatest } from 'redux-saga/effects'

import { appBusy } from '../actions'
import { createActionSaga, createFormSaga, user } from '../api'
import { senderList, userInfo } from '../auth/sagas'
import {
  ACTION_COMPLETE,
  actionComplete,
  actionName,
  actionPut,
} from '../common/action'
import { actionSuccess } from '../common/action'

export const profileReload = 'profile-reload'
export const profileUpdate = 'profile-update'
export const profilePasswordChange = 'profile-password-change'

const { updatePassword, updateProfile, getInfo } = user

/**
 * Handle profile reload
 */
export function* handleReload() {
  yield put(appBusy(true))
  yield actionPut(userInfo)
  yield take(actionName(userInfo, ACTION_COMPLETE))
  // Get current customer and set senderlist
  const customer = yield select(({ auth }) => auth.customer),
    active = customer.active || {}
  yield actionPut(senderList, { customerId: active.id })
  yield actionComplete(profileReload)
  yield put(appBusy(false))
}

/**
 * Watch profile reload
 */
export function* watchReload() {
  yield takeLatest(actionName(profileReload), handleReload)
}

const profile = [
  watchReload,
  createActionSaga(profileReload, getInfo, {
    busy: true,
  }),
  createFormSaga(profileUpdate, updateProfile, {
    message: 'Profile saved',
    args: (payload) => {
      return ({ auth: { customer, senderList } }) => {
        return {
          ...payload,
          customer,
          senderList,
        }
      }
    },
    success: function* (result) {
      yield actionSuccess(profileReload, result)
      yield actionPut(profileReload, result)
      yield take(actionName(profileReload, ACTION_COMPLETE))
    },
  }),
  createFormSaga(profilePasswordChange, updatePassword, {
    message: 'Password successfully updated',

    success: function* (result) {
      yield actionSuccess(profileReload, result)
      yield actionPut(profileReload, result)
      yield take(actionName(profileReload, ACTION_COMPLETE))
    },
  }),
]

export default profile
