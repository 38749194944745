import moment from 'moment'

import { bind } from '../common/utils'
import { getPageNumber } from './'
// import { TOKEN_MAX_AGE } from './API';
// import { TOKEN_SEPARATOR } from './API';

export const roles = {
  user: 'User',
  admin: 'Admin',
  systemAdmin: 'System admin',
}

/**
 * User API
 */
export class SettingsUser {
  constructor(api) {
    this.api = api
    bind(this, [
      'getAccount',
      'getAccounts',
      'getCompanies',
      'getAssignedCompanies',
      'getUnassignedCompanies',
      'saveAccount',
      'deleteAccount',
    ])
  }

  /**
   * Delete an account
   */
  deleteAccount({ id, customerId }) {
    return this.api
      .delete('service/users/' + id, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Get single account by ID
   */
  getAccount({ id, customerId }) {
    return this.api
      .get('service/users/' + id, {}, true, {
        'X-Customer': customerId,
      })
      .then(async (result) => {
        const role = result.type.toLowerCase()
        return {
          id: result.id + '',
          username: result.user.username,
          firstName: result.user.first_name,
          lastName: result.user.last_name,
          mobilePhone: result.msisdn || '',
          is_active: result.user.is_active,
          language: result.language,
          role: role === 'sysadmin' ? 'systemAdmin' : role,
          created: moment(result.user.date_joined).toDate(),
          companies: [],
          userId: result.user.id + '',
          invitation_expires: result?.invitation_expires,
        }
      })
  }

  /**
   * Get accounts
   */
  async getAccounts(options = {}) {
    const users = await this.api
      .get('service/users?page=' + getPageNumber(options), {}, true, {
        'X-Customer': options.extra.customerId,
        ...this.api.withFilters(options, {
          username: 'username',
          firstName: 'first_name',
          lastName: 'last_name',
          role: 'type',
          is_active: 'is_active',
          mobilePhone: 'msisdn',
          created: 'date_joined',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })

    const results = await Promise.all(
      users.results.map(async (result) => {
        const role = result.type.toLowerCase()
        return {
          id: result.id + '',
          username: result.user.username,
          firstName: result.user.first_name,
          lastName: result.user.last_name,
          mobilePhone: result.msisdn,
          language: result.language,
          role: role === 'sysadmin' ? 'systemAdmin' : role,
          created: moment(result.user.date_joined).toDate(),
          companies: [],
          userId: result.user.id,
          is_active: result.user.is_active,
          invitation_expires: result?.invitation_expires,
        }
      })
    )

    return {
      ...users,
      results,
    }
  }

  getCompanies(customerId, options = {}) {
    let newOptions = {
      ...options,
      search: {
        ...options.search,
        user: options.extra.user,
      },
    }

    return this.api
      .get(
        `service/users/customers?page=` + getPageNumber(newOptions),
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(newOptions, {
            name: 'name',
            vatCode: 'vatcode',
            externalId: 'extid',
            status: 'status',
            demoStatus: 'demo',
            assigned: 'assignment',
            created: 'created',
            user: 'userid',
          }),
        }
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              name: result.name,
              vatCode: result.vatcode,
              status: result.status.toLowerCase(),
              created: new Date(result.created),
              assignment: result.assignment,
            }
          }),
        }
      })
  }

  getAssignedCompanies(options = {}) {
    return this.getCompanies({
      ...options,
      search: {
        ...options.search,
        assigned: 'ASSIGNED',
      },
    })
  }

  getUnassignedCompanies(options = {}) {
    return this.getCompanies({
      ...options,
      search: {
        ...options.search,
        assigned: 'AVAILABLE',
      },
    })
  }

  /**
   * Save account
   */
  saveAccount({ account, customerId }) {
    const headers = { 'X-Customer': customerId }

    const role = {
      user: 'USER',
      admin: 'ADMIN',
    }[account.role]
    if (account.userId) {
      return this.api
        .put(
          'service/users/' + account.userId,
          {
            id: parseInt(account.userId),
            msisdn: account.mobilePhone,
            type: role,
            language: account.language,
            user: {
              id: parseInt(account.userId),
              username: account.username,
              email: account.username,
              is_active: account.is_active,
              first_name: account.firstName,
              last_name: account.lastName,
            },
          },
          true,
          headers
        )
        .then((result) => {
          return {
            id: result.id + '',
            firstName: result.user.first_name,
            lastName: result.user.last_name,
            is_active: result.user.is_active,
            userId: result.user.id + '',
          }
        })
    } else {
      return this.api
        .post(
          'service/users',
          {
            id: 0,
            msisdn: account.mobilePhone,
            type: role,
            language: account.language,
            user: {
              id: 0,
              password: account.password,
              username: account.username,
              email: account.username,
              is_active: account.is_active,
              first_name: account.firstName,
              last_name: account.lastName,
            },
          },
          true,
          headers
        )
        .then((result) => {
          return {
            id: result.id + '',
            username: result.user.username,
            email: result.user.username,
            firstName: result.user.first_name,
            lastName: result.user.last_name,
            mobilePhone: result.msisdn,
            language: result.language,
            role: role === 'sysadmin' ? 'systemAdmin' : role.toLowerCase(),
            new: true,
            is_active: result.user.is_active,
            userId: result.user.id + '',
          }
        })
    }
  }

  sendInvitation(id) {
    return this.api.get(`service/users/${id}/invite`).then(({ expires }) => ({
      expires,
    }))
  }
}
