import { z } from 'zod'

import { destinationSender } from 'app/api/FlowAdapter'
import { DestinationSenderType, FlowAction, FlowStatus } from 'app/types/flow'
import { groupApi } from 'app/types/zod/common/group'
import {
  flowFunctionPartialFields,
  flowFunctionResponse,
} from 'app/types/zod/flow/flow-function'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  phoneNumber,
} from 'app/types/zod/utils'

const sendSmsBatchResponse = z.object({
  id: integer,
  created: datetimeStringWithOffset,
  applicationevent_id: integer,
  applicationevent_key: nonEmptyString,
  status: z.nativeEnum(FlowStatus),
  recipient_count: integer,
  groups: z.array(groupApi),
  msisdns: z.array(
    z.object({
      phonenumber: phoneNumber,
    })
  ),
  destination: z.nullable(z.literal(destinationSender)),
})

const sendSmsBatch = sendSmsBatchResponse.partial({
  id: true,
  created: true,
  applicationevent_id: true,
  applicationevent_key: true,
  status: true,
  recipient_count: true,
})

const sendsmsfunctionResponse = z.object({
  id: integer,
  sender: nonEmptyString,
  body: nonEmptyString,
  use_session: z.literal(true),
  enable_duplicates: z.boolean(),
  sendsms_batches: z.array(sendSmsBatchResponse).min(1),
})

const sendsmsfunction = sendsmsfunctionResponse
  .partial({
    id: true,
  })
  .extend({
    sender: z.string(),
    body: z.string(),
    sendsms_batches: z.array(sendSmsBatch).min(1),
  })

export const sendSmsFlowFunctionResponse = flowFunctionResponse.extend({
  sendsmsfunction: sendsmsfunctionResponse,
})

export const sendSmsFlowFunction = sendSmsFlowFunctionResponse
  .partial(flowFunctionPartialFields)
  .extend({
    sendsmsfunction,
  })

export type SendSmsFunction = z.infer<typeof sendsmsfunction>

export type SendSmsFlowFunction = z.infer<typeof sendSmsFlowFunction>

const receivesmsfunctionResponse = z.object({
  id: integer,
  destination: nonEmptyString,
  destinationType: z.nativeEnum(DestinationSenderType),
  created: datetimeStringWithOffset,
})

const receivesmsfunction = receivesmsfunctionResponse
  .partial({
    id: true,
    destinationType: true,
    created: true,
  })
  .extend({
    destination: z.string(),
  })

export const receiveSmsFlowFunctionResponse = flowFunctionResponse.extend({
  receivesmsfunction: receivesmsfunctionResponse,
})

export const receiveSmsFlowFunction = receiveSmsFlowFunctionResponse
  .partial(flowFunctionPartialFields)
  .extend({
    receivesmsfunction,
  })

export type ReceiveSmsFunction = z.infer<typeof receivesmsfunction>

export type ReceiveSmsFlowFunction = z.infer<typeof receiveSmsFlowFunction>

const subscriptionfunctionResponse = z.object({
  id: integer,
  action: z.nativeEnum(FlowAction),
  personalization: z.array(nonEmptyString),
  groups: z.array(groupApi),
  created: datetimeStringWithOffset,
})

export const subscriptionfunction = subscriptionfunctionResponse.partial({
  id: true,
  groups: true,
  created: true,
})

export const subscriptionFlowFunctionResponse = flowFunctionResponse.extend({
  subscriptionfunction: subscriptionfunctionResponse,
})

export const subscriptionFlowFunction = subscriptionFlowFunctionResponse
  .partial(flowFunctionPartialFields)
  .extend({ subscriptionfunction })

export type SubscriptionFunction = z.infer<typeof subscriptionfunction>

export type SubscriptionFlowFunction = z.infer<typeof subscriptionFlowFunction>
