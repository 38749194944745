import { snakeCase, toUpper } from 'lodash'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import { sagaMiddleware } from '../store/sagas'

export const ACTION_COMPLETE = '_COMPLETE'
export const ACTION_FAIL = '_FAIL'
export const ACTION_SUCCESS = '_SUCCESS'

/**
 * Action utils
 */
export function actionCall(dispatch, action, payload = {}) {
  dispatch(actionCreate(action, payload))
}

/**
 * Action complete
 */
export function* actionComplete(action, payload = {}) {
  yield put({
    type: actionName(action, ACTION_COMPLETE),
    payload,
  })
}

/**
 * Action create
 */
export function actionCreate(action, payload = {}, suffix = '') {
  return {
    type: actionName(action, suffix),
    payload,
  }
}

/**
 * Action fail
 */
export function* actionFail(action, payload = {}) {
  yield put({
    type: actionName(action, ACTION_FAIL),
    payload,
  })
  yield actionComplete(action)
}

/**
 * Action name
 */
export function actionName(action, suffix = '') {
  return toUpper(snakeCase(action)) + suffix
}

/**
 * Action put
 */
export function* actionPut(action, payload = {}) {
  yield put(actionCreate(action, payload))
}

/**
 * Action success
 */
export function* actionSuccess(action, payload = {}) {
  yield put({
    type: actionName(action, ACTION_SUCCESS),
    payload,
  })
  yield actionComplete(action)
}

/**
 * Watch actions
 */
export function* watchActions(actions, listener) {
  yield all(
    actions.map((action) => {
      return fork(function* watchAction() {
        yield takeEvery(action, listener)
      })
    })
  )
}

/**
 * Watch
 */
export function watch(actions, listener) {
  return sagaMiddleware.run(watchActions, actions, listener)
}
