import { bind } from '../common/utils'
import { getPageNumber } from './'

export class NewsFeed {
  constructor(api) {
    this.api = api
    bind(this, [
      'getNewsFeeds',
      'getNewsFeed',
      'getArticle',
      'deleteArticle',
      'saveArticle',
    ])
  }

  getNewsFeeds(request = {}) {
    const options = {
      ...request,
      search: {
        ...request.search,
      },
    }
    return this.api
      .get('mgmt/newsfeed?page=' + getPageNumber(options), {}, true, {
        ...this.api.withFilters(options, {
          status: 'published',
          title: 'title',
          description: 'description',
          created: 'created',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results: results
            .map((result) => {
              const finnishArticle = result.translations.find(
                (translation) => translation.language === 'fi'
              )

              return finnishArticle
                ? {
                    status: result.published,
                    created: result.created,
                    id: result.id,
                    title: finnishArticle.title,
                    description: finnishArticle.description,
                    content: finnishArticle.text_body,
                  }
                : null
            })
            .filter((el) => el),
        }
      })
  }

  getNewsFeed(request) {
    const { customerId } = request
    return this.api
      .get('service/newsfeed', {}, true, {
        'X-Customer': customerId,
      })
      .then((results) => {
        return { data: results }
      })
      .catch((error) => {
        return Promise.reject('No news')
      })
  }

  getArticle(request) {
    return this.api.get(`mgmt/newsfeed/${request.id}`, {}, true, {})
  }

  deleteArticle({ id }) {
    return this.api.delete(`mgmt/newsfeed/${id}`).then(() => {
      return {
        success: true,
      }
    })
  }

  async saveArticle(article) {
    if (article.action === 'add') {
      return await this.api.post('mgmt/newsfeed', { ...article })
    } else {
      return await this.api.put('mgmt/newsfeed/' + article.action, {
        ...article,
      })
    }
  }
}
