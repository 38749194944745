import { combineReducers } from 'redux'

import accounts from './accounts/reducer'
import billing from './billing/reducer'
import customers from './customers/reducer'
import moNumbers from './mo-numbers/reducer'
import newsfeed from './news-feed/reducer'
import policies from './policies/reducer'
import routing from './routing/reducer'
import ssoconnection from './sso/reducer'

export default combineReducers({
  accounts,
  billing,
  customers,
  moNumbers,
  policies,
  routing,
  ssoconnection,
  newsfeed,
})
