import { EnumLike, z } from 'zod'

import { isMobile, requiredMsg } from 'app/common/utils'
import { TableRequestOptions, TableResponse } from '../app'
import { FlowStatus } from '../flow'

const requiredOptions = {
  required_error: requiredMsg,
}

const required = { message: requiredMsg }
const invalidValue = { message: 'Invalid value' }

export const nonEmptyString = z
  .string(requiredOptions)
  .min(1, { message: requiredMsg })

export const optionalString = z.optional(z.string())

export const nullableString = z.nullable(z.string())

export const urlString = z.string(requiredOptions).url().max(200)
export const optionalUrl = z.optional(urlString).or(z.literal(''))

export const phoneNumber = z.string().refine((val) => isMobile(val), {
  message: 'Invalid phone number',
})

export const integer = z.number().int()

export const hexColor = z
  .string()
  .regex(/^#[0-9A-Fa-f]{6,6}$/, { message: 'Invalid hex value' })

export const requiredNumber = z.number({
  ...requiredOptions,
  invalid_type_error: 'Please input a number',
})
export const optionalInteger = z.optional(z.number().int())

export const datetimeStringWithOffset = z
  .string(requiredOptions)
  .datetime({ offset: true })

export function requiredEnum<T extends EnumLike>(item: T): z.ZodNativeEnum<T> {
  return z.nativeEnum(item, {
    errorMap: (issue, ctx) => {
      if (!ctx.data) {
        return required
      }
      return invalidValue
    },
  })
}

export const editorId = nonEmptyString

export const withEditorId = z.object({
  editorId,
})

export type EditorId = z.infer<typeof editorId>

export type WithEditorId = z.infer<typeof withEditorId>

export type EditorIdHandler = (editorId: EditorId) => void

export const apiEntityFields = z.object({
  id: z.number(),
  created: datetimeStringWithOffset,
})

export const apiEntityFieldsWithStatus = z.object({
  id: z.number(),
  created: datetimeStringWithOffset,
  status: z.nativeEnum(FlowStatus),
})

export function parsedTableRequest<T extends z.Schema>(
  schema: T,
  fn: (options: TableRequestOptions) => Promise<TableResponse<any>>
) {
  return async function (
    options: TableRequestOptions
  ): Promise<TableResponse<z.infer<T>>> {
    const response = await fn(options)

    try {
      const results = z.array(schema).parse(response.results)
      return {
        ...response,
        results,
      }
    } catch (error) {
      console.error(schema.description, error)
      return response as TableResponse<z.infer<T>>
    }
  }
}

export function parsedRequest<T extends z.Schema, P extends any[]>(
  schema: T,
  fn: (...args: P) => Promise<any>,
  defaultValue?: z.infer<T>
) {
  return async function (...args: P): Promise<z.infer<T>> {
    const response = await fn(...args)

    try {
      const parsed = schema.parse(response)
      return parsed
    } catch (error) {
      console.error(schema.description, error)
      return defaultValue || (response as z.infer<T>)
    }
  }
}
