import { z } from 'zod'

import { externalIntegration } from 'app/types/zod/common/external-integrations'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  optionalString,
} from '../../utils'
import { SystemApiSource } from '../Enums'
import { systemApi } from '../system-api'
import { systemApplication } from '../system-application'

export const globalApiKey = z.object({
  id: integer,
  name: nonEmptyString,
  apikey: nonEmptyString,
  allowed_app: z.array(
    z.object({
      id: integer,
    })
  ),
  allowed_api: z.array(
    z.object({
      id: integer,
    })
  ),
  allowed_src: z.array(SystemApiSource),
  allowed_int: z.array(externalIntegration),
  status: z.enum(['ACTIVE', 'PASSIVE']),
  description: optionalString,
  created: datetimeStringWithOffset,
  expires: z.nullable(datetimeStringWithOffset.or(z.literal(''))),
})

export const globalApiKeyApi = globalApiKey
  .extend({
    allowed_app: z.array(
      systemApplication.omit({
        created: true,
        applicationevent_key: true,
      })
    ),
    allowed_api: z.array(systemApi),
    allowed_int: z.array(externalIntegration),
  })
  .strict()
  .describe('globalApiKeyApi')

// This schema is in this file (instead of ./SystemApi) to
// fix an error resulting from some sort of circular dependency
// between these two files
export const systemApiSingle = systemApi
  .extend({
    global_apikeys: z.array(globalApiKeyApi),
  })
  .describe('systemApiSingle')

export type GlobalApiKey = z.infer<typeof globalApiKey>

export type GlobalApiKeyApi = z.infer<typeof globalApiKeyApi>
