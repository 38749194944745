import { extend, pick } from 'lodash'
import { race, take, takeEvery } from 'redux-saga/effects'

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  actionFail,
  actionName,
  actionPut,
  actionSuccess,
} from '../action'
import { tableQuery } from './Table'

/**
 * Handle query
 */
export function* handleQuery({ payload }) {
  const { action, page, limit } = payload
  const options = extend(
    {
      offset: (page - 1) * limit,
      limit,
    },
    pick(payload, ['extra', 'order', 'search', 'sort'])
  )
  yield actionPut(action, options)
  const [success, fail] = yield race([
    take(actionName(action, ACTION_SUCCESS)),
    take(actionName(action, ACTION_FAIL)),
  ])
  if (success) {
    yield actionSuccess(tableQuery, success.payload)
  } else if (fail) {
    yield actionFail(tableQuery, fail.payload)
  }
}

/**
 * Watch query
 */
export function* watchQuery() {
  yield takeEvery(actionName(tableQuery), handleQuery)
}

const table = [watchQuery]

export default table
