import React, { useState } from 'react'
import { Dropdown, Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { groupBy, sortBy } from 'lodash'

import { rawCountryCodes } from 'app/countryCodes/rawCountryCodes'
import AutoComplete, { AutoCompleteItem } from '../autocomplete/Autocomplete'

import './CountryCodeSelect.scss'

interface CountryCodeSelectProps {
  allowAll?: boolean
  className?: string
  clearOption?: boolean
  defaultValue?: string
  errors?: string | string[]
  onSelect: (code: string) => void
  value?: string
  prepend?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const CountryCodeSelect = ({
  allowAll = false,
  className,
  clearOption = false,
  defaultValue = '',
  errors,
  value,
  onSelect,
  prepend,
  disabled = false,
}: CountryCodeSelectProps) => {
  const { t } = useTranslation()
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue)

  const countryCodes = groupBy(
    sortBy(rawCountryCodes, (item) => t(item.name.toLowerCase())),
    'countryCode'
  )

  const countryCodeItems: AutoCompleteItem<string>[] = Object.entries(
    countryCodes
  ).map(([id, items]) => ({
    id,
    name: `${id}: ${items.map((item) => t(item.name)).join(', ')}`,
  }))

  if (allowAll) {
    countryCodeItems.push({
      id: 'ALL',
      name: t('All countries'),
      prependItem: true,
    })
  }

  const selectedName =
    countryCodeItems.find(
      (item) => item.id === (value === undefined ? uncontrolledValue : value)
    )?.name || ''

  const handleSelect = (code?: string) => {
    if (!code) {
      code = ''
    }
    if (value === undefined) {
      setUncontrolledValue(code)
    }
    onSelect(code)
  }

  return (
    <>
      <Dropdown className={clsx('CountryCodeSelect', className)}>
        <Dropdown.Toggle
          data-cy="country-code-toggle"
          as={CountryCodeToggle}
          id="country-code-toggle"
          prepend={prepend}
          disabled={disabled}
        >
          {selectedName || `- ${t('Select country code')} -`}
        </Dropdown.Toggle>
        <Dropdown.Menu className="customers">
          <AutoComplete
            suggestions={countryCodeItems}
            onSelect={handleSelect}
            clearOption={!!selectedName && clearOption}
          />
        </Dropdown.Menu>
      </Dropdown>
      {errors && <span className="text-danger">{errors}</span>}
    </>
  )
}

const CountryCodeToggle = React.forwardRef<
  HTMLInputElement,
  {
    children: string
    onClick: React.MouseEventHandler<HTMLElement>
    prepend?: boolean
    disabled?: boolean
  }
>(({ onClick, children, prepend = true, disabled = false }, ref) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!disabled) {
      onClick(e)
    }
  }

  return (
    <InputGroup ref={ref}>
      {prepend && (
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="qf qf-map"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
      )}
      <Form.Control
        className={clsx(disabled && 'disabled')}
        onClick={handleClick}
        value={children}
        disabled={disabled}
        readOnly
      />
      <InputGroup.Append
        onClick={handleClick}
        className={clsx(disabled && 'disabled')}
      >
        <InputGroup.Text>
          <i className="fa fa-angle-down"></i>
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  )
})

export default CountryCodeSelect
