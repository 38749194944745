import { z } from 'zod'

import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from 'app/types/zod/utils'

export const moNumber = z.object({
  number: nonEmptyString,
  customer_description: z.string(),
  customer_id: integer,
  numbertype: z.enum(['LONG_CODE', 'SHORT_CODE']),
  created: datetimeStringWithOffset,
  hasbilling: z.boolean(),
  routeruleinfo: z.array(
    z.object({
      customer_id: integer,
      id: integer,
      info: nonEmptyString,
    })
  ),
})

export type MoNumber = z.infer<typeof moNumber>
