import { nullable, z } from 'zod'

import { CleanupAction, CleanupRoutineStatus } from 'app/types/cleanup'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from 'app/types/zod/utils'

export const pendingCleanup = z
  .object({
    id: integer,
    name: nonEmptyString,
    action: z.nativeEnum(CleanupAction),
    data_object: nonEmptyString,
    status: z.nativeEnum(CleanupRoutineStatus),
    total_amount: nullable(integer),
    processed_amount: nullable(integer),
    statustime: nullable(datetimeStringWithOffset),
  })
  .describe('pendingCleanup')

export const pendingCleanupList = z
  .array(pendingCleanup)
  .describe('pendingCleanupArray')

export type PendingCleanup = z.infer<typeof pendingCleanup>

export type PendingJobData = Pick<
  PendingCleanup,
  'data_object' | 'processed_amount' | 'total_amount'
>
