import { z } from 'zod'

import { Detail, FlowAction, FlowStatus, FlowType } from '../../../flow'
import {
  apiEntityFieldsWithStatus,
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from '../../utils'

export const flowFunctionBase = z.object({
  id: z.optional(z.number()),
  action: z.optional(z.nativeEnum(FlowAction)),
  detail: z.nullable(z.nativeEnum(Detail)),
  flow_type: z.enum([FlowType.FLOW_FUNCTION, FlowType.ACTIVATION]),
  // This is a frontend field used in Group Subscription and Verify
  reference: z.optional(nonEmptyString),
})

export const flowFunctionApiBase = flowFunctionBase
  .merge(apiEntityFieldsWithStatus)
  .strict()

// v2

// These are updated versions of flow data schemas. We'll leave
// the previous schemas untouched so that there's no need to worry about
// Group Subscription, Verify and RCS
export const flowFunctionPartialFields: Record<string, true> = {
  id: true,
  created: true,
  status: true,
  customerrouterule_id: true,
  order: true,
}

export const flowFunctionResponse = z
  .object({
    id: integer,
    customerrouterule_id: z.nullable(integer),
    created: datetimeStringWithOffset,
    detail: z.nullable(z.nativeEnum(Detail)),
    flow_type: z.nativeEnum(FlowType),
    order: integer,
    status: z.nativeEnum(FlowStatus),
  })
  .strict()
  .describe('flowFunctionResponse')
