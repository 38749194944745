import * as faker from 'faker'
import { random, range, upperFirst } from 'lodash'
import moment from 'moment'

import { bind, mapKeyValues } from '../common/utils'
import { getPageNumber } from '.'

export const types = {
  oidc: 'OIDC',
}

export const statuses = {
  active: 'Active',
  disabled: 'Disabled',
}

export const responseModes = {
  fragment: 'Fragment',
  form_post: 'Form post',
}

export const signAlgos = {
  RS256: 'RS256',
}

/**
 * Sso connection API
 */
export class SsoConnection {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteSsoConnection',
      'getAllSsoConnections',
      'getSsoConnection',
      'getSsoConnections',
      'saveSsoConnection',
      'getSsoKey',
    ])
  }

  /**
   * Delete sso connection
   */
  deleteSsoConnection({ id }) {
    return this.api.delete(`mgmt/ssoconnections/${id}`).then(() => {
      return {
        success: true,
      }
    })
  }

  /**
   * Get all sso connection
   */
  async getAllSsoConnections() {
    const { results } = await this.getSsoConnections()
    return mapKeyValues(
      results.map(({ id, name }) => ({ id, name })),
      'id',
      'name'
    )
  }

  /**
   * Get sso connection
   */
  getSsoConnection({ id }) {
    return this.api.get(`mgmt/ssoconnections/${id}`).then((result) => {
      return {
        id: result.id,
        key: result.key,
        name: result.name,
        connection_type: result.connection_type,
        connection_status: result.connection_status,
        default_customer: result.default_customer,
        default_policygroup: result.default_policygroup,
        confirm_user: result.confirm_user,
        description: result.description,
        created: moment(result.created),
        oidcsetting: {
          id: result.oidcsetting.id,
          client_id: result.oidcsetting.client_id,
          client_secret: result.oidcsetting.client_secret,
          scope: result.oidcsetting.scope,
          response_type: result.oidcsetting.response_type,
          response_mode: result.oidcsetting.response_mode,
          configuration: result.oidcsetting.configuration,
          sign_algo: result.oidcsetting.sign_algo,
          sign_key: result.oidcsetting.sign_key,
          verify_tsl: result.oidcsetting.verify_tsl,
          timeout: result.oidcsetting.timeout,
          proxy: result.oidcsetting.proxy,
        },
      }
    })
  }

  /**
   * Get sso connections
   */
  getSsoConnections(options = {}) {
    return this.api
      .get(
        'mgmt/ssoconnections?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          key: 'key',
          name: 'name',
          connection_type: 'connection_type',
          connection_status: 'connection_status',
          description: 'description',
          created: 'created',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  /**
   * Get mock sso connections
   */
  getMockSsoConnections() {
    if (!this.ssoconnections) {
      this.ssoconnections = [
        ...range(10).map((i) => {
          return {
            id: i + 1 + '',
            name: upperFirst(faker.lorem.words(random(2, 3))),
            description: faker.lorem.sentence(),
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastSsoConnectionId = this.ssoconnections.length
    }
    return this.ssoconnections
  }

  /**
   * Save sso connection
   */
  saveSsoConnection(ssoconnection) {
    const data = {
      id: ssoconnection.id,
      key: ssoconnection.key,
      name: ssoconnection.name,
      connection_type: ssoconnection.connection_type,
      connection_status: ssoconnection.connection_status,
      default_customer: parseInt(ssoconnection.default_customer),
      default_policygroup: parseInt(ssoconnection.default_policygroup),
      confirm_user: ssoconnection.confirm_user,
      description: ssoconnection.description,
      oidcsetting: {
        id: ssoconnection.oidcsetting.id,
        client_id: ssoconnection.oidcsetting.client_id,
        client_secret: ssoconnection.oidcsetting.client_secret,
        scope: ssoconnection.oidcsetting.scope,
        response_type: ssoconnection.oidcsetting.response_type,
        response_mode: ssoconnection.oidcsetting.response_mode,
        configuration: ssoconnection.oidcsetting.configuration,
        sign_algo: ssoconnection.oidcsetting.sign_algo,
        sign_key: ssoconnection.oidcsetting.sign_key,
        verify_tsl: ssoconnection.oidcsetting.verify_tsl,
        timeout: ssoconnection.oidcsetting.timeout,
        proxy: ssoconnection.oidcsetting.proxy,
      },
    }
    if (!ssoconnection.id) {
      return this.api.post('mgmt/ssoconnections', data).then((result) => ({
        ...result,
        isnew: true,
      }))
    } else {
      return this.api
        .put(`mgmt/ssoconnections/${ssoconnection.id}`, data)
        .then((result) => ({
          ...result,
          isnew: false,
        }))
    }
  }

  /**
   * Get sso key
   */
  getSsoKey() {
    return this.api.get('mgmt/ssoconnections/getkey/').then(({ ssokey }) => {
      return ssokey
    })
  }
}
