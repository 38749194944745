import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Settings accounts initial state
 */
export const settingsAccountsState = {
  user: {},
}

/**
 * Settings accounts reducer
 */
export default handleActions(
  {
    SETTINGS_ACCOUNT_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        user: extend({}, state.user, payload || {}),
      })
    },
    SETTINGS_ACCOUNT_RESET: (state) => {
      return extend({}, state, {
        user: {},
      })
    },
  },
  settingsAccountsState
)
