import { z } from 'zod'

import { Media, media } from '../../common/media'
import { editorId, urlString } from '../../utils'
import { rcsMediaFile, rcsRichCardMedia } from '../content/media'
import { editorSuggestions } from './suggestions'

export const rcsMediaFileContent = urlString.or(media)

export const rcsEditorMediaFile = rcsMediaFile.extend({
  thumbnail: z.optional(rcsMediaFileContent),
  url: rcsMediaFileContent,
})

export const rcsEditorRichCardMedia = rcsRichCardMedia.extend({
  file: rcsEditorMediaFile,
})

export const rcsMediaNode = z.object({
  editorId,
  file: rcsEditorMediaFile,
  suggestions: editorSuggestions,
})

export type RcsMediaNode = z.infer<typeof rcsMediaNode>

export type RcsEditorMediaFile = z.infer<typeof rcsEditorMediaFile>

export type RcsMediaFileContent = z.infer<typeof rcsMediaFileContent>

export type RcsEditorRichCardMedia = z.infer<typeof rcsEditorRichCardMedia>

export const isMediaFile = (
  mediaContent: RcsMediaFileContent
): mediaContent is Media =>
  typeof mediaContent !== 'string' && 'url' in mediaContent
