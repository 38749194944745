export enum RcsDroppable {
  MENU = 'RCS_MENU_ITEMS',
  BRANCH = 'RCS_BRANCH',
  SCREEN = 'RCS_VIRTUAL_SCREEN',
}

export enum ActionType {
  OPEN_URL = 'OPEN_URL',
  CALL_NUMBER = 'CALL_NUMBER',
  CREATE_CALENDAR_EVENT = 'CREATE_CALENDAR_EVENT',
  VIEW_LOCATION = 'VIEW_LOCATION',
}

export type RcsMediaType = 'MEDIA' | 'IMAGE' | 'VIDEO'

export type RcsContentType =
  | 'TEXT'
  | 'RICH_CARD'
  | 'CAROUSEL'
  | 'CAROUSEL_CARD'
  | RcsMediaType
