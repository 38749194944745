import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * System billing initial state
 */
export const billingState = {
  product: {},
  group: {},
  groups: {},
  products: [],
}

/**
 * System billing reducer
 */
export default handleActions(
  {
    BILLING_PRODUCT_GROUP_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        group: extend({}, state.group, payload || {}),
      })
    },
    BILLING_PRODUCT_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        product: extend({}, state.product, payload || {}),
      })
    },
    BILLING_PRODUCT_GROUP_RESET: (state) => {
      return extend({}, state, {
        group: {},
      })
    },
    BILLING_PRODUCT_RESET: (state) => {
      return extend({}, state, {
        product: {},
      })
    },
    BILLING_PRODUCT_GROUPS_RESET: (state) => {
      return extend({}, state, {
        groups: {},
      })
    },
    BILLING_PRODUCT_GROUPS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        groups: payload || {},
      })
    },
    BILLING_PRODUCTS_RESET: (state) => {
      return extend({}, state, {
        products: [],
      })
    },
    BILLING_PRODUCTS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        products: payload || [],
      })
    },
  },
  billingState
)
