import { createActionSaga, createFormSaga, customer, user } from '../../api'
import { actionSuccess } from '../../common/action'

export const systemCustomerApplications = 'system-customer-applications'
export const systemCustomers = 'system-customers'
export const systemUserCustomers = 'system-user-customers'
export const systemCustomerBillings = 'system-customer-billings'
export const systemCustomerBillingsReload = 'system-customer-billings-reload'
export const systemCustomerBillingDelete = 'system-customer-billing-delete'
export const customerDelete = 'customer-delete'
export const customerReload = 'customer-reload'
export const customerSave = 'customer-save'
export const systemCustomerToggleApplication =
  'system-customer-toggle-application'
export const customerSaveBilling = 'customer-save-billing'
export const customerSaveCompany = 'customer-save-company'
export const systemCustomerConnections = 'system-customer-connections'
export const customerDownloadCompanies = 'customer-download-companies'

const {
  deleteCustomer,
  deleteBilling,
  getApplications,
  getBillings,
  getCustomers,
  getConnections,
  getCustomer,
  toggleApplication,
  saveBilling,
  saveCustomer,
  downloadCompanies,
} = customer

const { getCompanies, saveCompany } = user

const customers = [
  createActionSaga(systemCustomerConnections, getConnections),
  createActionSaga(systemCustomerApplications, getApplications),
  createActionSaga(systemCustomers, getCustomers),
  createActionSaga(systemUserCustomers, getCompanies),
  createActionSaga(customerDelete, deleteCustomer),
  createActionSaga(customerReload, getCustomer, {
    busy: true,
  }),
  createActionSaga(systemCustomerBillingsReload, getBillings, {
    busy: true,
  }),
  createActionSaga(systemCustomerBillings, getBillings),
  createActionSaga(systemCustomerBillingDelete, deleteBilling),
  createActionSaga(systemCustomerToggleApplication, toggleApplication, {
    busy: true,
  }),
  createActionSaga(customerSaveBilling, saveBilling),
  createActionSaga(customerSaveCompany, saveCompany),
  createFormSaga(customerSave, saveCustomer, {
    message: 'Customer was updated successfully',
    redirect: (payload, result) => {
      if (result && result.new) {
        return '/system/customers/' + result.id
      }
    },
    success: function* (result) {
      yield actionSuccess(customerReload, result)
    },
  }),
  createActionSaga(customerDownloadCompanies, downloadCompanies),
]

export default customers
