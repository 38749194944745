import { z } from 'zod'

import { editorId } from '../../utils'
import { rcsTextContent } from '../content/text'
import { editorSuggestions } from './suggestions'

export const rcsTextNode = rcsTextContent.extend({
  editorId,
  suggestions: editorSuggestions,
})

export type RcsTextNode = z.infer<typeof rcsTextNode>
