import { sortBy } from 'lodash'
import moment from 'moment'

import { formatDate } from 'app/common/utils'
import { Remind, TimeResolution } from 'app/types/app'
import { FlowEvent } from 'app/types/flow'
import {
  EmailNotification,
  SmsNotification,
  SurveyChildItem,
  SurveyItem,
} from './types'

const standardFormat = 'DD.MM.YYYY HH:mm'

type scheduleValues = {
  is_scheduled: boolean
  scheduled_startdate: string
  scheduled_starttime: string
  is_receivedatetime: boolean
  receive_enddate: string
  receive_endtime: string
}

export const getBeginTime = (values: scheduleValues) => {
  return values.is_scheduled
    ? moment(
        values.scheduled_startdate + ' ' + values.scheduled_starttime,
        standardFormat
      ).format('YYYY-MM-DDTHH:mm:ssZ')
    : moment(new Date()).toISOString(true)
}

export const getEndTime = (values: scheduleValues) => {
  return values.is_receivedatetime
    ? moment(
        values.receive_enddate + ' ' + values.receive_endtime,
        standardFormat
      ).format('YYYY-MM-DDTHH:mm:ssZ')
    : undefined
}

const convertRemindTime = (activationMessageBegin: string, begin?: string) => {
  const defaultItem = {
    rm_remind: 1,
    rm_resolution: 'DAYS',
  }
  if (!begin) return defaultItem
  const remindDate = formatDate(begin, standardFormat)
  const activationDate = formatDate(activationMessageBegin, standardFormat)
  const daysDiff = moment(remindDate, standardFormat).diff(
    moment(activationDate, standardFormat),
    'days'
  )
  const hoursDiff = moment(remindDate, standardFormat).diff(
    moment(activationDate, standardFormat),
    'hours'
  )
  const minutesDiff = moment(remindDate, standardFormat).diff(
    moment(activationDate, standardFormat),
    'minutes'
  )

  if (
    daysDiff &&
    checkForEvenDiff(hoursDiff, 24) &&
    checkForEvenDiff(minutesDiff, 60)
  ) {
    return {
      rm_remind: daysDiff,
      rm_resolution: 'DAYS',
    }
  } else if (hoursDiff && checkForEvenDiff(minutesDiff, 60)) {
    return {
      rm_remind: hoursDiff,
      rm_resolution: 'HOURS',
    }
  } else if (minutesDiff) {
    return {
      rm_remind: minutesDiff,
      rm_resolution: 'MINUTES',
    }
  }
  return defaultItem
}

const checkForEvenDiff = (amount: number, context: number) => {
  return amount % context === 0
}

export const convertStartTimeFromRemind = (remind: Remind, base: string) => {
  const originalDate = moment(base)
  const daysToAdd = remind.rm_remind
  const resolution = remind.rm_resolution.toLowerCase() as
    | 'days'
    | 'minutes'
    | 'hours'
  const newDate = originalDate.add(daysToAdd, resolution)
  return newDate.toISOString(true)
}

export const convertRemindFromFlow = (
  flowitem: FlowEvent,
  activationBegin: string
) => {
  const schedule = flowitem.flowevent_schedule
  const scheduleTime = schedule?.scheduled_datetime
  const scheduleId = schedule?.id

  const flowFunction = flowitem.flow_functions?.[0]
  const sendFunction = flowFunction?.sendsmsfunction
  const remindTime = convertRemindTime(activationBegin, scheduleTime)

  return {
    id: flowitem.id,
    fromApiTimestamp: new Date().getTime(), // To be used as React key to trigger Editor component re-mount
    flowEventId: flowFunction?.id,
    sendSmsId: sendFunction?.id,
    scheduleId,
    receivers: getReceiverValue(sendFunction?.sendbatch_option),
    remindbody: sendFunction?.body,
    ...remindTime,
    rm_no_answer: false,
  }
}

const getReceiverValue = (string?: string) => {
  switch (string) {
    case 'REMIND_REPLIED':
      return 1
    case 'REMIND_EVERYONE':
      return 2
    default:
      return 0
  }
}

export const getReceiverSelection = (selection?: number) => {
  switch (selection) {
    case 1:
      return 'Replied'
    case 2:
      return 'Everyone'
    default:
      return 'Not replied'
  }
}

export const PERIOD_UNITS = [
  TimeResolution.DAYS,
  TimeResolution.HOURS,
  TimeResolution.MINUTES,
]

export const PERIOD_UNITS_MAX = {
  [TimeResolution.DAYS]: '31',
  [TimeResolution.HOURS]: '24',
  [TimeResolution.MINUTES]: '60',
}

export const getRemindMessageTitle = (index: number) => {
  const keys: { [key: number]: string } = {
    1: 'First',
    2: 'Second',
    3: 'Third',
    4: 'Fourth',
    5: 'Fifth',
    6: 'Sixth',
    7: 'Seventh',
    8: 'Eighth',
    9: 'Ninth',
    10: 'Tenth',
  }
  return keys[index] || index.toString()
}

export const sortMessagesByDate = (messages: Remind[]) => {
  const durationUnit = {
    [TimeResolution.MINUTES]: 'minutes' as const,
    [TimeResolution.HOURS]: 'hours' as const,
    [TimeResolution.DAYS]: 'days' as const,
  }

  return sortBy(
    messages.map((message) => {
      const unit = durationUnit[message.rm_resolution]
      if (unit) {
        return {
          ...message,
          helperTimeStamp: moment().add(message.rm_remind, unit).format('x'), // Unix ms timestamp
        }
      }
      return message
    }),
    ['helperTimeStamp']
  )
}

export const parseCopiedSurvey = (survey: SurveyItem[]) => {
  const mapped = survey.map(mapSurvey)
  return mapped
}

const parseEmailItem = (email: EmailNotification) => {
  return {
    ...email,
    id: undefined,
    idData: undefined,
    batchId: undefined,
    parentFunctionId: undefined,
  }
}

const parseSmsItem = (sms: SmsNotification) => {
  return {
    ...sms,
    msisdns: sms.msisdns.map((el) => ({ ...el, id: undefined })),
    id: undefined,
    batchId: undefined,
    parentFunctionId: undefined,
  }
}

type MappedSurveyItem = {
  id: undefined
  flowEventId: undefined
  flowevent_matchers: undefined
  batchId: undefined
  responseId: undefined
  response?: string
  children?: MappedSurveyItem[] | undefined
  sms_action?: SmsNotification | undefined
  email_action?: EmailNotification | undefined
  isActive?: boolean
}

const mapSurvey = (
  surveyItem: SurveyItem | SurveyChildItem
): MappedSurveyItem => {
  const { sms_action, email_action, response } = surveyItem
  const children = 'children' in surveyItem ? surveyItem.children : undefined
  const isActive = 'isActive' in surveyItem ? surveyItem.isActive : undefined
  const parsedSms = sms_action ? parseSmsItem(sms_action) : undefined
  const parsedEmail = email_action ? parseEmailItem(email_action) : undefined
  const parsedChildren: MappedSurveyItem[] | undefined = children
    ? children.map((el) => mapSurvey(el))
    : undefined

  return {
    id: undefined,
    flowEventId: undefined,
    flowevent_matchers: undefined,
    batchId: undefined,
    responseId: undefined,
    response,
    children: parsedChildren,
    sms_action: parsedSms,
    email_action: parsedEmail,
    isActive,
  }
}
