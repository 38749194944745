import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

/**
 * Routing initial state
 */
export const routingState = {
  routingRule: {},
}

/**
 * System routing reducer
 */
export default handleActions(
  {
    ROUTING_RULE_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        routingRule: extend({}, state.routingRule, payload || {}),
      })
    },
    ROUTING_RULE_RESET: (state) => {
      return extend({}, state, {
        routingRule: {},
      })
    },
  },
  routingState
)
