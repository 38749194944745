import { z } from 'zod'

import { editorId } from '../../utils'
import { rcsRichCardContent } from '../content/rich-card'
import { rcsEditorRichCardMedia } from './media'
import { editorSuggestions } from './suggestions'

export const superRefineRichCard = (
  card: {
    media?: z.infer<typeof rcsEditorRichCardMedia>
    title?: string
    description?: string
  },
  ctx: z.RefinementCtx
) => {
  if (!!card.media || !!card.title || !!card.description) {
    return
  }
  const message = 'The rich card should include a title, a description or media'
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ['title'],
    message,
  })
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ['description'],
    message,
  })
}

export const rcsRichCardNode = rcsRichCardContent
  .extend({
    editorId,
    suggestions: editorSuggestions,
    media: z.optional(rcsEditorRichCardMedia),
  })
  .superRefine(superRefineRichCard)

export type RcsRichCardNode = z.infer<typeof rcsRichCardNode>
