import { isUndefined } from 'lodash'

import { bind } from '../../common/utils'
import { getPageNumber } from '..'

export class ApplicationDraft {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteApplicationDraft',
      'saveApplicationDraft',
      'editApplicationDraft',
      'getDrafts',
      'getAllDrafts',
    ])
    this.endpointUrl = 'app/applicationdraft'
  }

  getDrafts(request) {
    const { customerId, systemApplicationId } = request
    return this.api
      .get(`${this.endpointUrl}`, {}, true, {
        'X-Customer': customerId,
        'X-Filter': JSON.stringify({ systemapplication: systemApplicationId }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.draftid,
              name: result.name,
              systemapplication: result.systemapplication,
              modified: result.modified,
              created: result.created,
            }
          }),
        }
      })
  }

  getAllDrafts(request = {}) {
    const {
      extra: { customerId },
    } = request
    const options = {
      ...request,
      search: {
        ...request.search,
      },
    }
    return this.api
      .get('app/applicationdraft?page=' + getPageNumber(options), {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          draftid: 'id',
          modulename: 'systemapplication',
          name: 'name',
          created_range: 'created_range',
          created: 'created',
          modified: 'modified',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.draftid,
              name: result.name,
              modulename: result.modulename,
              systemapplication: result.systemapplication,
              modified: result.modified,
              created: result.created,
            }
          }),
        }
      })
  }

  getApplicationDraft(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((results) => {
        return {
          results,
        }
      })
  }

  saveApplicationDraft(request) {
    const { customerId } = request
    let data = {
      systemapplication: request.systemApplicationId,
      eventdata: request.eventdata,
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then((results) => {
        return {
          draftid: results.draftid,
        }
      })
  }

  editApplicationDraft(request) {
    const { customerId, id } = request
    let data = {
      systemapplication: request.applicationId,
      eventdata: request.eventdata,
    }

    return this.api
      .put(`${this.endpointUrl}/${id}`, data, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  deleteApplicationDraft({ id, customerId }) {
    if (isUndefined(id)) {
      return this.api
        .delete(`${this.endpointUrl}`, {}, true, {
          'X-Customer': customerId,
        })
        .then(() => {
          return {
            success: true,
          }
        })
    } else {
      return this.api
        .delete(`${this.endpointUrl}/${id}`, {}, true, {
          'X-Customer': customerId,
        })
        .then(() => {
          return {
            success: true,
          }
        })
    }
  }
}
