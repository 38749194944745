import { createActionSaga, newsfeed as newsfeedApi } from '../api'

export const newsFeedReload = 'news-feed-reload'

const { getNewsFeed } = newsfeedApi

const newsfeed = [
  createActionSaga(newsFeedReload, getNewsFeed, {
    busy: true,
  }),
]

export default newsfeed
